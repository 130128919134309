import React from 'react';
import { css } from '@emotion/core';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { gray6, primary } from 'styles/global_defaults/colors';
import { doubleSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import {
  ReactComponent as ClassicViewQuiz,
} from 'styles/icons/classic-view-quiz.svg';
import {
  ReactComponent as SingleQuestionViewQuiz,
} from 'styles/icons/single-question-view-quiz.svg';

export enum QuizType {
  CLASSIC = 'CLASSIC',
  PROGRESSIVE = 'PROGRESSIVE',
}

type Props = {
  type: QuizType;
  dataQa: string;
  onSelect: () => void;
};

const QuizTypeCard = (props: Props) => {
  const { type, dataQa, onSelect } = props;

  const [isButtonFocused, setIsButtonFocused] = React.useState(false);

  const courseAliases = useSelector(getFlatCourseAliases);

  const typeFeatures = React.useMemo(() => ({
    [QuizType.CLASSIC]: [
      {
        label: t.QUIZZES.TYPE_FEATURES.CLASSIC_VIEW.ALL_QUESTION_TYPES.LABEL(),
        description: t.QUIZZES.TYPE_FEATURES.CLASSIC_VIEW.ALL_QUESTION_TYPES.DESCRIPTION(),
      },
      {
        label: t.QUIZZES.TYPE_FEATURES.CLASSIC_VIEW.ALL_AT_ONCE.LABEL(),
        description: t.QUIZZES.TYPE_FEATURES.CLASSIC_VIEW.ALL_AT_ONCE.DESCRIPTION(courseAliases),
      },
      {
        label: t.QUIZZES.TYPE_FEATURES.CLASSIC_VIEW.QUIZ_ATTEMPTS.LABEL(),
        description: t.QUIZZES.TYPE_FEATURES.CLASSIC_VIEW.QUIZ_ATTEMPTS.DESCRIPTION(courseAliases),
      },
      {
        label: t.QUIZZES.TYPE_FEATURES.CLASSIC_VIEW.POST_QUIZ_FEEDBACK.LABEL(),
        description: t.QUIZZES.TYPE_FEATURES.CLASSIC_VIEW.POST_QUIZ_FEEDBACK.DESCRIPTION(courseAliases),
      },
      {
        label: t.QUIZZES.TYPE_FEATURES.CLASSIC_VIEW.STATIC_FEEDBACK.LABEL(),
        description: t.QUIZZES.TYPE_FEATURES.CLASSIC_VIEW.STATIC_FEEDBACK.DESCRIPTION(courseAliases),
      },
    ],
    [QuizType.PROGRESSIVE]: [
      {
        label: t.QUIZZES.TYPE_FEATURES.SINGLE_QUESTION_VIEW.LIMITED_QUESTION_TYPES.LABEL(),
        description: t.QUIZZES.TYPE_FEATURES.SINGLE_QUESTION_VIEW.LIMITED_QUESTION_TYPES.DESCRIPTION(),
      },
      {
        label: t.QUIZZES.TYPE_FEATURES.SINGLE_QUESTION_VIEW.ONE_AT_A_TIME.LABEL(),
        description: t.QUIZZES.TYPE_FEATURES.SINGLE_QUESTION_VIEW.ONE_AT_A_TIME.DESCRIPTION(courseAliases),
      },
      {
        label: t.QUIZZES.TYPE_FEATURES.SINGLE_QUESTION_VIEW.QUIZ_AND_QUESTION_ATTEMPTS.LABEL(),
        description: t.QUIZZES.TYPE_FEATURES.SINGLE_QUESTION_VIEW.QUIZ_AND_QUESTION_ATTEMPTS.DESCRIPTION(courseAliases),
      },
      {
        label: t.QUIZZES.TYPE_FEATURES.SINGLE_QUESTION_VIEW.IMMEDIATE_QUESTION_FEEDBACK.LABEL(),
        description: t.QUIZZES.TYPE_FEATURES.SINGLE_QUESTION_VIEW.IMMEDIATE_QUESTION_FEEDBACK.DESCRIPTION(courseAliases),
      },
      {
        label: t.QUIZZES.TYPE_FEATURES.SINGLE_QUESTION_VIEW.QUESTION_HINT.LABEL(),
        description: t.QUIZZES.TYPE_FEATURES.SINGLE_QUESTION_VIEW.QUESTION_HINT.DESCRIPTION(courseAliases),
      },
      {
        label: t.QUIZZES.TYPE_FEATURES.SINGLE_QUESTION_VIEW.FRESH_CREATION_EXPERIENCE.LABEL(),
      },
    ],
  }), [courseAliases]);

  const isClassicType = type === QuizType.CLASSIC;

  const GraphicComponent = isClassicType ? ClassicViewQuiz : SingleQuestionViewQuiz;

  const typeName = isClassicType ? t.QUIZZES.TYPES.ALL_QUESTION_VIEW() : t.QUIZZES.TYPES.SINGLE_QUESTION_VIEW();

  const styles = css`
    width: 320px;
    border: 1px solid ${gray6};
    border-top-width: ${quarterSpacing}px;
    border-top-left-radius: ${quarterSpacing}px;
    border-top-right-radius: ${quarterSpacing}px;

    &:hover {
      border-color: ${primary};
    }

    ${isButtonFocused && css`
      border-color: ${primary};
    `};

    .features-container {
      flex: 1;
      border-top: 1px solid ${gray6};
    }

    .feature-item-icon {
      flex-shrink: 0;
      margin-left: 8px;
      display : inline-block !important;
    }
  `;

  const currentTypeFeatures = typeFeatures[type];

  return (
    <div className='d-flex flex-column align-items-center p-2' css={styles}>
      <GraphicComponent width={doubleSpacing} height={doubleSpacing} className='mb-2' />
      <div className='text-body bold mb-2'>
        {typeName}
      </div>
      <div className='features-container p-2 w-100'>
        {currentTypeFeatures.map((feature) => (
          <div
            className='d-flex align-items-center mb-3'
            key={`${typeName}-${feature.label}`}
          >
            <NvIcon icon='check' size='small' className='text-success mr-2' />
            <div className='feature-item'>
              <div className='feature-item-text'>
                {feature.label}
                {!!feature.description && (
                  <NvPopover
                    showOnHover
                    placement='top'
                    className='feature-item-icon'
                    content={(
                      <div>
                        {feature.description}
                      </div>
                  )}
                  >
                    <NvIcon icon='info' size='xs-smallest' className='text-primary' />
                  </NvPopover>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Button
        data-qa={dataQa}
        className='mb-2'
        onClick={onSelect}
        onFocus={() => setIsButtonFocused(true)}
        onBlur={() => setIsButtonFocused(false)}
      >
        {t.FORM.DROPDOWN.SELECT()}
      </Button>
    </div>
  );
};

export default QuizTypeCard;

import React, { useCallback } from 'react';
import _ from 'underscore';
import { css } from '@emotion/core';
import t from 'react-translate';
import { AngularContext } from 'react-app';

// redux
import { useSelector } from 'react-redux';
import { getLecturePage } from 'redux/selectors/timeline';
import { Institution } from 'redux/schemas/models/courseFull';
import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { Course } from 'redux/schemas/models/course';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getLectureComponent } from 'redux/selectors/lecture-components';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import {
  getQuizQuestion,
  GetQuestionParams,
  reviewPreviousAttempt,
} from 'redux/actions/quizzes';
import {
  getProgressiveQuiz,
  getQuizQuestionResponse,
  getProgressiveQuizQuestion,
  getProgressiveQuizQuestions,
  getQuizQuestionOptions,
} from 'redux/selectors/quizzes';
import {
  NQuizQuestion,
  QuizQuestionType,
  ResponseOption,
  QuizQuestion as TQuizQuestion,
} from 'redux/schemas/models/progressive-quiz';

import NvIcon from 'shared/components/nv-icon';
import QuizHeader from 'quizzes/components/quiz-header';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import FailedQuizModal from 'quizzes/components/failed-quiz-modal';
import NavigationArrows from 'quizzes/components/navigation-arrows';
import {
  largeSpacing,
  quarterSpacing,
  threeQuartersSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import { gray3, gray7 } from 'styles/global_defaults/colors';
import { QuestionPopoverProvider } from 'quizzes/components/question-popover';
import { getCurrentCourse, getFlatCourseAliases } from 'redux/selectors/course';
import ProgressiveQuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import useSavingRegistry, { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import QuizQuestion from './quiz-question';
import QuizSubmitSection from './quiz-submit-section';

type Props = {
  reveal: boolean;
  mode: ProgressiveQuizMode;
  lectureComponentId: number;
  closeModal: (skipCheck?: boolean) => void;
  forwardOnModalClose: (fn: (e: Event) => void) => () => void;
  questionIndex?: number
};

const ProgressiveQuizModal = (props: Props) => {
  const {
    mode,
    reveal,
    closeModal,
    lectureComponentId,
    forwardOnModalClose,
    questionIndex: initialQuestionIndex,
  } = props;

  const dispatch = useAppDispatch();
  const savingRegistry = useSavingRegistry();
  const { isSaving } = savingRegistry;
  const scrollRef = React.useRef<HTMLDivElement>();
  const savingIndicatorTimeoutRef = React.useRef<null | NodeJS.Timeout>(null);
  const quizContainerRef = React.useRef(null);
  const isEditMode = mode === ProgressiveQuizMode.EDIT;
  const courseAliases = useSelector(getFlatCourseAliases);
  const isReviewMode = mode === ProgressiveQuizMode.REVIEW;
  const isAnswerMode = mode === ProgressiveQuizMode.ANSWER;
  const [fetchedData, setFetchedData] = React.useState(false);
  const [savingStatus, setSavingStatus] = React.useState(SavingIndicator.HIDDEN_STATUS);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { injectServices } = React.useContext(AngularContext);
  const [StateManager] = injectServices(['StateManager']);

  const lectureComponent = useSelector((state: RootState) => getLectureComponent(state, lectureComponentId));
  const ownerLecturePage = useSelector((state: RootState) => getLecturePage(state, lectureComponent.lecturePageId));

  const progressiveQuizId = lectureComponent.progressiveQuiz;

  const progressiveQuiz = useSelector(
    (state: RootState) => getProgressiveQuiz(state, progressiveQuizId),
  );

  const pointsConfiguration = useSelector((state) => (
    getPointsConfiguration(state, progressiveQuiz.pointsConfiguration || 0)
  ));

  const questions: NQuizQuestion[] = useSelector(
    (state: RootState) => getProgressiveQuizQuestions(state, progressiveQuizId),
  );

  const [
    currentQuestionId,
    setCurrentQuestionId,
  ] = React.useState<number | null>(null);

  const questionByCurrentId: NQuizQuestion = useSelector((state: RootState) => getProgressiveQuizQuestion(state, currentQuestionId));

  const [
    currentQuestionIndex,
    setCurrentQuestionIndex,
  ] = React.useState<number>(isAnswerMode ? null : initialQuestionIndex);

  let currentQuestion = (currentQuestionId ? questionByCurrentId : questions?.[currentQuestionIndex]);

  // For modes that are not EDIT we need to wait till data is fetched to
  // consider the redux question a reliable source
  currentQuestion = (fetchedData || isEditMode) ? currentQuestion : undefined;

  const currentCourse = useSelector(getCurrentCourse);
  const currentQuestionResponse = useSelector(
    (state: RootState) => getQuizQuestionResponse(state, (isReviewMode ? currentQuestion?.previousAttemptResponses : currentQuestion?.responses)),
  );

  const failedQuestion = (currentQuestion?.completedQuestionAttempts === progressiveQuiz.questionMaximumAttempts) && !currentQuestionResponse?.isCorrect;

  const canMakeStructuralChanges = currentCourse.isContentManagementCollection ? !progressiveQuiz.learnerStartedSubmission : !ownerLecturePage.released;

  const isRequirementEnabled = pointsConfiguration && !pointsConfiguration.rewardsPointsProportionally;

  const requiredCorrectQuestionsCount = isRequirementEnabled ? Math.ceil(progressiveQuiz.answerableQuestionsCount * pointsConfiguration.threshold) : 0;

  const notMetRequirement = useCallback(() => {
    if (questionByCurrentId) {
      if (questionByCurrentId?.type === QuizQuestionType.STATEMENT) {
        return false;
      }
      const questionsLeftCount = progressiveQuiz.answerableQuestionsCount - (currentQuestion?.displayIndex - 1 + (failedQuestion ? 1 : 0));
      return isRequirementEnabled ? ((progressiveQuiz.correctAnswersCount + questionsLeftCount) < requiredCorrectQuestionsCount) : false;
    }
    return false;
  }, [
    failedQuestion,
    isRequirementEnabled,
    progressiveQuiz.answerableQuestionsCount,
    progressiveQuiz.correctAnswersCount,
    questionByCurrentId,
    requiredCorrectQuestionsCount,
  ]);

  React.useEffect(() => StateManager.registerStateChangeStart(
    isSaving,
    'shared/templates/modal-navigate-away.html',
    'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
  ),
  [isSaving, StateManager]);

  React.useEffect(() => forwardOnModalClose((event) => {
    if (isSaving()) {
      event.preventDefault();

      dispatch(openConfirmationDialog({
        cancelText: t.FORM.CANCEL(),
        confirmText: t.FORM.YES_SURE(),
        onConfirm: () => closeModal(true),
        title: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.TITLE(),
        bodyText: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.DESCRIPTION(),
      }));
    }
  }), [isSaving, dispatch, closeModal, forwardOnModalClose]);

  const fetchQuestion = React.useCallback((params?: Omit<GetQuestionParams, 'questionSetId'>) => wrapThunkAction(
    dispatch(getQuizQuestion({
      questionSetId: progressiveQuizId,
      ...(params ?? {}),
    })),
  ).then((action) => {
    const fetchedQuestion = action.payload as TQuizQuestion;

    setCurrentQuestionId(fetchedQuestion.id);

    setCurrentQuestionIndex(fetchedQuestion.questionIndex);

    return fetchedQuestion;
  }), [dispatch, progressiveQuizId]);

  // Answer mode get question
  React.useEffect(() => {
    if (isAnswerMode) {
      fetchQuestion().then(() => {
        setFetchedData(true);
      });
    }
  }, [isAnswerMode, fetchQuestion]);

  // Review mode get questions
  React.useEffect(() => {
    if (isReviewMode) {
      wrapThunkAction(dispatch(reviewPreviousAttempt({
        reveal,
        questionSetId: progressiveQuizId,
      }))).then(() => {
        setFetchedData(true);
      });
    }
  }, [
    reveal,
    dispatch,
    isReviewMode,
    progressiveQuizId,
  ]);

  React.useEffect(() => {
    if (currentQuestionIndex !== null) {
      if (quizContainerRef.current) {
        quizContainerRef.current.focus();
      }
      scrollRef.current.scrollTop = 0;
    }
  }, [currentQuestionIndex]);

  const { headerColor } = useSelector<RootState, Course>(getCurrentCourse);
  const { brandColor } = useSelector<RootState, Institution>(getCurrentInstitution);

  const accentColor = headerColor ?? brandColor ?? gray3;

  const styles = css`
    z-index: 0;
    height: 100%;
    border-top: ${quarterSpacing}px solid ${accentColor};

    .scroll {
      height: 100%;
      overflow-y: auto;
      padding-left: 70px;
      padding-right: 70px;

      .saving-container {
        z-index: 1;
        position: fixed;
        top: calc(100% - ${tripleSpacing}px);
        left: 50%;
        transform: translateX(-50%);
        height: ${largeSpacing}px;
        border-radius: ${threeQuartersSpacing}px;
        box-shadow: 0px ${quarterSpacing}px ${quarterSpacing}px rgba(0, 0, 0, 0.15)
      }

      .quiz-header {
        top: 0;
        z-index: 2;
      }

      .content-container {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;

        .quiz-question {
          z-index: 1;
        }

        .released-container {
          padding: 12px;
          background-color: ${gray7};
        }

        .question-image {
          flex-shrink: 0;
        }
      }
    }
  `;

  const contextValue = {
    mode,
    reveal,
    questions,
    scrollRef,
    closeModal,
    fetchQuestion,
    progressiveQuiz,
    currentQuestion,
    lectureComponent,
    notMetRequirement,
    forwardOnModalClose,
    isRequirementEnabled,
    currentQuestionIndex,
    currentQuestionResponse,
    setCurrentQuestionIndex,
    canMakeStructuralChanges,
    requiredCorrectQuestionsCount,
    savingIndicatorTimeoutRef,
    isSubmitting,
    setIsSubmitting,
    setSavingStatus,
  };

  const renderSavingStatus = () => {
    switch (savingStatus) {
      case SavingIndicator.SAVING_STATUS:
        return (
          <div className='d-flex text-gray-2 bg-gray-7 saving-container align-items-center justify-content-center px-2'>
            <i className='icon-refresh spin icon-xss-smallest d-flex align-items-center' />
            <div className='ml-1 semi-bold text-small'>{t.QUIZZES.SAVING()}</div>
          </div>
        );
      case SavingIndicator.SUCCESS_STATUS:
        return (
          <div className='d-flex bg-gray-7 saving-container align-items-center justify-content-center px-2'>
            <i className='icon-check text-success icon-xss-smallest d-flex align-items-center' />
            <div className='ml-1 semi-bold text-small'>{t.QUIZZES.QUIZ_SAVED()}</div>
          </div>
        );
      case SavingIndicator.ERROR_STATUS:
        return (
          <div className='d-flex bg-gray-7 saving-container align-items-center justify-content-center px-2'>
            <i className='icon-warning text-danger icon-xss-smallest d-flex align-items-center' />
            <div className='ml-1 semi-bold text-small'>{t.QUIZZES.ERROR()}</div>
          </div>
        );
      case SavingIndicator.HIDDEN_STATUS:
        return null;
      default:
        return null;
    }
  };

  const [answerState, setAnswerState] = React.useState(null);
  const [answerInputError, setAnswerInputError] = React.useState(null);

  const responseOptions: ResponseOption[] = useSelector(
    (state: RootState) => getQuizQuestionOptions(state, currentQuestion?.id),
  );

  const unsetResponse = React.useCallback(() => {
    setAnswerState(null);
    setAnswerInputError(null);
  }, []);

  React.useLayoutEffect(() => {
    unsetResponse();
  }, [currentQuestionId, unsetResponse]);

  const areAllOptionsCorrect = responseOptions?.every((responseOption) => !responseOption.isCorrect) ?? false;

  const questionContextValue = {
    answerState,
    setAnswerState,
    answerInputError,
    setAnswerInputError,
    responseOptions,
    currentQuestion,
    areAllOptionsCorrect,
    currentQuestionResponse,
  };

  return (
    <SavingRegistryContext.Provider value={savingRegistry}>
      <ProgressiveQuizContext.Provider value={contextValue}>
        <QuestionContext.Provider value={questionContextValue} key={currentQuestion?.id}>
          <QuestionPopoverProvider>
            <div css={styles} tabIndex={-1} ref={quizContainerRef} className='position-relative'>
              <div className='scroll position-relative' ref={scrollRef}>
                <div className='content-container d-flex flex-column h-100'>
                  {currentQuestion && (
                  <>
                    <QuizHeader className='quiz-header mb-2 position-sticky' />
                    {!canMakeStructuralChanges && isEditMode && (
                      <div className='released-container text-danger d-flex flex-row align-items-center mb-5'>
                        <NvIcon icon='warning' size='small' className='mr-2' />
                        <div className='label text-danger'>
                          {t.QUIZZES.ALREADY_RELEASED.DESCRIPTION(courseAliases)}
                        </div>
                      </div>
                    )}
                    <QuizQuestion
                      currentQuestion={currentQuestion}
                      className={`quiz-question ${!isAnswerMode ? 'pb-6' : ''} position-relative`}
                    />
                    {isAnswerMode
                      && (
                        <QuizSubmitSection
                          currentQuestion={currentQuestion}
                          unsetResponse={unsetResponse}
                        />
                      )}
                  </>
                  )}
                </div>
                {(savingStatus !== SavingIndicator.HIDDEN_STATUS) && isEditMode && (
                <React.Fragment>
                  {renderSavingStatus()}
                </React.Fragment>
                )}
              </div>
              {currentQuestion && (
              <NavigationArrows />
              )}
            </div>
          </QuestionPopoverProvider>
          {isAnswerMode && (
          <FailedQuizModal />
          )}
        </QuestionContext.Provider>
      </ProgressiveQuizContext.Provider>
    </SavingRegistryContext.Provider>
  );
};

export default ProgressiveQuizModal;

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { MyAccount } from 'redux/schemas/models/my-account';
import { getCurrentUser } from 'redux/selectors/users';

/**
 * Current user holds translationPreferenceLanguage and lastTranslationPreferenceLanguage
 * But lastTranslationPreferenceLanguage updates when translationPreferenceLanguage
 * is switched. Can't determine a change is made from these values. So this
 * keeps the last preference and change the boolean when it is changed.
 *
 * @returns translationPreferenceChanged boolean
 */

const useTranslationPreferenceChanged = () => {
  const lastTranslationPreference = useRef(null);

  const currentUser: MyAccount = useSelector(getCurrentUser);

  useEffect(() => {
    if (lastTranslationPreference.current !== currentUser.translationPreferenceLanguage) {
      lastTranslationPreference.current = currentUser.translationPreferenceLanguage;
    }
  }, [currentUser.translationPreferenceLanguage]);

  return lastTranslationPreference.current !== currentUser.translationPreferenceLanguage;
};

export default useTranslationPreferenceChanged;

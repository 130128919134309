import store from '../../redux/store';
import { getToSubmission } from '../../redux/selectors/skills-feedback';

/* @ngInject */
export default function IndividualSubmissionPaginationController(
  _,
  $stateParams,
  $state,
  config,

  ReportsManager,
) {
  let hasAttemptedToLoadMore = false;
  _.extend(this, {
    $stateParams,

    getPrevious,
    getNext,
    pendoTagNext: config.pendo.submissionView.nextSubmission,
    pendoTagPrev: config.pendo.submissionView.previousSubmission,
  });

  function getPrevious() {
    // To hide the previous arrow
    if (
      $stateParams.galleryMode !== 'true'
      || ReportsManager?.reports?.length === 1
    ) {
      return undefined;
    }

    let prevSubmission = ReportsManager.getPreviousReport();

    if ($stateParams.isRatingFeedback && $stateParams?.reportId) {
      const reduxState = store.getState();

      prevSubmission = getToSubmission(reduxState, $stateParams.reportId, true);
    }

    if (prevSubmission) {
      return $state.href('individual-submission-modal', {
        catalogId: getCatalogId(prevSubmission),
        reportId: prevSubmission.id,
      });
    }

    return null;
  }

  function getNext() {
    // To hide the next arrow
    if (
      $stateParams.galleryMode !== 'true'
      || ReportsManager?.reports?.length === 1
    ) {
      return undefined;
    }

    let nextSubmission = ReportsManager.getNextReport();

    if ($stateParams.isRatingFeedback && $stateParams?.reportId) {
      const reduxState = store.getState();
      nextSubmission = getToSubmission(reduxState, $stateParams.reportId);
    }

    if (nextSubmission) {
      return $state.href('individual-submission-modal', {
        catalogId: getCatalogId(nextSubmission),
        reportId: nextSubmission.id,
      });
    }
    if (!hasAttemptedToLoadMore) {
      hasAttemptedToLoadMore = true;
      ReportsManager.loadAdditionalResults();
    }

    return null;
  }

  function getCatalogId(submission) {
    return (submission.exercise && (submission.exercise.catalogId || submission.exercise.course?.catalogId)) || $stateParams.catalogId;
  }
}


/* @ngInject */
export default function nvInputWithLabel(
  $translate,
  $timeout,
) {
  return {
    restrict: 'A',
    controller: function ctrl() {
'ngInject'; },
    scope: true,
    link(scope, element, attributes) {
      scope.inputAttributes = scope.$eval(attributes.attributes);
      attributes.$observe('inputLabel', (inputLabel) => {
        scope.label = inputLabel;
      });
      scope.inputType = scope.inputAttributes.type || 'text';
      scope.inputName = scope.inputAttributes.name || `input_with_label_${scope.$id}`;
      scope.formName = attributes.formName;
      scope.tooltip = attributes.tooltip;
      scope.errorTooltipPosition = scope.inputAttributes.errorTooltipPosition || 'auto top';

      scope.focused = false;
      scope.required = scope.inputAttributes.required === 'true' || false;
      scope.disabled = scope.$eval(scope.inputAttributes.disabled);
      scope.hideLabel = scope.inputAttributes.hideLabel;

      scope.readonly = attributes.readonly === 'true';
      scope.readonlyWarning = attributes.readonlyWarning;

      scope.id = Math.floor(Math.random() * 10000);

      // Using the variable name `validationPopoverId` to override the value in the popover.
      scope.validationPopoverId = `validation_error_${scope.id}`;

      // show the label if there is content in the text box or if it's focused
      scope.shouldShowLabel = () => element.find('input').val() || scope.focused || scope.inputAttributes.alwaysShowLabel;

      scope.placeholderText = function getPlaceholderText() {
        const { inputAttributes } = scope;

        if (inputAttributes) {
          if (inputAttributes.placeholderKey) {
            return $translate.instant(inputAttributes.placeholderKey);
          }

          if (inputAttributes.placeholder) {
            return inputAttributes.placeholder;
          }
        }

        return scope.label;
      };

      scope.checkVisibility = function () {
        return element.find('input').val() || scope.focused; // show the label if there is content in the text box or if it's focused
      };

      scope.updateShowTooltip = function () {
        if (!scope.tooltip) {
          return false;
        }

        const hasError = scope.$eval(scope.formName)[scope.inputName].$showValidationMessage && scope.$eval(scope.formName)[scope.inputName].$invalid;
        scope.tooltipOpen = scope.focused && !hasError && !element.find('input').val().length;
        return null;
      };

      scope.getAriaDescribedbyValue = function () {
        if (scope.formName || scope.inputName) {
          return null;
        }

        return scope.$eval(scope.formName)[scope.inputName]?.$invalid ? scope.validationPopoverId : `required_${scope.id}`;
      };

      // unforunately, this is the only way to get the autofocus to work consistently
      if (scope.inputAttributes.autofocus) {
        $timeout(() => {
          element.find('input').focus();
        });
      }
    },
    templateUrl: 'shared/templates/nv-input-with-label.html',
  };
}

import t from 'react-translate';

import { white, primary, gray3 } from 'styles/global_defaults/colors';
import { css } from '@emotion/react';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

import Spinner from 'shared/components/spinner';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import useAutoTranslate from 'shared/hooks/use-auto-translate';

const iconStyles = (isToggled: boolean) => css`
  .icon-translate {
    padding: 2px;

    ${isToggled
    ? css`
        color: ${white};
        background-color: ${primary};
      `
    : css`
        color: ${gray3};
      `}

    &:hover {
      color: ${isToggled ? white : primary};
    }
  }
`;

type TranslationButtonProps = {
  onToggle: (isTranslationOn: boolean, language: string) => void;
  isToggled: boolean
  isLoading?: boolean
  isVisible?: boolean
  dataQa?: string;
  sourceText?: string;
  customTurnOnTooltipText?: string;
  iconSize?: string,
};

const TranslationButton = ({
  onToggle,
  isToggled,
  isLoading = false,
  isVisible = true,
  dataQa,
  sourceText = '',
  customTurnOnTooltipText = t.DISCUSSIONS_AUTO_TRANSLATION.TRANSLATE(),
  iconSize = 'smallest',
}: TranslationButtonProps) => {
  const { isAutoTranslateEnabled, targetLanguage, isSameLanguage } = useAutoTranslate({ text: sourceText });
  const showTranslationButton = isAutoTranslateEnabled && isVisible && !isSameLanguage;

  if (isLoading) {
    return (
      <Spinner size={standardSpacing} />
    );
  }

  const buttonLabel = isToggled
    ? t.DISCUSSIONS_AUTO_TRANSLATION.TURN_OFF_TRANSLATION()
    : t.DISCUSSIONS_AUTO_TRANSLATION.TRANSLATE();

  const buttonText = isToggled
    ? t.DISCUSSIONS_AUTO_TRANSLATION.TURN_OFF_TRANSLATION()
    : customTurnOnTooltipText;

  return showTranslationButton ? (
    <NvTooltip placement='bottom' text={buttonText}>
      <ClickableContainer
        data-qa={dataQa}
        css={iconStyles(isToggled)}
        onClick={() => onToggle(!isToggled, targetLanguage)}
        aria-label={buttonLabel}
        aria-pressed={isToggled}
        className='translation-container'
      >
        <NvIcon size={iconSize} icon='translate' />
      </ClickableContainer>
    </NvTooltip>
  ) : null;
};

export default TranslationButton;

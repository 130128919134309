/* eslint-disable react-hooks/rules-of-hooks */
import { css } from '@emotion/react';
import TranslationButton from 'components/translation-button';
import React from 'react';
import t from 'react-translate';
import { extractTextFromHTML } from 'shared/utils';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

/* @ngInject */
/* @ngInject */
export default function NvTranslationButton(_, $timeout) {
  return {
    scope: {
      item: '=',
      pendoTag: '@?',
      iconSize: '@?',
    },
    controller: ['$scope', function ($scope) {
      const vm = this;

      let setToggleState;
      let setLoadingState;
      let updateItem = (_item) => {};

      $scope.ReactAngularTranslationButton = () => {
        const [currentItem, setCurrentItem] = React.useState(vm.item);
        const [isTranslationOn, setIsTranslationOn] = React.useState(currentItem.isTranslationOn);
        const [isLoading, setIsLoading] = React.useState(currentItem.isTranslationLoading);

        const style = css`
          &.translation-button {
            margin-left: 0;
            &:first-of-type {
              margin-left: ${standardSpacing}px;
            }
          }
        `;

        setToggleState = setIsTranslationOn;
        setLoadingState = setIsLoading;

        const onToggleHandler = (isStateOn: boolean, language: string) => {
          currentItem.onToggleTranslate(isStateOn, language);
        };

        const customTurnOnTooltipText = () => {
          switch (currentItem.type) {
            case 'report':
              return t.AUTO_TRANSLATION.SUBMISSION.TURN_ON_TRANSLATION();
            default:
              return undefined;
          }
        };

        updateItem = (newItem) => {
          $timeout(() => {
            setCurrentItem(newItem);
          }, 0);
        };

        return (
          <div css={style} className='d-flex translation-button'>
            <TranslationButton
              isToggled={isTranslationOn}
              isLoading={isLoading}
              isVisible={!currentItem.belongsToCurrentUser()}
              onToggle={onToggleHandler}
              dataQa={vm.pendoTag}
              sourceText={extractTextFromHTML(currentItem.body)}
              customTurnOnTooltipText={customTurnOnTooltipText()}
              iconSize={vm.iconSize}
            />
          </div>
        );
      };
      $scope.$watch('vm.item', (newItem) => updateItem(newItem));
      $scope.$watch('vm.item.isTranslationOn', (newToggleState) => setToggleState?.(newToggleState));
      $scope.$watch('vm.item.isTranslationLoading', (newLoadingState) => setLoadingState?.(newLoadingState));
    }],
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared/directives/nv-translation-button/template.html',
  };
}

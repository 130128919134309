import { css } from '@emotion/react';
import React from 'react';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { doubleSpacing, halfSpacing, journeyIconSize, quarterSpacing } from 'styles/global_defaults/scaffolding';

export const Optional = () => {
  const lineHeight = 14;
  const extraComponentsStyles = css`
    position: absolute;
    top: -${lineHeight / 2}px;
    border-radius: ${halfSpacing}px;
    min-width: ${doubleSpacing * 2}px;
    padding: 0px ${quarterSpacing}px;
    justify-content: center;
    letter-spacing: 1.5px;
    .icon {
      align-self: center;
    }
  `;
  return (
    <div className='optional-icon d-flex gray-1 bg-gray-5 text-xs' css={extraComponentsStyles}>
      {t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.OPTIONAL().toUpperCase()}
    </div>
  );
};

export const CompletedIcon = () => {
  const extraComponentsStyles = css`
    position: absolute;
    top: -${journeyIconSize / 2}px;
    border-radius: 50%;
    width: ${journeyIconSize}px;
    height: ${journeyIconSize}px;
    justify-content: center;
    .icon {
      align-self: center;
    }
  `;
  return (
    <div className='circular-icon bg-success d-flex' css={extraComponentsStyles}>
      <NvIcon icon='check' size='small' className='text-white' />
    </div>
  );
};

export const LockedIcon = ({ isViewMode }) => {
  const extraComponentsStyles = css`
    position: absolute;
    top: -${journeyIconSize / 2}px;
    border-radius: 50%;
    width: ${journeyIconSize}px;
    height: ${journeyIconSize}px;
    justify-content: center;
    .icon {
      align-self: center;
    }
  `;
  return (
    <NvTooltip enabled={isViewMode} text={t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.LOCKED_TOOLTIP()}>
      <div className='circular-icon bg-black d-flex' css={extraComponentsStyles}>
        <NvIcon icon='locked' size='small' className='text-white' />
      </div>
    </NvTooltip>
  );
};

import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';
import QrveyComponent from './qrvey-component';

const styles = css`
  &.container{
  max-width: 100%;
  .charts{
    display:flex;
    flex-direction:column;
    padding-bottom: 80px;
    min-width: 320px;
    .chart{
      margin-top:25px;
      &.small{
        max-width:400px;
      }
    }
  }
  }
`;

export type AnalyticsTokenProps ={
  type: AnalyticsDashBoardType,
  catalogId? : string,
  institutionId?: number,
  pageIdKey?: string
  questionSetId?: number

};
type AnalyticsProps = {
  dashboardType?: AnalyticsDashBoardType,
  configKeyName?: string,
  pageIdKey?: string,
  questionSetId?: number
  lecturePageId?: number
};

const AnalyticsDashboard = (props: AnalyticsProps) => (
  <div css={styles} className='container'>
    <div className='charts'>
      <QrveyComponent {...props} type={props.dashboardType} />
    </div>
  </div>
);

export default AnalyticsDashboard;

export const AnalyticsInstitutionDashboard = () => <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.INSTITUTION} />;

import { useCallback, useEffect, useRef } from 'react';
import { UploadedFile } from 'redux/schemas/models/file-uploads';
import { useAppDispatch } from 'redux/store';
import { getBoxAccessToken } from 'redux/actions/embeddings';

const PROXY_DOMAINS = {
  'api.box.com': 'boxapi.novoed.com',
  'dl.boxcloud.com': 'boxdl.novoed.com',
};

type NvBoxPreviewProps = {
  boxDocumentId: UploadedFile['boxDocumentId'],
};

const NvBoxPreview = ({ boxDocumentId }: NvBoxPreviewProps) => {
  const boxContainerRef = useRef(null);
  const getBoxAccessTokenRef = useRef(null);

  const dispatch = useAppDispatch();

  const replaceUrl = (originalUrl) => {
    let url = originalUrl;
    Object.keys(PROXY_DOMAINS).forEach(key => {
      url = url.replace(key, PROXY_DOMAINS[key]);
    });

    return url;
  };

  const proxyInterceptor = useCallback((request) => {
    request.url = replaceUrl(request.url);
    return request;
  }, []);

  const responseInterceptor = useCallback((response) => {
    try {
      response.data.representations.entries.forEach(entry => {
        if (entry?.content?.url) {
          entry.content.url = replaceUrl(entry.content.url);
        }
        if (entry?.content?.url_template) {
          entry.content.url_template = replaceUrl(entry.content.url_template);
        }
      });

      response.data.authenticated_download_url = replaceUrl(response.data.authenticated_download_url);
    } catch (ex) {
      // in case json signature changes
    }

    return response;
  }, []);

  const showBoxPreview = useCallback(() => {
    const preview = new window.Box.Preview();
    // Disable the FontInspector to a avoid console error on every box preview: https://novoed.atlassian.net/browse/NOV-63072
    // Unfortunately I've seen no config or other method to disable this
    window.default = { FontInspector: false };

    getBoxAccessTokenRef.current = dispatch(getBoxAccessToken({ boxDocumentId }));

    getBoxAccessTokenRef.current.then((response) => {
      const { accessToken } = response.payload || {};
      if (accessToken) {
        preview.show(boxDocumentId, accessToken, {
          container: boxContainerRef.current,
          autoFocus: false,
          header: 'none',
          disableEventLog: true,
          requestInterceptor: proxyInterceptor,
          responseInterceptor,
          fixDependencies: true,
        });
      }
    });
  }, [boxDocumentId, dispatch, proxyInterceptor, responseInterceptor]);

  useEffect(() => {
    showBoxPreview();
    return () => {
      if (getBoxAccessTokenRef.current) {
        getBoxAccessTokenRef.current.abort();
        getBoxAccessTokenRef.current = null;
      }
    };
  }, [showBoxPreview]);

  return (
    <div
      className='w-100 h-100'
      ref={boxContainerRef}
      dir='ltr' // TO avoid issues like NOV-62035
    />
  );
};

export default NvBoxPreview;

import t from 'react-translate';
import { useContext } from 'react';

import {
  NvDropdown,
  NvDropdownOption,
  NvDropdownButtonStyle,
  NvDropdownAlign,
} from 'shared/components/inputs/nv-dropdown';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { AngularServicesContext } from 'react-app';

import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getCurrentUser } from 'redux/selectors/users';
import { deleteComment } from 'redux/actions/comments';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { BookmarkType } from 'redux/schemas/models/bookmark';
import { createBookmark, highlightBookmark } from 'redux/actions/bookmarks';
import { Payload } from 'redux/schemas/api';
import { BookmarkResponse } from 'redux/schemas/api/bookmarks';
import BookmarkIcon from 'bookmarks/components/bookmark-icon';
import { isHandheld } from 'styles/global_defaults/media-queries';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import { TeamDiscussionContext } from './team-discussion';
import commonStyles from './common-action-styles';

interface DiscussionCommentActionsProps {
  commentId: number;
  onEditComment: (e: any) => void;
}

const DiscussionCommentActions = ({
  commentId,
  onEditComment,
}: DiscussionCommentActionsProps) => {
  const dispatch = useAppDispatch();

  const { TimelinesManager, FlyoutModalManager, $state } = useContext(AngularServicesContext);
  const { postId, currentLecture } = useContext(TeamDiscussionContext);
  const timelineService = useTimelineService();

  const currentUser = useSelector(getCurrentUser);
  const comment = useSelector((state) => state.models.comments[commentId]);
  const catalogId = useSelector((state) => state.app.currentCatalogId);

  const onDelete = () => {
    dispatch(deleteComment({
      postId,
      catalogId,
      commentId,
      userId: currentUser.id,
      // Update posts and progress of timeline after new post is fetched
      callback: (newPost) => {
        timelineService.updateTimeline(currentLecture.id);
        TimelinesManager.updateComponentPointsAndProgress(
          currentLecture.id,
          newPost.type,
          newPost.id,
          newPost.pointsReceived,
          newPost.totalPoints,
          newPost.progress,
        );
      },
    }));
  };

  const onDeleteComment = () => {
    const hasReplies = comment.repliesCount > 0;
    const hasPoints = comment.pointsReceived;

    let deleteMessage = t.DISCUSSIONS.DELETE_COMMENT_MSG();

    if (comment.user.id !== currentUser.id) {
      deleteMessage = t.DISCUSSIONS.DELETE_COMMENT_MSG();
    } else if (hasReplies && hasPoints) {
      deleteMessage = t.DISCUSSIONS.DELETE_COMMENT_WITH_POINTS_AND_REPLIES_MSG();
    } else if (!hasReplies && hasPoints) {
      deleteMessage = t.DISCUSSIONS.DELETE_COMMENT_WITH_POINTS_MSG();
    } else if (hasReplies && !hasPoints) {
      deleteMessage = t.DISCUSSIONS.DELETE_COMMENT_WITH_REPLIES_MSG();
    }

    dispatch(openConfirmationDialog({
      bodyText: deleteMessage,
      confirmText: t.FORM.DELETE(),
      cancelText: t.FORM.CANCEL(),
      icon: 'warning',
      onConfirm: onDelete,
    }));
  };

  const onCreateBookmark = () => {
    dispatch(createBookmark({
      type: BookmarkType.POST,
      catalogId: $state.params.catalogId,
      componentId: commentId,
    })).then(() => {
      FlyoutModalManager.openFlyout({
        controller: 'BookmarksFlyoutCtrl as vm',
        template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
        isNavigational: false,
      });
    });
  };

  const onHighlightBookmark = () => {
    dispatch(highlightBookmark({ id: comment.bookmarkId }));
    FlyoutModalManager.openFlyout({
      controller: 'BookmarksFlyoutCtrl as vm',
      template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
      isNavigational: false,
    });
  };

  const items: NvDropdownOption[] = [
    { type: 'text', text: t.DISCUSSIONS.DROPDOWN.EDIT(), callback: onEditComment },
    { type: 'text', text: t.DISCUSSIONS.DROPDOWN.DELETE(), callback: onDeleteComment },
  ];

  const canEdit = comment.user.id === currentUser.id;

  return (
    <div
      css={commonStyles}
      onClick={(e) => e.stopPropagation()}
      className='discussion-comment-actions'
    >
      <BookmarkIcon
        size='smallest'
        bookmarked={!!comment.bookmarkId}
        onCreate={onCreateBookmark}
        onHighlight={onHighlightBookmark}
        className='p-1'
        showOnHover={false}
      />
      {canEdit && (
        <NvTooltip text={t.DISCUSSIONS.ACTIONS()}>
          <div>
            <NvDropdown
              minWidth={220}
              buttonStyle={NvDropdownButtonStyle.CUSTOM}
              items={items}
              align={NvDropdownAlign.RIGHT}
              customTarget={() => (
                <div className='p-1'>
                  <NvIcon size='smallest' icon='arrow-down' />
                </div>
              )}
              toggleDataQa='comment-actions-toggle'
            />
          </div>
        </NvTooltip>
      )}
    </div>
  );
};

export default DiscussionCommentActions;

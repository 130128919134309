import { css } from '@emotion/react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

import { RootState } from 'redux/schemas';
import { Post } from 'redux/schemas/models/post';

import NvUserAvatar from 'components/nv-user-avatar';

import { boldFontWeight } from 'styles/global_defaults/fonts';
import { gray6 } from 'styles/global_defaults/colors';
import { standardSpacing, halfSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { handheld, notHandheld, tablet } from 'styles/global_defaults/media-queries';

import { useAppDispatch } from 'redux/store';
import { createBookmark, highlightBookmark } from 'redux/actions/bookmarks';
import { BookmarkType } from 'redux/schemas/models/bookmark';
import { AngularServicesContext } from 'react-app';
import { Payload } from 'redux/schemas/api';
import { BookmarkResponse } from 'redux/schemas/api/bookmarks';
import BookmarkIcon from 'bookmarks/components/bookmark-icon';
import { TeamDiscussionLectureComponent } from 'redux/schemas/models/team-discussion';
import { isEmpty } from 'underscore';
import { TeamDiscussionContext } from './team-discussion';
import { config } from '../../../../config/pendo.config.json';

const PostBody = () => {
  const styles = css`
    display: flex;

    ${notHandheld(css`
      .post-body-avatar {
        width: 80px;
      }
    `)};

    ${handheld(css`
      .post-body-avatar {
        margin-bottom: ${standardSpacing}px;
      }
    `)};

    .post-body-wrapper {
      flex: 1;
      position: relative;

      ${notHandheld(css`
        margin-left: ${halfSpacing}px;
      `)};

      .bookmark-icon {
        position: absolute;
        top: ${halfSpacing}px;
        right: ${halfSpacing}px;
      }

      .show-on-hover {
        visibility: hidden;

        ${handheld(css`
          visibility: visible;
        `)};

        ${tablet(css`
          visibility: visible;
        `)};
      }

      &:hover {
        .show-on-hover {
          visibility: visible;
        }
      }

      .post-body-text {
        padding: ${standardSpacing}px;
        border: 1px solid ${gray6};
        border-bottom: none;

        .post-title {
          margin-bottom: ${standardSpacing}px;
          font-weight: ${boldFontWeight};
          word-break: break-word;
        }

        .text-body {
          word-break: break-word;
        }
      }

      &:before, & > .arrow, & > * > .arrow {
        content: '';
        width: 0;
        height: 0;
        background: transparent;
        border-color: transparent;
        border-style: solid;
        border-top-width: 0;
        border-left-width: 0;
        border-bottom-width: ${threeQuartersSpacing}px;
        position: absolute;
      }

      & > .arrow, & > * > .arrow {
        left: -8px;
        top: 1px;
        border-right-width: 10px;
        border-right-color: white;
      }

      &:before {
        border-right-width: 10px;
        left: -${halfSpacing}px;
        top: 0px;
        border-right-color: ${gray6};
      }
    }
  `;

  const dispatch = useAppDispatch();

  const { $state, FlyoutModalManager } = useContext(AngularServicesContext);

  const { postId, lectureComponentId } = useContext(TeamDiscussionContext);

  const post = useSelector<RootState, Post>((state: RootState) => state.models.posts[postId]);

  const lectureComponent = useSelector((state) => state.models.lectureComponents[lectureComponentId] as TeamDiscussionLectureComponent);

  const onCreateBookmark = () => {
    dispatch(createBookmark({
      type: BookmarkType.TOPIC,
      catalogId: $state.params.catalogId,
      componentId: post.id,
    })).then((response) => {
      FlyoutModalManager.openFlyout({
        controller: 'BookmarksFlyoutCtrl as vm',
        template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
        isNavigational: false,
      });
    });
  };

  const onHighlightBookmark = () => {
    dispatch(highlightBookmark({ id: post.bookmarkId }));
    FlyoutModalManager.openFlyout({
      controller: 'BookmarksFlyoutCtrl as vm',
      template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
      isNavigational: false,
    });
  };

  return (
    <div css={styles}>
      {/* Hidden only on xs */}
      <div className='d-none d-md-block'>
        <NvUserAvatar
          user={post.user}
          size='md'
          borderType='round'
          displayName
          className='post-body-avatar'
          directToProfile
          pendoTagName={config.pendo.userProfiles.openProfile}
        />
      </div>
      <div className='post-body-wrapper'>
        <div className='arrow' />
        {(lectureComponent.post !== lectureComponent.teamDiscussion) && (
          <BookmarkIcon
            size='small'
            bookmarked={!!post.bookmarkId}
            onCreate={onCreateBookmark}
            onHighlight={onHighlightBookmark}
            showOnHover={false}
          />
        )}
        <div className='post-body-text'>
          {/* Visible only on xs */}
          <div className='d-block d-md-none'>
            <NvUserAvatar
              user={post.user}
              size='md'
              borderType='round'
              displayName
              alignNameRight
              className='post-body-avatar'
              directToProfile
              pendoTagName={config.pendo.userProfiles.openProfile}
            />
          </div>
          {(!post.isTodo && isEmpty(post.pointsConfiguration)) && (
            <div className='post-title text-large-regular'>{post.title}</div>
          )}
          {/* eslint-disable-next-line react/no-danger */}
          <div className='text-body' dangerouslySetInnerHTML={{ __html: post.body }} />
        </div>
      </div>
    </div>
  );
};

export default PostBody;

import { css } from '@emotion/react';
import RadialGauge, { Mode } from 'institutions/components/radial-gauge';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import {
  CourseOutlineItem, LectureOutlineItem, SectionOutlineItem, SubsectionOutlineItem,
} from 'redux/selectors/course-outline/course-outline-item';
import { CourseOutlineNotOptimized, useCourseOutlineNotOptimized } from 'redux/selectors/course-outline/use-course-outline-not-optimized';
import useLectureStatus, { getEstimateReadout, LecturePageStatus } from 'redux/selectors/lecture-page';
import { getCurrentCourse } from 'redux/selectors/course';
import NvIcon from 'shared/components/nv-icon';
import { gray6, gray7, primary } from 'styles/global_defaults/colors';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { AngularServicesContext } from 'react-app';
import NvRouterLink from 'nv-router/nv-link';
import { isEmpty } from 'underscore';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { linkTolecturePage } from '../lecture-page-routes';
import { LecturePageMode } from '..';
import { config } from '../../../../config/pendo.config.json';

/**
 * WILL BE REMOVING THIS AND course-outline-navigation.tsx WILL BE USED
 * THIS IS USED FOR COURSE BUILDER ROLES ONLY SO THAT IT WILL DEPEND ON THE
 * BULKY course_timeline.json. THIS TICKET ONLY IMPACTS FOR LEARNERS -
 * https://novoed.atlassian.net/browse/NOV-80252
 * THE SCOPE WILL INCREASE IF WE HANDLE THE NEW API IN EDIT MODE, SO FOR NOW
 * THE COURSE BUILDER WON'T BE AFFECTED AND WILL BE PRESENTED WITH THE DATA
 * LOADED USING THE BULKY API. THIS FILE WILL BE REMOVED WHEN WE HANDLE THE
 * COURSE BUILDER TOO AND INTRODUCE THE OPTIMIZED API IN THE EDIT MODE TOO.
 *
 * OO-TODO - Remove this file
 */

type NavigationItemProps<T extends CourseOutlineItem> = {
  item: T
};

type ExpandedState = { [key: string]: boolean };
type SetExpanded = (subsectionId: number, expanded: boolean, keepExpanded?: boolean) => void;

const currentPageBgColor = 'bg-gray-6';

/** The visual course or collection outline & navigation buttons shown in the lecture page left panel */
export const CourseOutlineNavigationNotOptimized = () => {
  const params = useLecturePageParams();
  const courseOutline: CourseOutlineNotOptimized = useCourseOutlineNotOptimized(params.catalogId);

  const outlineItemKey = (item: CourseOutlineItem) => `${item.type}-${item.id}`;

  const { CurrentPermissionsManager } = useContext(AngularServicesContext);

  const isCourseAdmin = CurrentPermissionsManager.hasCourseAdminPermissions();

  // Track which lecture section UX'es have been expanded/collapsed
  const [sectionExpandedState, setSectionExpandedState] = useState<ExpandedState>(() => {
    if (!courseOutline) {
      return {};
    }

    const state = {};

    courseOutline.forEach(item => {
      // Hide all lecture pages in subsections by default
      if (item.type === 'subsection') {
        state[outlineItemKey(item)] = false;
      }
    });

    return state;
  });

  /** Visually expands a subsection
   * @param subsectionId ID of the subsection to change
   * @param expanded The new expanded state; false to collapse
   * @param keepExpanded If true, preserves the previous state. False collapses all states by resetting the whole data structure */
  const setItemExpanded = useCallback<SetExpanded>((subsectionId, expanded, keepExpanded) => {
    setSectionExpandedState((state) => ({
      // Ignore the previous state so that *only* the section containing the current lecture page is expanded.
      // Uncommenting the last line will keep states expanded if they were expanded before navigation
      ...(keepExpanded ? state : {}),
      [`subsection-${subsectionId}`]: expanded,
    }));
  }, []);

  // Expand the outline subsection for the current lecture page, if present
  useEffect(() => {
    // Iterate backwards through the outline array starting at the current lecture item and search for a 'subsection' item.
    // Then, expand that subsection item.
    const currentLectureIndex = courseOutline?.findIndex(outlineItem => outlineItem.type === 'lecture' && outlineItem.id === params.lecturePageId);
    let parentSubsectionIndex = currentLectureIndex - 1;

    while (parentSubsectionIndex > 0 && courseOutline[parentSubsectionIndex].type === 'lecture') {
      parentSubsectionIndex -= 1;
    }

    if (courseOutline?.[parentSubsectionIndex]?.type === 'subsection') {
      // Force all subsections to collapse upon navigation. We if there's ever a case where we want previously expanded subsections to stay expanded,
      // then this needs to be called with 'true' as the 2nd param
      setItemExpanded(courseOutline[parentSubsectionIndex].id, true, false);
    } else {
      setSectionExpandedState({});
    }
  }, [courseOutline, params.lecturePageId, setItemExpanded]);

  return (
    <div className='mt-4 w-100'>
      {!isEmpty(courseOutline) && courseOutline.map(item => {
        const key = outlineItemKey(item);

        if (!isCourseAdmin && item.type !== 'lecture' && !item.lecturesAreReleased) {
          return null;
        }

        switch (item.type) {
          case 'section':
            return <SectionNavigationItem key={key} item={item} />;
          case 'subsection':
            return <SubsectionNavigationItem key={key} item={item} expanded={sectionExpandedState[key]} setExpanded={setItemExpanded} />;
          case 'lecture':
            return (
              <LectureNavigationItem
                key={key}
                item={item}
                visible={item.sectionType === 'section' || sectionExpandedState[`${item.sectionType}-${item.section}`]}
              />
            );
          default:
            throw new Error(`Invalid item given to CourseOutlineNavigation: ${item}`);
        }
      })}
    </div>
  );
};

/** Navigation UX for individual section-level outline data */
const SectionNavigationItem = (props: NavigationItemProps<SectionOutlineItem>) => (
  <h1
    className='mt-4 pl-4 pr-4 card-title-small text-align-center mb-0'
    data-qa={config.pendo.outline.timeline.sectionRow}
  >
    {props.item.name}
  </h1>
);

/** Navigation UX for individual subsection-level outline data. Is an expandable container for lecture page nav items */
const SubsectionNavigationItem = (props: NavigationItemProps<SubsectionOutlineItem> & {
  /** Whether the section is uncollapsed to show its lecture pages */
  expanded: boolean,
  setExpanded: SetExpanded,
}) => {
  const styles = css`
    display: flex;
    align-items: center;

    :hover {
      color: ${primary};
      cursor: pointer;
    }

    .subsection-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin : 0px;
    }
  `;

  const { isContentManagementCollection } = useSelector(getCurrentCourse);

  const hasPoints = !!props.item.totalPoints;
  const isCompleted = props.item.allLecturePagesCompleted
    && (hasPoints ? props.item.pointsAccrued === props.item.totalPoints : true);

  const toggleExpanded = () => {
    props.setExpanded(props.item.id, !props.expanded, true);
  };
  return (
    <div
      css={styles}
      className='course-title-xs pl-4 pt-2 pb-2 border-bottom border-gray-6'
      onClick={() => toggleExpanded()}
      style={{ opacity: !isContentManagementCollection && !props.item.lecturesAreReleased ? 0.5 : 1 }}
      data-qa={config.pendo.outline.timeline.subsectionRow}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          toggleExpanded();
        }
      }}
      role='button'
      tabIndex={0}
      aria-expanded={props.expanded ? 'true' : 'false'}
      aria-label={props.item.name}
    >
      <div className='d-flex align-items-baseline w-100 text-truncate'>
        <h3
          className='subsection-title  course-title-xs pr-1'
          data-qa={config.pendo.outline.timeline.subsectionTitle}
        >
          {props.item.name}
        </h3>

        {isCompleted && (
          <span className='text-success course-title-xxs font-weight-bolder pl-1 pr-2'>
            {t.TIMELINE.COMPLETED()}
          </span>
        )}
        {props.item.achievableTotalPoints > 0 && (
          <span className='ml-auto pr-2'>
            <PointsDisplay item={props.item} />
          </span>
        )}
      </div>
      <div className='ml-auto pr-2'>
        <NvIcon
          icon={props.expanded ? 'arrow-up' : 'arrow-down'}
          size='xss'
          data-qa={props.expanded ? 'subsection-collapse' : 'subsection-expand'}
        />
      </div>
    </div>
  );
};

/** Navigation UX for individual lecture-level outline data. Is a clickable button that performs lecture page navigation */
const LectureNavigationItem = (props: NavigationItemProps<LectureOutlineItem> & {
  /** Whether this item can be seen because the parent subsection is expanded */
  visible: boolean,
}) => {
  const params = useLecturePageParams();
  const lecturePage = useSelector(state => state.models.lecturePages[props.item.id]);
  const lecturePageStatus = useLectureStatus(lecturePage.id);

  const isCurrentPage = params.lecturePageId === props.item.id;

  const { isContentManagementCollection } = useSelector(getCurrentCourse);

  if (!props.visible) {
    return null;
  }

  return (
    <NvRouterLink
      to={linkTolecturePage({
        ...params,
        lecturePageId: lecturePage.id,
      })}
      /** These are rendered as <a> elements, which by default show text-primary colored text when focused */
      css={css`
        :focus {
          color: unset;
        }
      `}
      id={`timeline-lecture-page-${lecturePage.id}`}
    >
      <div style={{ opacity: !isContentManagementCollection && !lecturePage.released ? 0.5 : 1, flex: 1 }}>
        <NonCardViewNavItem
          isCurrentPage={isCurrentPage}
          lecturePage={lecturePage}
          lecturePageStatus={lecturePageStatus}
          {...props}
        />
        {/* Lecture page left panel "card" view removed as per the JIRA card NOV-75168.
          We can use the git commit history of this card if we need the card view in the future. */}
      </div>
    </NvRouterLink>
  );
};

/** Styles for both the card and non-card view UXes below */
const lectureNavigationItemStyles = css`
  &:hover {
    cursor: pointer;
    .item-name {
      color: ${primary};
    }
  }

  .icon-panel {
    /* TODO: I'm really not a fan of setting a minwidth on this to achieve the correct offset. Let's revist this */
    min-width: 60px;
  }
`;

const nonCardLectureNavItemStyles = css`
  ${lectureNavigationItemStyles};
  min-height: 60px;
  border-bottom: 1px dashed ${gray6};

  .linked-lesson {
    min-width: ${standardSpacing}px;
    height: ${standardSpacing}px;
  }
`;

type InnerLectureNavigationItemProps = NavigationItemProps<LectureOutlineItem> & {
  isCurrentPage: boolean,
  /** TODO: Use this to retrieve the custom color & image options for this nav item */
  lecturePage: NLecturePage,
  lecturePageStatus: LecturePageStatus,
};

const NonCardViewNavItem = (props: InnerLectureNavigationItemProps) => {
  const params = useLecturePageParams();

  return (
    <div
      css={nonCardLectureNavItemStyles}
      className={`d-flex align-items-center pt-2 pb-2 pl-4 pr-4 ${props.isCurrentPage ? currentPageBgColor : ''}`}
      data-qa={config.pendo.outline.timeline.lessonRow}
    >
      {/* TODO: I'm really not a fan of setting a minwidth on this to achieve the correct offset. Placed in a style here as a reminder to rework */}
      <div className={`icon-panel pl-2 pr-2 ${props.isCurrentPage ? 'text-primary' : ''}`}>
        <OutlineIcon
          size='smallest'
          lecturePage={props.lecturePage}
          lecturePageStatus={props.lecturePageStatus}
          isCurrentPage={props.isCurrentPage}
        />

      </div>
      <div className='item-name'>
        <div className='mr-1 '>
          <span
            className={`${props.isCurrentPage ? 'font-weight-bold' : ''}`}
            data-lhs-lecturepage-name={props.item.name}
            data-qa={config.pendo.outline.timeline.lessonTitle}
          >
            {props.item.name}
          </span>
          { !props.lecturePage.released
          && props.lecturePage.releaseDate
          && (
          <span className='ml-1 text-small condensed'>
            {t.TIMELINE.RELEASE_DATE(moment(props.lecturePage.releaseDate).format('MM/DD/YYYY'))}
          </span>
          )}
        </div>

        {/* ONly show this section if there are points or estimate text to display.
        TODO: Unify these checks with the same checks inside this tag */}
        {(props.item.achievableTotalPoints > 0 || props.lecturePage.estimation?.estimatedEffort > 0) && (
          <div className='mt-1 d-flex align-items-baseline'>
            <span className='text-gray-2 text-small mr-1'>
              {props.lecturePage.estimation?.estimatedEffort > 0 && getEstimateReadout(props.lecturePage.estimation)}
            </span>
            {props.item.achievableTotalPoints > 0 && (
              <div className='pr-2'>
                <PointsDisplay item={props.item} />
              </div>
            )}
          </div>
        )}
      </div>
      { /* TODO: Verify that this time translates correctly. This doesn't use the moment format codes defined in app.js; the Angularjs app uses `'MOMENT.MONTH_DAY_YEAR'`, here */}

      {props.item.isLinked && params.mode !== LecturePageMode.VIEW && (
        <div className='linked-lesson d-flex align-items-center justify-content-center rounded-circle bg-primary ml-auto'>
          <NvIcon icon='format-makelink' size='xss-smallest' />
        </div>
      )}
    </div>
  );
};

const OutlineIcon = (props: {
  lecturePage: NLecturePage,
  lecturePageStatus: LecturePageStatus,
  isCurrentPage: boolean,
  size: string,
}) => (
  <div className={`${(props.lecturePageStatus === 'completed') ? 'text-success' : ''} text-center`}>
    { props.lecturePageStatus === 'in-progress'
      && <InProgressRadialGauge isCurrentPage={props.isCurrentPage} isCardView={false} />}
    { props.lecturePageStatus !== 'in-progress'
      && <NvIcon size={props.size} icon={props.lecturePageStatus === 'completed' ? 'check' : 'read'} />}
  </div>
);

const PointsDisplay = (props: { item: SubsectionOutlineItem | LectureOutlineItem }) => {
  const params = useLecturePageParams();

  const showPoints = props.item.achievableTotalPoints > 0;
  const showCurrentPoints = params.mode === LecturePageMode.VIEW && props.item.pointsAccrued > 0;

  if (!showPoints) {
    return null;
  }

  return (
    <span className='text-small text-gray-2 d-flex align-items-baseline'>

      <span className={`${showCurrentPoints ? 'text-success' : ''} mr-1`}>
        <NvIcon size='xss-smallest' icon={showCurrentPoints ? 'highlight' : 'points'} />
      </span>
      {(!showCurrentPoints || props.item.pointsAccrued === 0) && (
        <span className='font-weight-bolder'>{props.item.achievableTotalPoints}</span>
      )}

      {showCurrentPoints && props.item.pointsAccrued > 0
        && props.item.pointsAccrued < props.item.achievableTotalPoints && (
        <span>
          <span className='text-success font-weight-bold'>{props.item.pointsAccrued}</span>
          <span className='text-xsmall font-weight-bold'>
            /
            {props.item.achievableTotalPoints}
          </span>
        </span>
      )}

      {showCurrentPoints && props.item.pointsAccrued >= props.item.achievableTotalPoints && (
        <span className='font-weight-bolder text-success'>{props.item.pointsAccrued}</span>
      )}
    </span>
  );
};

const InProgressRadialGauge = (props: {
  isCurrentPage: boolean,
  isCardView: boolean,
}) => {
  let bkgColor = null;

  if (props.isCardView) {
    bkgColor = props.isCurrentPage ? gray6 : 'white';
  } else {
    bkgColor = props.isCurrentPage ? gray6 : gray7;
  }

  return (
    <RadialGauge
      activeColor={primary}
      bkgColor={bkgColor}
      // This is currently hardcoded to 50% completion; we do not do dynamic calulations on completion %
      // of these displays for historical reasons, but this can change in the future
      current={50}
      max={100}
      min={0}
      mode={Mode.FULL_CIRCLE}
      labelsDisabled
      width={36}
      meterThickness={2}
      iconClass='read'
    />
  );
};

export default CourseOutlineNavigationNotOptimized;

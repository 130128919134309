import t from 'react-translate';

// Components
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import { useFormContext, useWatch } from 'react-hook-form';
import { AngularServicesContext } from 'react-app';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { config } from '../../../config/pendo.config.json';

export const MS_LOBBY_BY_PASS_TYPES = {
  ORGANIZER: 'organizer',
  ORGANIZATION: 'organization',
  ORGANIZATION_AND_FEDERATED: 'organizationAndFederated',
  EVERYONE: 'everyone',
};

type MSTeamsCheckbox = {
  label: string;
  name: string;
  condition?: string;
  disabled?: boolean;
  className?: string;
  disabledText?: string;
};

const checkboxes: MSTeamsCheckbox[] = [
  { label: 'AUTORECORD', name: 'recordMeetingAutomatically' },
  { label: 'AUTOMATICALLY_UPLOAD_RECORDING', name: 'automaticallyUploadRecording', condition: 'recordMeetingAutomatically', className: 'ml-5' },
];

type MSTeamsSettingsProps = {
  disabled: boolean
  email: string
};

const MSTeamsSettings = (props: MSTeamsSettingsProps) => {
  const { email, disabled } = props;
  const lobbyByPassTypesRef = useRef<NvDropdownOption[]>([]);
  const { control } = useFormContext();
  const settings = useWatch({ control, name: 'settings' });
  const { $scope } = useContext(AngularServicesContext);
  const [displayMode, setDisplayMode] = useState($scope.$eval('vm.display'));

  useEffect(() => {
    $scope.$watch('vm.display', (newValue) => {
      setDisplayMode(newValue);
    });
  }, [$scope]);

  useEffect(() => {
    lobbyByPassTypesRef.current = Object.entries(MS_LOBBY_BY_PASS_TYPES).map(([key, value]) => ({
      id: value,
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.LOBBY_BY_PASS[key](),
      dataQa: `${config.pendo.liveEvent.liveEventSession.msTeams.lobbyByPass}-${value}`,
    }));
  }, []);

  const msTeamCustomSettings = useMemo(() => {
    if (displayMode === 'sessionFormWithoutRecordingScope') {
      return checkboxes.map(item => {
        if (item.name === 'recordMeetingAutomatically' || item.name === 'automaticallyUploadRecording') {
          return {
            ...item,
            disabled: true,
            disabledText: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.INVALID_OAUTH_TOOLTIP(),
          };
        }
        return item;
      });
    }
    return checkboxes;
  }, [displayMode]);

  return (
    <React.Fragment>
      {msTeamCustomSettings.map((checkbox: MSTeamsCheckbox) => (
        <React.Fragment key={checkbox.name}>
          {(!checkbox.condition || settings[checkbox.condition]) && (
            <div className='d-flex'>
              <NvCheckbox
                label={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL[checkbox.label]()}
                disabled={checkbox.disabled ?? disabled}
                name={`settings.${checkbox.name}`}
                className={checkbox.className}
                dataQa={config.pendo.liveEvent.liveEventSession.msTeams[checkbox.name]}
                withForm
              />
              {checkbox.disabled && !!checkbox.disabledText && (
                <NvTooltip enabled={checkbox.disabled && !!checkbox.disabledText} text={checkbox.disabledText}>
                  <NvIcon size='xss-smallest' icon='info' className='text-primary ml-1' />
                </NvTooltip>
              )}
            </div>
          )}
        </React.Fragment>
      ))}
      <div className='text-small bold gray-3 mt-2'>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.LOBBY_BY_PASS.LABEL()}</div>
      <NvFormDropdown
        title={t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.LOBBY_BY_PASS.LABEL()}
        name='settings.lobbyBypassSettings'
        items={lobbyByPassTypesRef.current}
        disabled={disabled}
        isSmallSize
      />
      <div className='bold text-small gray-2 mt-2'>
        {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.MS_TEAMS_ORGANIZER(email)}
      </div>
    </React.Fragment>
  );
};

export default MSTeamsSettings;

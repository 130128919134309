import { useSelector } from 'react-redux';
import { useContext } from 'react';
import t from 'react-translate';

import { getCurrentUser } from 'redux/selectors/users';
import { useAppDispatch } from 'redux/store';

import {
  NvDropdown,
  NvDropdownOption,
  NvDropdownButtonStyle,
  NvDropdownAlign,
} from 'shared/components/inputs/nv-dropdown';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';

import { AngularServicesContext } from 'react-app';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { deleteReply } from 'redux/actions/replies';

import { createBookmark, highlightBookmark } from 'redux/actions/bookmarks';
import { BookmarkType } from 'redux/schemas/models/bookmark';
import { Payload } from 'redux/schemas/api';
import { BookmarkResponse } from 'redux/schemas/api/bookmarks';
import BookmarkIcon from 'bookmarks/components/bookmark-icon';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import { TeamDiscussionContext } from './team-discussion';
import commonStyles from './common-action-styles';

interface DiscussionReplyActionsProps {
  replyId: number;
  commentId: number;
  onEditReply: (e: any) => void;
}

const DiscussionReplyActions = ({
  replyId,
  commentId,
  onEditReply,
}: DiscussionReplyActionsProps) => {
  const dispatch = useAppDispatch();

  const { TimelinesManager, FlyoutModalManager, $state } = useContext(AngularServicesContext);
  const { postId, angularComponent, currentLecture } = useContext(TeamDiscussionContext);

  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const currentUser = useSelector((state) => getCurrentUser(state));
  const post = useSelector((state) => state.models.posts[postId]);
  const reply = useSelector((state) => state.models.replies[replyId]);

  const timelineService = useTimelineService();

  const onDelete = () => {
    dispatch(deleteReply({
      commentId,
      catalogId,
      replyId,
      userId: currentUser.id,
      postId: post.id,
      // Update posts and progress of timeline after new post is fetched
      callback: (newPost) => {
        timelineService.updateTimeline(currentLecture.id);
        TimelinesManager.updateComponentPointsAndProgress(
          currentLecture.id,
          angularComponent.type,
          angularComponent.id,
          newPost.pointsReceived,
          newPost.totalPoints,
          newPost.progress,
        );
      },
    }));
  };

  const onDeleteReply = () => {
    const hasPoints = reply.pointsReceived;

    let deleteMessage = t.DISCUSSIONS.DELETE_REPLY_MSG();

    if (hasPoints && reply.user.id === currentUser.id) {
      deleteMessage = t.DISCUSSIONS.DELETE_REPLY_WITH_POINTS_MSG();
    }

    dispatch(openConfirmationDialog({
      icon: 'warning',
      bodyText: deleteMessage,
      confirmText: t.FORM.DELETE(),
      cancelText: t.FORM.CANCEL(),
      onConfirm: onDelete,
    }));
  };

  const onCreateBookmark = () => {
    dispatch(createBookmark({
      type: BookmarkType.COMMENT,
      catalogId: $state.params.catalogId,
      componentId: replyId,
    })).then(() => {
      FlyoutModalManager.openFlyout({
        controller: 'BookmarksFlyoutCtrl as vm',
        template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
        isNavigational: false,
      });
    });
  };

  const onHighlightBookmark = () => {
    dispatch(highlightBookmark({ id: reply.bookmarkId }));
    FlyoutModalManager.openFlyout({
      controller: 'BookmarksFlyoutCtrl as vm',
      template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
      isNavigational: false,
    });
  };

  const items: NvDropdownOption[] = [
    { type: 'text', text: t.DISCUSSIONS.DROPDOWN.EDIT(), callback: onEditReply },
    { type: 'text', text: t.DISCUSSIONS.DROPDOWN.DELETE(), callback: onDeleteReply },
  ];

  const canEdit = reply.user.id === currentUser.id;

  return (
    <div css={commonStyles} onClick={(e) => e.stopPropagation()} className='discussion-reply-actions'>
      <BookmarkIcon
        size='smallest'
        bookmarked={!!reply.bookmarkId}
        onCreate={onCreateBookmark}
        onHighlight={onHighlightBookmark}
        className='p-1'
        showOnHover={false}
      />
      {canEdit && (
        <NvTooltip text={t.DISCUSSIONS.ACTIONS()}>
          <div>

            <NvDropdown
              minWidth={220}
              buttonStyle={NvDropdownButtonStyle.CUSTOM}
              items={items}
              align={NvDropdownAlign.RIGHT}
              customTarget={() => (
                <div className='p-1'>
                  <NvIcon size='smallest' icon='arrow-down' />
                </div>
              )}
              toggleDataQa='reply-actions-toggle'
            />
          </div>
        </NvTooltip>
      )}
    </div>
  );
};

export default DiscussionReplyActions;

/**
 * We can't use nv-responsive-tabs because in this case
 * 1. The tab header is not text
 * 2. Also on responsive view it displays icons in tabs instead of the
 *    nv-responsive-tabs behaviour of displaying them as a dropdown
 */
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { useMediaQuery } from 'react-responsive';

import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';

import { AngularServicesContext } from 'react-app';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { doubleSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray6, primary, warning } from 'styles/global_defaults/colors';
import { screenMdMin, screenSmMin, screenXsMax } from 'styles/global_defaults/media-queries';
import Spinner from 'shared/components/spinner';
import { config } from '../../../../config/pendo.config.json';
import { CourseTimelineContext, Tab } from './course-timeline';
import useCompletionCriteria from '../completion_criteria/use-completion-criteria';

type TimelineTabsHeaderProps = {
  activeTab: Tab,
  onTabClick: Dispatch<SetStateAction<Tab>>,
};

type TimelineTab = {
  type: Tab,
  text?: string,
  icon?: string, // Icon to display in handheld
  renderElement?: React.ReactNode,
  tooltip: string,
  pendoTagName: string,
};

const PointsTabHeader = () => {
  const aliases = useSelector((state) => getCourseAliases(state));
  const {
    pointsReceived,
  } = useCompletionCriteria();
  const isCourseCompletionProgressLoading = useSelector((state) => state.app.timeline.isCourseCompletionProgressLoading);

  return (
    <div className='d-flex align-items-center'>
      <NvIcon icon='highlight' size='smallest' className='mr-2 color-positive' />
      <div className='sr-only'>
        {t.TIMELINE.POINTS_EARNED({ ...aliases.pointsAliases })}
      </div>
      {isCourseCompletionProgressLoading
        ? (
          <>
            <div className='ml-auto d-flex align-items-center justify-content-center'>
              <Spinner size={16} />
            </div>
            <span>&nbsp;</span>
          </>
        )
        : (<span>{pointsReceived}</span>)}
    </div>
  );
};

const GotoLectureButton = () => {
  const { $state } = useContext(AngularServicesContext);

  const aliases = useSelector((state) => getCourseAliases(state));
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const isCurrentLectureLoading = useSelector((state) => state.app.timeline.isCurrentLectureLoading);
  const currentLecturePage = useSelector((state) => state.app.timeline.currentLecturePage);

  const isTabletAndMobile = useMediaQuery({
    query: `(max-width: ${screenMdMin}px)`,
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${screenSmMin}px)`,
  });

  const lecturePageUrl = $state.href(
    'lecture-page',
    {
      catalogId,
      id: currentLecturePage.firstLecture?.id ?? currentLecturePage.currentLecture?.activityId,
    },
  );

  if (isCurrentLectureLoading) {
    return (
      <div className='ml-auto d-flex align-items-center justify-content-center'>
        <Spinner size={16} />
      </div>
    );
  }

  return (
    <div>
      {!currentLecturePage.firstLecture && !currentLecturePage.currentLecture && (
        <a
          className='btn btn-primary disabled'
        >
          {isMobile
            ? <NvIcon icon='arrow-right' size='small' aria-hidden='true' />
            : t.TIMELINE.START_LEARNING()}
        </a>
      )}
      {currentLecturePage.firstLecture && (
        <NvTooltip
          text={t.TIMELINE.START_LEARNING_POPOVER({
            lectureAlias: aliases.lectureAliases.LectureTitleAlias,
            lectureName: currentLecturePage.firstLecture.title,
          })}
          enabled={!isTabletAndMobile}
        >
          <a
            className='btn btn-primary'
            href={lecturePageUrl}
            pendo-tag-name={isTabletAndMobile
              ? config.pendo.outline.startLearningTabletMobile
              : config.pendo.outline.startLearningDesktop}
            data-qa={isTabletAndMobile
              ? config.pendo.outline.startLearningTabletMobile
              : config.pendo.outline.startLearningDesktop}
            aria-label={t.TIMELINE.START_LEARNING()}
          >
            {isMobile
              ? <NvIcon icon='arrow-right' size='small' aria-hidden='true' />
              : t.TIMELINE.START_LEARNING()}
          </a>
        </NvTooltip>
      )}
      {currentLecturePage.currentLecture && (
        <NvTooltip
          text={t.TIMELINE.RESUME_LEARNING_POPOVER({
            lectureAlias: aliases.lectureAliases.LectureTitleAlias,
            lectureName: currentLecturePage.currentLecture.title,
          })}
          enabled={!isTabletAndMobile}
        >
          <a
            className='btn btn-primary'
            href={lecturePageUrl}
            pendo-tag-name={isTabletAndMobile
              ? config.pendo.outline.resumeLearningTabletMobile
              : config.pendo.outline.resumeLearningDesktop}
            data-qa={isTabletAndMobile
              ? config.pendo.outline.resumeLearningTabletMobile
              : config.pendo.outline.resumeLearningDesktop}
            aria-label={t.TIMELINE.RESUME_LEARNING()}
          >
            {isMobile
              ? <NvIcon icon='arrow-right' size='small' aria-hidden='true' />
              : t.TIMELINE.RESUME_LEARNING()}
          </a>
        </NvTooltip>
      )}
    </div>
  );
};

const TimelineTabsHeader = ({
  activeTab,
  onTabClick,
}: TimelineTabsHeaderProps) => {
  const { showOutline, isLearnerProgressModal } = useContext(CourseTimelineContext) || {};

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const styles = css`
    border-bottom: 1px solid ${gray6};
    border-top: 1px solid ${gray6};
    position: sticky;
    top: ${isLearnerProgressModal ? 0 : 60}px; // Including institution branding 5px + 55px;
    background-color: #ffffff;
    z-index: 1000;

    .timeline-header-tabs-container {
      height: ${standardSpacing * 3}px;

      .timeline-header-tab {
        position: relative;

        &:not(.active):hover {
          color: ${primary};
        };

        &:not(:last-of-type) {
          margin-right: ${isHandheld ? standardSpacing : doubleSpacing}px;
        }

        &.active .bottom-border {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
          background-color: ${warning};
        }
      }
    }
  `;

  const aliases = useSelector((state) => getCourseAliases(state));
  const currentCourse = useSelector(getCurrentCourse);

  const showGotoLectureButton = !currentCourse.isProgram && !isLearnerProgressModal;

  const tabs: TimelineTab[] = [];

  if (showOutline) {
    tabs.push({
      type: Tab.OUTLINE,
      text: currentCourse.isProgram
        ? t.COURSE_HOME.HEADER.RESOURCES()
        : t.TIMELINE.COURSE_OUTLINE({ courseAlias: aliases.courseAliases.CourseAlias }),
      tooltip: currentCourse.isProgram
        ? t.COURSE_HOME.HEADER.RESOURCES()
        : t.TIMELINE.COURSE_OUTLINE({ courseAlias: aliases.courseAliases.CourseAlias }),
      icon: 'course-menu',
      pendoTagName: config.pendo.outline.outlineTab,
    });
  }

  tabs.push({
    type: Tab.TODOS,
    text: t.TIMELINE.TODOS(),
    tooltip: t.TIMELINE.TODOS(),
    icon: 'assignments',
    pendoTagName: config.pendo.outline.todosTab,
  });

  if (currentCourse.gamificationEnabled) {
    tabs.push({
      type: Tab.POINTS,
      renderElement: <PointsTabHeader />,
      tooltip: t.TIMELINE.POINTS_EARNED({ ...aliases.pointsAliases }),
      pendoTagName: config.pendo.outline.pointsTab,
    });
  }

  return (
    <div css={styles} className=''>
      <div className='mx-4'>
        <div className='timeline-header-tabs-container body-text-wrapper d-flex justify-content-between'>
          <div
            className='d-flex'
            role='tablist' // Indicates this is a tablist
            aria-label={t.TIMELINE.COURSE_TIMELINE_HEADER({ courseAlias: aliases.courseAliases.CourseAlias })}
          >
            {tabs.map((tab) => (
              <NvTooltip key={tab.type} text={tab.tooltip}>
                <ClickableContainer
                  role='tab' // Indicates each item is a tab
                  aria-selected={tab.type === activeTab} // Indicates whether this tab is selected
                  id={`tab-${tab.type}`}
                  aria-controls={`tab-panel-${tab.type}`} // Identifies the associated panel (you may want to set up panels)
                  className={`${tab.type === activeTab ? 'active' : ''} ${isHandheld ? 'card-title-small' : 'card-title'} timeline-header-tab d-flex justify-content-center align-items-center p-2`}
                  onClick={() => onTabClick(tab.type)}
                  pendo-tag-name={tab.pendoTagName}
                  data-qa={tab.pendoTagName}
                >
                  {/* If text then it will be displayed */}
                  {tab.text && (
                    <React.Fragment>
                      {isHandheld ? (
                        <NvIcon icon={tab.icon} size='small' />
                      ) : tab.text}
                    </React.Fragment>
                  )}
                  {/* If renderElement then it will be displayed */}
                  {tab.renderElement}
                  <div className='bottom-border' />
                </ClickableContainer>
              </NvTooltip>
            ))}
          </div>
          <div className='timeline-header-buttons-wrapper d-flex align-items-center'>
            {showGotoLectureButton && <GotoLectureButton />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineTabsHeader;

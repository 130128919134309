import { ChartData, ChartOptions, TooltipModel, ChartTypeRegistry, TooltipItem } from 'chart.js';
import { useRef, useEffect, createContext, useCallback, useContext } from 'react';
import t from 'react-translate';
import { SubmissionTab, RateOfSpeech, PracticeSubmissionComment, SkillsRating } from 'redux/schemas/models/video-practice';
import { NvTab } from 'shared/components/nv-responsive-tabs';
import { primary, gray1, gray4, gray5 } from 'styles/global_defaults/colors';
import { map } from 'underscore';
import { Post, PostCommentParams } from 'redux/schemas/api/video-practice';
import { useSelector } from 'react-redux';
import { setFilteredComments, getComments, postComment, setPracticeFeedbackData } from 'redux/actions/video-practice';
import { useAppDispatch } from 'redux/store';
import { SkillTag } from 'redux/schemas/models/skill-tag';
import { NvVideoPreviewImperativeInterface } from 'shared/components/nv-video-preview';
import { Badge } from 'shared/components/nv-seekbar-badges';
import { AngularServicesContext } from 'react-app';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import { VideoPracticeFeedbackActivity } from 'redux/schemas/models/video-practice-feedback';
import { getLectureComponent } from 'redux/selectors/lecture-components';
import { getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';
import { config as configConstants } from '../../../../config/config.json';

export type Tab = NvTab & {
  tabValue: SubmissionTab
};

export const getChartData = (rateOfSpeech: RateOfSpeech[]): {
  data: ChartData, options: ChartOptions
} => {
  const labels = []; const wpm = [];
  map(rateOfSpeech, value => {
    /**
     * 0 -
     * 30 s
     * To display x axis ticks labels in multiple line
     */
    const label = [`${value.start} -`, `${value.end} s`];
    labels.push(label);
    wpm.push(value.wordsPerMinute);
  });
  const data: ChartData = {
    labels,
    datasets: [{
      label: 'WPM',
      data: wpm,
      fill: false,
      borderColor: primary,
      tension: 0,
      borderWidth: 1,
    }],
  };
  const options: ChartOptions = {
    scales: {
      y: {
        suggestedMin: 90,
        suggestedMax: 180,
        grid: { display: false, drawBorder: false },
        ticks: {
          // To add suffix or prefix with tick value
          callback(value, index, ticks) {
            return `${value} WPM`;
          },
          font: {
            size: 10,
            family: 'inherit',
          },
          autoSkip: true,
          color: gray1,
          align: 'inner',
        },
      },
      x: {
        grid: { display: false, borderColor: gray4, borderWidth: 1.5 },
        ticks: {
          color: gray1,
          font: {
            size: 10,
            family: 'inherit',
          },
          align: 'inner',
        },
      },
    },
    borderColor: gray4,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label(this: TooltipModel<keyof ChartTypeRegistry>,
            item: TooltipItem<keyof ChartTypeRegistry>) {
            const label = `${item.formattedValue} WPM`;
            return label;
          },
          title(tooltipItems: TooltipItem<keyof ChartTypeRegistry>[]) {
            const title = tooltipItems[0].label.replace(/[ ,]/g, '');
            return title;
          },
        },
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: configConstants.recordings.wpm.fast,
            yMax: configConstants.recordings.wpm.fast,
            borderDash: [5, 5],
            borderColor: gray5,
          },
          line2: {
            type: 'line',
            yMin: configConstants.recordings.wpm.slow,
            yMax: configConstants.recordings.wpm.slow,
            borderDash: [5, 5],
            borderColor: gray5,
          },
          label1: {
            type: 'label',
            xValue() {
              if (wpm.length - 1 > 0) { return wpm.length - 1; }
              return 1;
            },
            yValue: configConstants.recordings.wpm.fast,
            content: t.PRACTICE_ROOM.INSIGHTS.WPM.FAST(),
            font: {
              size: 12,
              family: 'inherit',
            },
            color: gray4,
            position: 'end',
          },
          label2: {
            type: 'label',
            xValue() {
              if (wpm.length - 1 > 0) { return wpm.length - 1; }
              return 1;
            },
            yValue: configConstants.recordings.wpm.slow,
            content: t.PRACTICE_ROOM.INSIGHTS.WPM.SLOW(),
            font: {
              size: 12,
              family: 'inherit',
            },
            color: gray4,
            position: 'end',
          },
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        backgroundColor: primary,
      },
    },
  };
  return { data, options };
};

export const bgGradient = 'linear-gradient(88.8deg, #1E9FD6 2.06%, #6597FF 97.94%)';

export const rtlBgGradient = 'linear-gradient(-88.8deg, #1E9FD6 2.06%, #6597FF 97.94%)';

/* @ngInject */
export function selectedViewToIndex(selectedView: SubmissionTab) {
  switch (selectedView) {
    case SubmissionTab.COMMENTS:
      return 0;
    case SubmissionTab.AUTHOR_FEEDBACK:
      return 1;
    case SubmissionTab.ALL_FEEDBACK:
      return 2;
    case SubmissionTab.INSIGHTS:
      return 3;
    default:
      return 0;
  }
}

export const INITIAL_COMMENTS_PER_PAGE = 5;

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useSubmitComment = (
  submissionId: number,
  isPracticeFeedback: boolean,
  afterSubmit: () => void,
) => {
  const filteredComments = useSelector((state) => state.app.practiceRoom.filteredComments?.[submissionId]);
  const practiceFeedbackCriteriaId = useSelector(state => state.app.videoPracticeFeedback?.practiceFeedbackCriteriaId);
  const currentTimestamp = useSelector(state => state.app.videoPracticeSubmissions[submissionId]?.currentTimestamp);

  const dispatch = useAppDispatch();

  const onSubmit = useCallback(async (comment: Post) => {
    const data: PostCommentParams = {
      post: comment,
      submissionId,
    };
    if (!data.post.videoTimestamp && currentTimestamp) {
      data.post.videoTimestamp = currentTimestamp;
    }
    if (filteredComments?.length > 0) {
      dispatch(setFilteredComments({
        submissionId,
        filteredComments: [],
      }));
      dispatch(getComments({ submissionId, page: 1 }));
    }
    if (practiceFeedbackCriteriaId) {
      data.practiceFeedbackCriteriaId = practiceFeedbackCriteriaId;
    }
    const { payload } = await dispatch(postComment(data));
    if (!payload) {
      return false;
    }
    if (isPracticeFeedback && payload.isUserFirstReview) {
      dispatch(setPracticeFeedbackData(payload));
    }
    afterSubmit();
    return true;
  }, [
    afterSubmit, currentTimestamp, dispatch, filteredComments?.length,
    isPracticeFeedback, practiceFeedbackCriteriaId, submissionId,
  ]);
  return { onSubmit };
};

export enum ActionTypes {
  SET_TIME_STAMP = 'setTimeStamp',
  SET_SHOW_COMMENT_BUTTON = 'setShowCommentButton',
  SET_SELECTED_VIEW = 'setSelectedView',
  SET_SHOW_FEEDBACK_FORM = 'setShowFeedbackForm',
  SET_SHOW_RECORD_VIDEO_COMMENT = 'setShowRecordVideoComment',
  SET_SHOW_COMMENT_INPUT_ROW = 'setShowCommentInputRow',
  ON_CLOSE_COMMENT_FORM = 'onCloseCommentForm',
  SET_SUBMISSION = 'setSubmission',
  SET_SKILLS_VIEW = 'setSkillsView',
  SET_GENERAL_ATTRIBUTES = 'setGeneralAttributes',
}

/* @ngInject */
export function PracticeSubmissionReducer(
  state: {
    currentTimestamp?: number,
    selectedView?: SubmissionTab,
    showCommentButton?: boolean,
    showFeedbackForm?: boolean,
    showRecordVideoComment?: boolean,
    showCommentInputRow?: boolean,
    submissionId: number,
    practiceSubmissionRefs?: {
      playerInstanceRef: React.MutableRefObject<NvVideoPreviewImperativeInterface>,
      videoRef: React.MutableRefObject<any>
    },
    scenarioId?: number,
    // The lecturecomponent id where the feedback activity is created
    // will be present only when redirected from feedback gallery
    lectureComponentId: number,
    submissionIds?: number[],
    // true, when user selects a scenario from the flyout / practice from practice activity submission gallery
    isPracticeRoom?: boolean,
    skillTags?: SkillTag[],
    // true when the admin enter to the practice room while the course that created is in the background
    isCourseAdmin?: boolean,
    // true when redirected from practice feedback component
    isPracticeFeedback?: boolean,
    isMyPractice?: boolean,
    // will be true if the practice redirected from a skill feedback activity on a lecture page
    // eg: when user open a submission from the feedback gallery of practice skill feedback component
    isSkillsFeedbackActivity?: boolean,
    // isCourseAdmin OR isPracticeAdmin OR hasCourseAdmin
    isAdmin: boolean,
    // 'mentor_received' or undefined - this param bring others mentors skills feedback
    skillsView?: 'mentor_received',
  },
  action: { type: ActionTypes, payload?: any },
) {
  switch (action.type) {
    case ActionTypes.SET_TIME_STAMP: {
      return {
        ...state,
        currentTimestamp: action.payload,
      };
    }
    case ActionTypes.SET_SELECTED_VIEW: {
      return {
        ...state,
        selectedView: action.payload,
      };
    }
    case ActionTypes.SET_SHOW_COMMENT_BUTTON: {
      return {
        ...state,
        showCommentButton: action.payload,
      };
    }
    case ActionTypes.SET_SHOW_FEEDBACK_FORM: {
      return {
        ...state,
        showFeedbackForm: action.payload,
      };
    }
    case ActionTypes.SET_SHOW_RECORD_VIDEO_COMMENT: {
      return {
        ...state,
        showRecordVideoComment: action.payload,
      };
    }
    case ActionTypes.SET_SHOW_COMMENT_INPUT_ROW: {
      return {
        ...state,
        showCommentInputRow: action.payload,
      };
    }
    case ActionTypes.ON_CLOSE_COMMENT_FORM: {
      return {
        ...state,
        showCommentInputRow: false,
        showRecordVideoComment: false,
        showCommentButton: true,
      };
    }
    case ActionTypes.SET_SUBMISSION: {
      return {
        ...state,
        submissionId: action.payload,
      };
    }
    case ActionTypes.SET_SKILLS_VIEW: {
      return {
        ...state,
        skillsView: action.payload,
      };
    }
    case ActionTypes.SET_GENERAL_ATTRIBUTES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

export const PracticeSubmissionContext = createContext<
[React.ReducerState<typeof PracticeSubmissionReducer>, React.Dispatch<React.ReducerAction<typeof PracticeSubmissionReducer>>]
>([
  {
    currentTimestamp: null,
    selectedView: null,
    showCommentButton: false,
    showFeedbackForm: false,
    showRecordVideoComment: false,
    showCommentInputRow: false,
    submissionId: null,
    scenarioId: null,
    lectureComponentId: null,
    submissionIds: null,
    isPracticeRoom: false,
    skillTags: [],
    isCourseAdmin: false,
    isPracticeFeedback: false,
    isMyPractice: false,
    isSkillsFeedbackActivity: false,
    practiceSubmissionRefs: {
      playerInstanceRef: null,
      videoRef: null,
    },
    isAdmin: false,
    skillsView: undefined,
  },
  () => {
    throw new Error('Please wrap your component with the  PracticeSubmissionContext context provider');
  },
]);

export const useFilterComments = () => {
  const [{ practiceSubmissionRefs, submissionId }] = useContext(PracticeSubmissionContext);

  const dispatch = useAppDispatch();
  const filterCommentsOfTime = useCallback((badge: Badge) => {
    if (practiceSubmissionRefs.playerInstanceRef.current) {
      practiceSubmissionRefs.playerInstanceRef.current.play(false);
      if (badge.meta.time) {
        // Seek to two seconds before
        const seekTime = badge.meta.time > 2 ? (badge.meta.time - 2) : 0;

        practiceSubmissionRefs.playerInstanceRef.current.seek(seekTime);
      }
    }

    dispatch(setFilteredComments({
      submissionId,
      filteredComments: badge.meta.extras.commentIds,
    }));
  }, [practiceSubmissionRefs.playerInstanceRef, dispatch, submissionId]);

  return { filterCommentsOfTime };
};

export const useUpdateTimeline = (
  practiceFeedbackActivity: VideoPracticeFeedbackActivity,
  isSkillsFeedbackActivity: boolean,
  isPracticeFeedback: boolean,
  callback?: () => void,
) => {
  const { $uibModal, TimelinesManager } = useContext(AngularServicesContext);

  const practiceFeedbackData = useSelector(state => state.app.videoPracticeFeedback?.practiceFeedbackData);
  const lectureComponent = useSelector(state => (
    practiceFeedbackActivity ? getLectureComponent(state, practiceFeedbackActivity.lectureComponentId) : {}
  ));
  const skillTags = useSelector(state => getSkillTagsFromTaggings(state, lectureComponent?.skillTaggings ?? [])) ?? [];
  const dispatch = useAppDispatch();
  const timelineService = useTimelineService();

  const updateTimelineAndComponentProgress = (
    data: PracticeSubmissionComment | SkillsRating,
    message: string,
    status: 'completed' | 'in_progress',
  ) => {
    const { pointsReceived, leaderboardPoints } = data;
    dispatch(addAlertMessage({
      type: AlertMessageType.SUCCESS,
      header: t.FORM.SUCCESS_BANG(),
      message,
    }));

    timelineService.updateTimeline(practiceFeedbackActivity.lecturePageId);
    if (pointsReceived) {
      TimelinesManager.updateComponentPointsAndProgress(
        practiceFeedbackActivity.lecturePageId,
        'practice_feedback_criteria',
        practiceFeedbackActivity.id,
        pointsReceived,
        leaderboardPoints,
        status,
      );
    } else {
      TimelinesManager.updateComponentProgress(
        practiceFeedbackActivity.lecturePageId,
        'practice_feedback_criteria',
        practiceFeedbackActivity.id,
        status,
      );
    }
  };

  useEffect(() => {
    if (practiceFeedbackData) {
      const data = isSkillsFeedbackActivity ? practiceFeedbackData?.review : practiceFeedbackData;
      const { pointsReceived, leaderboardPoints, leaderboardRank, priorLeaderboardRank } = data;
      if (data) {
        if ((isPracticeFeedback || isSkillsFeedbackActivity) && practiceFeedbackActivity?.progress !== 'missed') {
          const isBeyondRequirements = practiceFeedbackActivity?.numReviewesCompleted > practiceFeedbackActivity?.requiredToComplete;
          if (practiceFeedbackActivity?.numReviewesCompleted >= practiceFeedbackActivity?.requiredToComplete) {
            let message = isSkillsFeedbackActivity
              ? t.PRACTICE_ROOM.SKILL_FEEDBACK.ACTIVITY_COMPLETED(practiceFeedbackActivity.requiredToComplete)
              : t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.ACTIVITY_COMPLETED(
                practiceFeedbackActivity.requiredToComplete,
              );
            if (isBeyondRequirements) {
              message = t.PRACTICE_ROOM.SKILL_FEEDBACK.ACTIVITY_COMPLETED_BEYOND();
            }

            // Update points and progress of timeline after activity has been submitted
            // Update completed
            updateTimelineAndComponentProgress(data, message, 'completed');
          } else {
            const getRemainingVideos = () => (
              practiceFeedbackActivity?.requiredToComplete - practiceFeedbackActivity?.numReviewesCompleted
            );
            const message = isSkillsFeedbackActivity
              ? t.PRACTICE_ROOM.SKILL_FEEDBACK.SUCCESS_ALERT(getRemainingVideos())
              : t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.SUCCESS_MSG();
            if (practiceFeedbackActivity?.progress !== 'completed') {
              updateTimelineAndComponentProgress(data, message, 'in_progress');
            }
          }
          const currentPointsReceived = practiceFeedbackActivity?.pointsConfiguration?.points;
          const showSkillTagsAnimation = skillTags.length > 0;

          if ((pointsReceived || showSkillTagsAnimation) && !isBeyondRequirements) {
            $uibModal.open({
              templateUrl: 'shared/templates/points-modal.html',
              windowClass: 'points-modal',
              controller: 'PointsModalCtrl as vm',
              resolve: {
                pointsReceived: currentPointsReceived,
                leaderboardPoints,
                leaderboardRank,
                priorLeaderboardRank,
                extras: { skillTags },
              },
            });
          }
        }
        dispatch(setPracticeFeedbackData(null));
        callback();
      }
    }
  }, [
    practiceFeedbackData,
    practiceFeedbackActivity?.pointsReceived,
    practiceFeedbackActivity?.numReviewesCompleted,
    practiceFeedbackActivity?.pointsConfiguration?.points,
    practiceFeedbackActivity?.progress,
    practiceFeedbackActivity?.requiredToComplete,
  ]);
};

import { css } from '@emotion/react';
import { MouseEvent } from 'react';
import t from 'react-translate';

import { Comment, isComment } from 'redux/schemas/models/comment';
import ClickableContainer from 'components/clickable-container';
import { Reply } from 'redux/schemas/models/reply';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { primary } from 'styles/global_defaults/colors';
import { config } from '../../../../config/pendo.config.json';

interface DiscussionRepliesProps {
  isRepliesExpanded?: boolean,
  discussionComponent: Comment | Reply
  onClickIcon: (event: MouseEvent) => void
  onClickText?: (event: MouseEvent) => void
}

const DiscussionReplies = (props: DiscussionRepliesProps) => {
  const styles = css`
    display: flex;
    align-items: center;

    button {
      border: none;
      background: none;
      outline: none;
      padding-left: 0;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      .icon-reply, .replies-count {
        color: ${primary};
      }
    }

    .icon-reply:hover {
      color: ${primary};
    }
  `;

  let className = '';
  if (props.discussionComponent.commented) {
    className = 'active';
  }

  return (
    <div css={styles} className={`discussion-replies ${className}`}>
      <NvTooltip
        text={t.DISCUSSIONS.REPLY()}
        placement='bottom'
      >
        <button
          type='button'
          onClick={props.onClickIcon}
          pendo-tag-name={config.pendo.teamDiscussion.replyIcon}
          aria-label={t.DISCUSSIONS.REPLY()}
          className='p-1'
        >
          <NvIcon size='smallest' icon='reply' />
        </button>
      </NvTooltip>
      {isComment(props.discussionComponent) && props.discussionComponent?.repliesCount > 0 && (
        <NvTooltip
          text={t.DISCUSSIONS.REPLY_COUNT_TOOLTIP(props.isRepliesExpanded.toString())}
          placement='bottom'
        >
          <ClickableContainer
            className='replies-count btn-link mr-4'
            onClick={props.onClickText}
            aria-expanded={props.isRepliesExpanded}
          >
            <span className='sr-only'>{t.COURSE_HOME[props.isRepliesExpanded ? 'COLLAPSE' : 'EXPAND']()}</span>
            {props.discussionComponent?.repliesCount}
            <span className='sr-only'>{t.DISCUSSIONS.REPLIES()}</span>
          </ClickableContainer>
        </NvTooltip>
      )}
    </div>
  );
};

export default DiscussionReplies;

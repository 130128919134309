/* @ngInject */
export default function NvSubmissionsCarousel(
  _,
  moment,
  $state,
  $stateParams,
  $timeout,
  CurrentCourseManager,
  CurrentUserManager,
  ReportsManager,
  nvUtil,
  config,
) {
  const DEFAULT_SLIDES_PER_SCROLL = 5;
  const BREAKPOINTS_WITH_TIMELINE_EXPANDED = [
    {
      breakpoint: 1780,
      settings: {
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToScroll: 1,
      },
    },
  ];

  const BREAKPOINTS_WITH_TIMELINE_HIDDEN = [
    {
      breakpoint: 1460,
      settings: {
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1180,
      settings: {
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 710,
      settings: {
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToScroll: 1,
      },
    },
  ];

  return {
    scope: {
      acountForTimelineWidth: '=?',
      submissions: '=?', // this is all the submissions that can be display
      carouselSubmissions: '=', // this seems to be a clone or a subset of submissions, apparently slick carousel breaks if we try to update the original set

      submissionsReleased: '=?', // only show submissions if released, if no released, show error message

      showApprovalProgress: '=?',
      showApprovalPendingTime: '=?',
      showDueDate: '=?',
      showRevisionRemainderTime: '=?',
      hideSocial: '=?',
      showCourseHeaderInLink: '=?',

      isPublicFeedbackOn: '=?', // if this is a carouself for public feedback, we display a public feedback specific error message
      requiredExercise: '=?', // relevant exercise for this set for submissions
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      const currentMoment = moment();
      let deadlineMoment;
      let releaseDateMoment;
      vm.config = config;
      vm.isContentManagementCollection = CurrentCourseManager?.course?.isContentManagementCollection;
      vm.sliderComponent = null;

      _.extend(vm, {
        CurrentCourseManager,
        CurrentUserManager,

        // data
        filterOption: this.isPublicFeedbackOn ? 'not_my_comments' : null,
        slickConfig: {
          infinite: false,
          arrows: vm.carouselSubmissions?.length > DEFAULT_SLIDES_PER_SCROLL,
          slidesToScroll: DEFAULT_SLIDES_PER_SCROLL,
          variableWidth: true,
          responsive: vm.acountForTimelineWidth && CurrentUserManager.user.isTimelineExpanded ? BREAKPOINTS_WITH_TIMELINE_EXPANDED : BREAKPOINTS_WITH_TIMELINE_HIDDEN,
          method: {},
          rtl: nvUtil.isRtl(),
          event: {
            init: (event, slick) => {
              vm.sliderComponent = event.currentTarget;
              // This method helps to identify the tabbing in the slide, in order to
              // call the slickNext or slickPrev, to avoid scrolling the slide
              // and causing an error when calculating the rest of elements to show (NOV-85682)
              vm.sliderComponent.onkeydown = (eventKeydown) => {
                // Listening the Tab key pressing
                if (eventKeydown.key === 'Tab') {
                  const isTabWithShift = eventKeydown.shiftKey;
                  const { slidesToScroll } = slick.options;
                  const focusedElementHashKey = document.activeElement.$$hashKey;
                  const slides = slick.$slides.toArray();
                  const currentIndexElement = slides.findIndex(element => element.children[0].$$hashKey === focusedElementHashKey);
                  if (!isTabWithShift && (currentIndexElement % slidesToScroll === slidesToScroll - 1) && (currentIndexElement < slides.length - 1)) {
                    eventKeydown.stopPropagation();
                    eventKeydown.preventDefault();
                    slick.slickNext();
                    setTimeout(() => {
                      slick.$slides[currentIndexElement + 1].children[0].focus();
                    }, 100); // An adjusted time to avoid double slickNext
                  }
                  if (isTabWithShift && (currentIndexElement % slidesToScroll === 0) && (currentIndexElement > slidesToScroll - 1)) {
                    eventKeydown.stopPropagation();
                    eventKeydown.preventDefault();
                    slick.slickPrev();
                    setTimeout(() => {
                      slick.$slides[currentIndexElement - 1].children[0].focus();
                    }, 100); // An adjusted time to avoid double slickPrev
                  }
                }
              };
            },
            setPosition: (event, slick) => {
              if (slick.$slides?.length > 0) {
                const slickElementWidth = $(slick.$slider).width();
                const slickItemElementWidth = $(slick.$slides[0]).width();

                $timeout(() => {
                  if (slickElementWidth > slickItemElementWidth * slick.$slides.length) {
                    $(slick.$slider).addClass('centered-view');
                  } else {
                    $(slick.$slider).removeClass('centered-view');
                  }
                });
              }
            },
            breakpoint: (event, slick, breakpoint) => {
              if (breakpoint) {
                const responsive = vm.acountForTimelineWidth && CurrentUserManager.user.isTimelineExpanded ? BREAKPOINTS_WITH_TIMELINE_EXPANDED : BREAKPOINTS_WITH_TIMELINE_HIDDEN;
                // eslint-disable-next-line no-plusplus
                for (let i = responsive.length - 1; i >= 0; i--) {
                  const point = responsive[i];
                  if (point.breakpoint <= breakpoint) {
                    vm.slickConfig.arrows = vm.carouselSubmissions?.length > point.settings.slidesToScroll;
                    break;
                  }
                }
              } else {
                vm.slickConfig.arrows = vm.carouselSubmissions?.length > DEFAULT_SLIDES_PER_SCROLL;
              }
            },
          },
          accessibility: false,
        },

        // functions
        dateValue,
        setReports,
      });

      if (vm.requiredExercise) {
        deadlineMoment = moment(vm.requiredExercise.deadline);
        releaseDateMoment = moment(vm.requiredExercise.releaseDate);
      }

      function dateValue() {
        if (vm.requiredExercise) {
          const dateToFormat = moment(vm.requiredExercise.submissionsViewableBeforeDeadline ? releaseDateMoment : deadlineMoment);

          return dateToFormat.format(dateToFormat.isSame(currentMoment, 'year') ? 'MOMENT.MONTH_DAY_AT_TIME' : 'MOMENT.MONTH_DAY_COMMA_YEAR_AT_TIME');
        }

        return null;
      }

      function setReports(submission) {
        let submissionCatalogId;
        if (submission.exercise) {
          submissionCatalogId = submission.exercise.catalogId || submission.exercise.course?.catalogId;
        }

        if (!submissionCatalogId) {
          submissionCatalogId = $stateParams.catalogId;
        }

        const reports = [...vm.submissions];
        if (_.last(reports).isPlaceholder) {
          reports.pop();
        }

        ReportsManager.initialize({
          reportsCatalogId: $stateParams.catalogId,
          reports,
        });

        $state.go('individual-submission-modal', {
          catalogId: submissionCatalogId, reportId: submission.id, galleryMode: true, showCourseHeader: !!vm.showCourseHeaderInLink,
        });
      }

      const setCenteredView = _.debounce(() => {
        $(vm.sliderComponent).slick('setPosition');
      }, 100);

      angular.element('.lecture-page-main-content').on('resize', setCenteredView);
      $scope.$on('$destroy', () => {
        angular.element('.lecture-page-main-content').off('resize', setCenteredView);
      });

      $scope.$watch('vm.carouselSubmissions', () => {
        vm.slickConfig.arrows = vm.carouselSubmissions?.length > DEFAULT_SLIDES_PER_SCROLL;
      });
    },
    controllerAs: 'vm',
    bindToController: true,
    transclude: true,
    templateUrl: 'shared/templates/nv-submissions-carousel.html',
  };
}

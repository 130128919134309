/* @ngInject */
export default function LiveSessionLectureComponentModel(
  $translate,
  CurrentUserManager,
  LectureComponentBaseModel,
  LiveSessionModel,
  TimezonesService,
  _,
  moment,
  config,
) {
  const DEFAULT_DURATION = 60;
  const MS_LOBBY_BY_PASS_TYPES = {
    ORGANIZER: 'organizer',
    ORGANIZATION: 'organization',
    ORGANIZATION_AND_FEDERATED: 'organizationAndFederated',
    EVERYONE: 'everyone',
  };

  class LiveSessionLectureComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false, extras = {}) {
      const defaults = {
        type: 'LiveSessionLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/live-session-edit-modal.html',
        editFormController: 'LiveSessionEditFormModalCtrl',
        formName: 'editLiveSessionForm',
        liveSession: {
          authenticationEmail: null,
          sessionType: null,
          mainTitle: 'Live Video Events',
          title: '',
          description: '',
          startTime: null,
          duration: DEFAULT_DURATION,
          joinUrl: '',
          recording: {},
          totalPoints: 0,
          isTodo: false,

          // zoom only
          alternativeHosts: '',
          recordMeetingAutomatically: false,
          automaticallyUploadRecording: true,
          participantVideo: false,
          muteUponEntry: false,
          enableEmailConfirmation: false,
          trackAttendance: true,

          // MS Teams only
          lobbyBypassSettings: MS_LOBBY_BY_PASS_TYPES.ORGANIZER,
          creatorUserCourseId: CurrentUserManager.currentUserCourse?.courseId,
        },
      };
      super({ ...defaults, ...attributes });

      this.__preprocess();

      if (useDefaults) {
        this.createDraft();
      }
      this.setRecording = this.setRecording.bind(this);
      this.getStatus = this.getStatus.bind(this);
      this.getLiveSessionType = this.getLiveSessionType.bind(this);
    }

    __preprocess() {
      if (!this.liveSession) {
        this.liveSession = {
          errorCode: {
            code: 404,
          },
        };
      }
      this.liveSession = new LiveSessionModel(Object.assign(this.liveSession, { catalogId: this.catalogId }));
    }

    setRecording(rec) {
      this.recording = rec; // set the recording for the file-upload-progress
    }

    getOffsetTime() {
      return this.liveSession.attendeeInfo?.startPlaybackFrom || 0;
    }

    // eslint-disable-next-line class-methods-use-this
    createDraft() {
      if (!this.liveSession.title) {
        this.liveSession.title = this.liveSession.mainTitle ?? this.liveSession.sessions?.[0]?.title ?? '';
      }
      if (!this.liveSession.description) {
        this.liveSession.description = this.liveSession.mainDescription ?? this.liveSession.sessions?.[0]?.description ?? '';
      }

      this.liveSessionDraft = { ...this.liveSession };

      // we only care to show the current time zone, this may not reflect the timezone saved in the backend
      // the backend sends the timezone as a string under the time_zone attribute
      const timeZoneString = TimezonesService.correctSpelling(CurrentUserManager.user.timeZone || moment.tz.guess());
      this.liveSessionDraft.timeZone = timeZoneString;
      const timeZoneKey = TimezonesService.timezones.find((t) => t.longName === timeZoneString)?.translationKey;
      this.liveSessionDraft.currentTimeZone = timeZoneKey ? `TIMEZONE.${timeZoneKey}` : timeZoneString;

      this.liveSessionDraft.isDisabled = this.beginningSoon();

      if (!this.liveSession.title) {
        this.liveSessionDraft.title = this.liveSession.mainTitle;
      }
    }

    getPayload() {
      const standardKeys = ['id', 'sessionType', 'title', 'description', 'mainTitle', 'mainDescription', 'startTime', 'duration', 'isTodo', 'joinUrl'];
      const zoomOnlyKeys = ['id', 'alternativeHosts', 'recordMeetingAutomatically', 'automaticallyUploadRecording', 'participantVideo', 'muteUponEntry', 'enableEmailConfirmation', 'trackAttendance'];
      const msTeamsOnlyKeys = ['id', 'lobbyBypassSettings', 'creatorUserCourseId', 'recordMeetingAutomatically', 'automaticallyUploadRecording'];
      let keysToUse;

      switch (this.liveSession.sessionType) {
        case (this.constructor.SESSION_TYPE_ZOOM): {
          keysToUse = [...standardKeys, ...zoomOnlyKeys];
          break;
        }
        case (this.constructor.SESSION_TYPE_EXTERNAL): {
          keysToUse = [...standardKeys];
          break;
        }
        case (this.constructor.SESSION_TYPE_MS_TEAMS): {
          /* Adding new keys with the existing values for supporting MS event
          creation API's key changes */
          this.liveSession = _.extend(this.liveSession, { subject: this.liveSession.title, startDateTime: this.liveSession.startTime });
          keysToUse = [...standardKeys, ...msTeamsOnlyKeys, 'subject', 'startDateTime'];
          break;
        }
        default: {
          break;
        }
      }

      const timeZone = this.liveSession.currentTimeZone ? this.liveSession.currentTimeZone.longName : this.liveSession.timeZone;
      if (this.liveSession.automaticallyUploadRecording && !this.liveSession.recordMeetingAutomatically) {
        this.liveSession.automaticallyUploadRecording = false;
      }

      return {
        liveSession: this.liveSession,
      };
    }

    saveDraft() {
      Object.assign(this.liveSession, this.liveSessionDraft);
    }

    isCompleted() {
      return this.liveSession.isCompleted();
    }

    isMissed() {
      return this.liveSession.isMissed();
    }

    isTodo() {
      return this.liveSession.isTodo;
    }

    toggleToDo() {
      this.liveSession.isTodo = !this.liveSession.isTodo;
      this.liveSession.sessionType = this.getLiveSessionType();
      this.save();
    }

    getStatus() {
      if (this.liveSession.beginningSoon() && !this.liveSession.hasEnded()) {
        return 'in_progress';
      } if (this.liveSession.isCompleted()) {
        return 'completed';
      } if (this.liveSession.hasEnded()) {
        return 'missed';
      }

      return 'not_started';
    }

    getLiveSessionType() {
      return this.liveSession?.sessionType || this.liveSessionDraft?.sessionType;
    }

    beginningSoon() {
      return moment(this.liveSession.sessions?.[0]?.startTime) <= moment().add(10, 'minutes');
    }

    // eslint-disable-next-line class-methods-use-this
    durationText(minutes = 0) {
      if (minutes < 60) {
        return $translate.instant('LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.MINUTES', { minutes });
      }

      return $translate.instant('LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.HOURS', { hours: minutes / 60 });
    }
  }

  LiveSessionLectureComponent.iconClass = 'icon-zoom';
  LiveSessionLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.DESCRIPTION';
  LiveSessionLectureComponent.showModalBeforeCreate = true;

  LiveSessionLectureComponent.SESSION_TYPE_ZOOM = 'ZoomLiveSession';
  LiveSessionLectureComponent.SESSION_TYPE_MS_TEAMS = 'TeamsLiveSession';
  LiveSessionLectureComponent.SESSION_TYPE_EXTERNAL = 'LiveSession';
  // this is the 30 minute increments going from 30 minutes to 8hrs
  LiveSessionLectureComponent.durations = _.range(24).map((val) => (val + 1) * 30);
  LiveSessionLectureComponent.lobbyByPassTypes = MS_LOBBY_BY_PASS_TYPES;
  LiveSessionLectureComponent.pendoTagName = config.pendo.lectureEdit.liveEvent;

  return LiveSessionLectureComponent;
}

/**
 * Functionality not implemented:
 * mentions
 * focus from outside
 * most callbacks
 *
 * List of potential refactors:
 * move css not to be global
 * use useReducer for all the useState instances
 */

import _ from 'underscore';
import uuid from 'react-uuid';
import React, {
  Fragment,
  useState,
  useRef,
  forwardRef,
  MutableRefObject,
  useEffect,
  SyntheticEvent,
  useImperativeHandle,
  useCallback,
  useContext,
} from 'react';
import { AngularServicesContext } from 'react-app';

import getTributeOptions, { interactWithOeMentionSpanBefore, interactWithOeMentionSpanAfter } from 'froala/helpers/tribute-options';

// Froala code view relies on CodeMirror
import 'codemirror';

import 'froala-editor/js/froala_editor.min';
// Froala: Plugins
import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/url.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/code_beautifier.min';
// Froala: Languages
import 'froala-editor/js/languages/es';
import 'froala-editor/js/languages/fr';
import 'froala-editor/js/languages/pt_pt';
import 'froala-editor/js/languages/pt_br';
import 'froala-editor/js/languages/zh_cn';
import 'froala-editor/js/languages/zh_tw';
import 'froala-editor/js/languages/ja';
import 'froala-editor/js/languages/ko';
import 'froala-editor/js/languages/ru';
import 'froala-editor/js/languages/de';
import 'froala-editor/js/languages/ar';
import 'froala-editor/js/languages/he';
import 'froala-editor/js/languages/th';
import 'froala-editor/js/languages/it';
import 'froala-editor/js/languages/nl';
import 'froala-editor/js/languages/ro';
import 'froala-editor/js/languages/sv';
import 'froala-editor/js/languages/tr';
import 'froala-editor/js/languages/id';

// import './froala/lib/mentio'; // Mentio has been modified for support NovoEd uses - pulling in local version
import '../plugins/plugin_defaults';
// Froala: NovoEd Custom Plugins
import '../plugins/paragraph_style';
import '../plugins/link';
import '../plugins/image';
import '../plugins/colors';
import '../plugins/align';
import '../plugins/typed_lists';

// currently, we will rely on the global froala css, including this css conflicts with existing froala css
// it will take up extra space, as well as the need to special case to block RTL post processing as well
// import 'styles/modules/froala/froala_editor.scss';
// import 'froala-editor/css/plugins/colors.min.css';
// import 'froala-editor/css/plugins/draggable.min.css';
// import 'froala-editor/css/plugins/image.min.css';
// import 'froala-editor/css/plugins/code_view.min.css';

import FroalaEditor from 'react-froala-wysiwyg';
import { useFormContext } from 'react-hook-form';

import t from 'react-translate';

import { Button } from 'react-bootstrap';
import { NvModal, ModalType, ModalTheme } from 'shared/components/nv-modal';
import { NvPopover } from 'shared/components/nv-popover';
import { NvTooltip } from 'shared/components/nv-tooltip';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { MyAccount } from 'redux/schemas/models/my-account';
import { getCurrentUser } from 'redux/selectors/users';

import {
  // Interfaces
  RteEmbedding, FroalaViewMode, UploadType, NvFroalaProps, RteTag,
  // Language Related
  LANGUAGE_MAPPING, DEFAULT_LANGUAGE,
  // Embedding
  defaultEmbeddingWidth, defaultEmbeddingHeight, PARAGRAPH_STYLES, DEFAULT_PARAGRAPH_STYLE,
  modalImgOnlyHeight, modalAllUploadsHeight,
} from 'froala/helpers/nv-froala-constants';
import {
  // Embedding
  createEmbeddingFromS3FileId,
  insertEmbeddingHtml,
  // Language Related
  addMissingStrings,
  // Configuration
  getFroalaBaseConfig,
  updateParagraphStyle,
  validateFiles,
  placeCaretAtEnd,
  getTopElement,

  toggleTransformFix,
} from 'froala/helpers/nv-froala-functions';
import { insertRteTags } from 'froala/helpers/nv-froala-tag-functions';
import getStyles from 'froala/helpers/nv-froala-styles';
import { sanitize, cleanupNovoEdCode, SanitizationLevel } from 'froala/helpers/sanitizer';
import useComponentId from 'shared/hooks/use-component-id';
import useUploadFile from 'shared/hooks/use-upload-file';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';

import useFroalaFte from 'froala/hooks/use-froala-fte';
import isMostlyRTLText from 'froala/helpers/isMostlyRTLText';
import { getScrollParent } from 'shared/utils';
import Tribute from '../helpers/tribute';
import { FroalaUploadModal } from './froala-upload-modal';
import { FroalaTagsModal } from './froala-tags-modal';

import { config } from '../../../config/config.json';

/**
 * NovoEd-customized wrapper around React Froala
 */
export const NvFroala = forwardRef(({
  /* Common Options */
  preset = FroalaViewMode.AIR,
  withCodeView = false,
  sanitizationLevel = preset === FroalaViewMode.AIR ? SanitizationLevel.BASIC : SanitizationLevel.NORMAL,
  withForm = false,
  name,
  value,
  onChange = () => {},
  onFocus,
  onBlur,
  blockBackspaceOnEmpty = false,
  pastePlain = false,
  mentionableUsers,

  // see https://froala.com/wysiwyg-editor/v2-0/docs/framework-plugins/react/#config
  immediateReactModelUpdate = false,
  relatedActivities,
  withInsertTag = false,
  scrollableContainer: propsScrollableContainer,

  /* Uncommon Options */
  toolbarButtons,
  placeholder,
  minHeight = withInsertTag && withCodeView ? 200 : 150,
  uploadType = UploadType.ALL,
  isDisabled = false,
  ariaLabel = '',
  dataQa = '',
  dataQaId = '',

  className = '',
  editorClass = '',
  keepFormatOnDelete = false,
  extraTags,
  fileExtensions,
  allowToolbar = false,
}: NvFroalaProps, forwardedRef: MutableRefObject<any>) => {
  const currentUser = useSelector<RootState, MyAccount>(getCurrentUser);
  const onBlurCallbackRef = useRef<typeof onBlur>(null);
  const onFocusCallbackRef = useRef<typeof onFocus>(null);
  onFocusCallbackRef.current = onFocus;

  const { register, unregister, setValue, watch } = useFormContext() ?? {};
  const formValue = withForm ? watch(name) : value;

  const [isCodeViewOn, setIsCodeViewOn] = useState(false);
  const [isInFocus, setIsInFocus] = useState(false);
  const [isToolbarVisible, setIsToolbarVisible] = useState(false);
  const [hasBeenFocused, setHasBeenFocused] = useState(false);
  const [editorError, setEditorError] = useState('');
  // purpose: keep track if the user has touched the input, if false, don't update model
  // froala does its own manipulating of format which causes the field to become dirty
  const [hasTouchedSinceInit, setHasTouchedSinceInit] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const lastPastedContentRef = useRef<string>();
  const [focusScheduled, setFocusScheduled] = React.useState(false);

  const containerId = useComponentId(); // Froala direct parent id for attaching inline toolbar
  const toolbarRef = useRef<HTMLDivElement>(); // NovoEd toolbar on the left of the RTE
  const froalaInstanceRef = useRef<any>(); // container to hold a reference to the Froala Editor
  const containerRef = useRef<any>();
  const tributeRef = useRef<any>();
  const [scrollParentSet, setScrollParentSet] = React.useState(false);

  const { current: internalId } = React.useRef(uuid());
  const scrollParentClass = `froala-scroll-parent-${internalId}`;

  React.useEffect(() => {
    const scrollContainer = getScrollParent(containerRef.current);
    scrollContainer.classList.add(scrollParentClass);
    setScrollParentSet(true);
  }, [scrollParentClass]);

  const defaultScrollParentClass = React.useMemo(() => {
    if (scrollParentSet) {
      return scrollParentClass;
    }

    return undefined;
  }, [scrollParentSet, scrollParentClass]);

  const scrollableContainer = propsScrollableContainer || (defaultScrollParentClass ? `.${defaultScrollParentClass}` : null);
  const editorReady = !!scrollableContainer;

  const containerRefCallback = useCallback(node => {
    if (node && mentionableUsers?.length && editorReady) {
      const options = getTributeOptions(mentionableUsers, node);
      tributeRef.current = new Tribute(options);

      tributeRef.current.attach(froalaInstanceRef.current.el);
    }
    containerRef.current = node;
  }, [containerRef, mentionableUsers, editorReady]);

  const [showFroalaFte, renderFte] = useFroalaFte();
  const { uploadFiles } = useUploadFile();

  useEffect(() => {
    if (editorReady) {
      if (isDisabled) {
        froalaInstanceRef.current.edit.off();
      } else {
        froalaInstanceRef.current.edit.on();
      }
    }
  }, [isDisabled, editorReady]);

  /* Form: Registration */
  useEffect(() => {
    if (withForm) {
      register(name);
    }

    return () => {
      if (withForm) {
        unregister(name);
      }
    };
  }, [withForm, name, register, unregister]);


  // We'll keep this ref up-to-date with the onBlur callback prop to guarantee
  // that whenever we use it we are not calling a stale prop. This is required
  // since the froala unBlur event is not registered e.g. with an useEffect
  useEffect(() => {
    onBlurCallbackRef.current = onBlur;
  }, [onBlur]);

  const filePatterns: string[] = uploadType === UploadType.IMAGE_ONLY ? config.files.rte.images.extensions : _.flatten(_.map(config.files.rte, (type) => type.extensions));

  /* Update Froala Language */
  const currentLang = LANGUAGE_MAPPING[currentUser?.platformLanguage] || DEFAULT_LANGUAGE;
  addMissingStrings(currentLang);

  /* Config: Basics */
  let froalaConfig = getFroalaBaseConfig(
    preset,
    sanitizationLevel,
    minHeight,
    currentLang,
    placeholder,
    scrollableContainer,
    uploadFiles,
    immediateReactModelUpdate,
    editorClass,
    toolbarButtons,
    keepFormatOnDelete,
    allowToolbar,
    pastePlain,
  );

  /* Config: Callbacks */
  function blurCallback(e: JQuery.BlurEvent): void {
    /**
     * in code view, froala does not update until exiting code view
     * the following code triggers froala to tell us the content has changed
     * https://github.com/froala/wysiwyg-editor/issues/1465
     * the recommended code was something like vm.froalaEditor('events.trigger', 'form.submit');
     * but this cleans up the html in way we don't like
     */
    /**
     * Only trigger when code view is active. Calling on normal modal will
     * cause the focus to stay on the rich-text editor
     */
    if (froalaInstanceRef.current?.codeView.isActive()) {
      froalaInstanceRef.current.events.trigger('contentChanged', [], true);
    }

    // Obtain the current editor contents to pass back with the blur callback
    let editorContents = null;
    if (froalaInstanceRef.current?.codeView.isActive()) {
      editorContents = froalaInstanceRef.current.codeView.get();
    } else {
      editorContents = froalaInstanceRef.current.html.get();
    }

    onBlurCallbackRef.current?.(e, editorContents);

    setIsInFocus(false);
    setEditorError('');

    // need timeout for blur event to complete so we can get the right position
    setTimeout(() => {
      if (preset !== FroalaViewMode.INLINE && !checkFocusWithin()) {
        setIsToolbarVisible(false);
      }
    });
  }

  /**
   * Updates the side menu position to follow the cursor
   */
  function updateSideMenuPosition(): void {
    // need timeout for event to complete
    setTimeout(() => {
      const barHeight = toolbarRef.current?.clientHeight;

      if (froalaInstanceRef.current.selection.inEditor() && barHeight) {
        const cursorBounds = froalaInstanceRef.current.position.getBoundingRect();
        const containerBounds = froalaInstanceRef.current.el.getBoundingClientRect();

        if (cursorBounds.top + barHeight > containerBounds.bottom) {
          // pin bottom
          toolbarRef.current.style.top = `${containerBounds.height - barHeight}px`;
        } else {
          // follow cursor
          toolbarRef.current.style.top = `${cursorBounds.top - containerBounds.top}px`;
        }
      }
    });
  }

  /**
   * Check the RTE tag is malformed by styling
   */
  function checkMalformedRTEtag(): void {
    if (!withInsertTag) {
      return;
    }

    const currentHTML: string = froalaInstanceRef.current.html.get();

    // The following regular expression checks whether there is html tag inside
    // square brackets in the content. The dynamic tag is considered by checking
    // the wrapping square brackets and the html tags by <, > characters
    if (/\[\w*?<[^>]+?>.*?\w*\]/.test(currentHTML)) {
      setEditorError(t.FROALA.INSERT_TAGS.MALFORMED_WARNING());
    } else {
      setEditorError('');
    }
  }

  /**
   * Handles file drag/drops and uploads them to s3
   */
  async function onFileDrop(dropEvent: JQuery.DragEvent) {
    dropEvent.preventDefault();
    dropEvent.stopPropagation();

    const dt = dropEvent.originalEvent.dataTransfer;

    if (uploadType === UploadType.NONE || preset === FroalaViewMode.AIR) {
      return;
    }

    if (dt?.files?.length) {
      const files = Array.from(dt.files);
      const firstError = validateFiles(files, filePatterns);

      if (firstError) {
        setEditorError(firstError);
      } else {
        files.forEach(async (file) => {
          const [novoEdFile] = await uploadFiles([file], S3NameSpaces.ATTACHMENTS);
          const rteEmbedding = await createEmbeddingFromS3FileId(novoEdFile, defaultEmbeddingWidth, defaultEmbeddingHeight);
          insertEmbeddingHtml(rteEmbedding, froalaInstanceRef, true);
        });
      }
    }
  }

  function initListeners(): void {
    /* Events - Normal Interaction */
    froalaInstanceRef.current.events.on('focus', (_e: JQuery.FocusEvent) => {
      setHasBeenFocused(true);
      setHasTouchedSinceInit(true);
      setIsInFocus(true);
      setIsToolbarVisible(true);
      updateSideMenuPosition();
      onFocusCallbackRef.current?.(_e);
      if (preset !== FroalaViewMode.AIR) {
        showFroalaFte();
      }
    });

    froalaInstanceRef.current.events.on('blur', (e: JQuery.BlurEvent) => {
      blurCallback(e);
    });
    froalaInstanceRef.current.events.on('click', (_e: JQuery.ClickEvent) => {
      updateSideMenuPosition();
      checkMalformedRTEtag();
    });
    froalaInstanceRef.current.events.on('keyup', (_e: JQuery.KeyUpEvent) => {
      updateSideMenuPosition();
      checkMalformedRTEtag();
    });

    froalaInstanceRef.current.events.on('paste.before', (_e: JQuery.FocusEvent) => {
      // Removing transform css property from the div.main-panel-scrollable to fix the scroll to top issue on paste.
      // More info regarding this issue can be found in the jsdoc of the method
      toggleTransformFix(false);
    });

    /* Events - Priority; need to be added to front of queue */
    froalaInstanceRef.current.events.on('paste.afterCleanup', (clipboardHTML) => {
      const cleanHtml = cleanupNovoEdCode(clipboardHTML);
      lastPastedContentRef.current = sanitize(cleanHtml, sanitizationLevel);

      return lastPastedContentRef.current;
    }, true);
    froalaInstanceRef.current.events.on('paste.after', () => {
      // this block of code used to set the direction of the text after it is pasted into Froala
      // it is only triggered if the pasted text does not contain any block level elements
      // there is a corresponding call for block level elements in sanitizer.ts
      const topElement = getTopElement(froalaInstanceRef.current);

      if (topElement?.parentElement // make sure we didn't hit the top html element
        && topElement.innerText.replace(/\u200B/g, '').trim() === $(`<div>${lastPastedContentRef.current}</div>`).text().replace(/\u200B/g, '').trim()
      ) {
        topElement.setAttribute('dir', isMostlyRTLText(topElement.innerText) ? 'rtl' : 'ltr');
      }

      // Enabling transform css property to the div.main-panel-scrollable which is removed on paste.before.
      // More info regarding this can be found in the jsdoc of the method
      toggleTransformFix(true);
    });
    froalaInstanceRef.current.events.on('drop', onFileDrop, true);

    /* Events - Froala Specific */
    froalaInstanceRef.current.events.on('contentChanged', () => {
      updateSideMenuPosition();
      checkMalformedRTEtag();
    });
    // if code view is active, pass the code view data instead because code view content is not synced until code view is closed
    froalaInstanceRef.current.events.on('html.get', () => {
      if (froalaInstanceRef.current?.codeView.isActive()) {
        return froalaInstanceRef.current.codeView.get();
      }

      return null;
    });

    // if aria label exists add this as an attribute for accessibility
    if (ariaLabel) {
      froalaInstanceRef.current.el.setAttribute('aria-label', ariaLabel);
    }

    // Adding Data-Qa attributes
    if (dataQa) {
      froalaInstanceRef.current.el.setAttribute('data-qa', dataQa);

      if (dataQaId) {
        froalaInstanceRef.current.el.setAttribute('data-qa-id', dataQaId);
      }
    }
  }

  /* Events - Setup on Initialization */
  froalaConfig = _.extend(froalaConfig, {
    events: {
      'froalaEditor.initialized': (_e, editor) => {
        froalaInstanceRef.current = editor;

        // by default, Froala expects to have a toolbar so there is no way to initialize it hidden
        // going with the second best option of hiding on init
        if (preset === FroalaViewMode.AIR) {
          editor.toolbar.hide();
        }

        initListeners();

        editor.events.on('keydown', (e) => {
          if (blockBackspaceOnEmpty && editor.html.get() === '' && (e.which === 8 || e.which === 46)) {
            e.preventDefault();
            return false;
          }
          if (e.which === 13 && tributeRef.current?.isActive) {
            return false;
          }
        }, true);
      },
      'froalaEditor.keydown': interactWithOeMentionSpanBefore,
      'froalaEditor.keyup': interactWithOeMentionSpanAfter,
      'froalaEditor.input': ((_e, editor, keyupEvent) => {
        // 'keyup` doesn't trigger for froala for all keys such as typing Chinese
        // 'keydown' modifies the content so we can't tell what the input is if it's not English
        const topElement = getTopElement(froalaInstanceRef.current);

        if (topElement?.innerText) {
          const content = topElement.innerText.replace(/\u200B/g, '').trim();
          if (content.length === 1) {
            topElement.setAttribute('dir', isMostlyRTLText(topElement.innerText) ? 'rtl' : 'ltr');
          }
        }
      }),
      // To display the toolbar on top of the bottom bar of lecture page.
      'froalaEditor.toolbar.show': () => {
        toggleTransformFix(false);
      },
      'froalaEditor.toolbar.hide': () => {
        toggleTransformFix(true);
      },
    },
  });

  React.useEffect(() => {
    if (froalaInstanceRef.current) {
      froalaInstanceRef.current.opts.placeholderText = placeholder;
      froalaInstanceRef.current.placeholder.refresh();
    }
  }, [placeholder]);

  /* User Actions on Toolbar */
  function formatUL(e: SyntheticEvent): void {
    e.preventDefault();

    // get the direction of the block to set on the new tags
    const previousDir = getTopElement(froalaInstanceRef.current)?.getAttribute('dir');

    froalaInstanceRef.current.lists.format('UL');
    froalaInstanceRef.current.placeholder.refresh();

    if (previousDir) {
      getTopElement(froalaInstanceRef.current)?.setAttribute('dir', previousDir);
    }
  }

  function formatOL(e: SyntheticEvent): void {
    e.preventDefault();

    // get the direction of the block to set on the new tags
    const previousDir = getTopElement(froalaInstanceRef.current)?.getAttribute('dir');

    froalaInstanceRef.current.lists.format('OL');
    froalaInstanceRef.current.placeholder.refresh();

    if (previousDir) {
      getTopElement(froalaInstanceRef.current)?.setAttribute('dir', previousDir);
    }
  }

  function openUploadModal(e: SyntheticEvent): void {
    e.preventDefault();
    froalaInstanceRef.current.selection.save(); // save the cursor location so we can get back to it after modal closes
    setIsUploadModalOpen(true);
  }

  // helper function for file insertion on closing of the upload modal
  function onUploadModalSubmit(rteEmbeddings: RteEmbedding[] = [], isNovoEdEmbedding: boolean = false): void {
    rteEmbeddings.forEach((rteEmbedding) => {
      insertEmbeddingHtml(rteEmbedding, froalaInstanceRef, isNovoEdEmbedding);
    });
    setIsUploadModalOpen(false);
  }

  function closeUploadModal(): void {
    froalaInstanceRef.current.selection.restore(); // Restore Froala Editor To Trigger onChange
    setIsUploadModalOpen(false);
  }

  function toggleCodeView(e: SyntheticEvent): void {
    e.preventDefault();
    e.stopPropagation();
    setIsCodeViewOn(currentIsCodeView => !currentIsCodeView);
    froalaInstanceRef.current.events.disableBlur(); // not needed on AngularJS froala, now sure why this is needed here
    froalaInstanceRef.current.codeView.toggle();
    froalaInstanceRef.current.events.enableBlur();
    toolbarRef.current.style.top = '0';
  }

  function openTagModal(e: React.MouseEvent): void {
    e.preventDefault();
    froalaInstanceRef.current.selection.save(); // save the cursor location so we can get back to it after modal closes
    setIsTagModalOpen(true);
  }

  // helper function for tag insertion on closing of the tag modal
  function onTagModalSubmit(rteTags: { [key: string]: RteTag } = {}): void {
    _.values(rteTags).forEach((tag) => {
      if (tag.selected) {
        insertRteTags(tag, froalaInstanceRef);
      }
    });
    setIsTagModalOpen(false);
  }

  // Function to trigger on closing tag modal. Restore selection to enable trigger on change
  function closeTagModal(): void {
    froalaInstanceRef.current.selection.restore();
    setIsTagModalOpen(false);
  }

  /**
   * handles updates from Froala
   * @param froalaContent html of current Froala editor
   */
  function onModelChange(froalaContent: string): void {
    if (preset !== FroalaViewMode.AIR) {
      // cleans up existing html(this will trigger another onModelChange)
      updateParagraphStyle(froalaInstanceRef);
    }

    // froala by default cleans up html the second it is initialized, we don't want to bother updating until after the user has touched the editor since this will set the input as dirty
    if (hasTouchedSinceInit) {
      if (withForm) {
        setValue(name, froalaContent, { shouldValidate: true, shouldDirty: true });
      }

      onChange(froalaContent);
    }
  }

  function preventDefault(e: SyntheticEvent) {
    e.preventDefault();
  }


  const triggerFocus = () => {
    if (froalaInstanceRef.current?.core.isEmpty()) {
      froalaInstanceRef.current?.events.focus();
    } else {
      placeCaretAtEnd(froalaInstanceRef.current.el);
    }
  };

  useEffect(() => {
    if (focusScheduled) {
      setFocusScheduled(false);

      triggerFocus();
    }
  }, [focusScheduled]);

  /* Methods to Expose Externally */
  useImperativeHandle(forwardedRef, () => ({
    focus: () => {
      if (editorReady) {
        triggerFocus();
      } else {
        setFocusScheduled(true);
      }
    },
  }));

  /**
   * Return true if foucs is within the parent rich text editor container
   * including toolbar icons
   */
  function checkFocusWithin() {
    let elements: any = $(`#parent_${containerId}`).find('a[href], area[href], input:not([disabled]), '
    + 'button:not([disabled]),select:not([disabled]), textarea:not([disabled]), '
    + 'iframe, object, embed, *[tabindex], *[contenteditable=true]');
    elements = elements ? _.filter(elements, (element) => $(element).is(':visible')) : [];
    elements = elements ? _.filter(elements, (element) => $(element).is(':focus')) : [];
    if (elements.length) {
      return true;
    }
    return false;
  }

  /**
   * This is created to hide toolbar when focus moves outside of the
   * rich text editor and toolbar buttons
   */
  const onComponentBlur = () => {
    // need timeout for blur event to complete
    setTimeout(() => {
      if (preset !== FroalaViewMode.INLINE && !isInFocus && !checkFocusWithin()) {
        setIsToolbarVisible(false);
      }
    });
  };

  return (
    <div
      css={getStyles(preset === FroalaViewMode.INLINE, hasBeenFocused, isInFocus)}
      className={className}
      id={`parent_${containerId}`}
      onBlur={onComponentBlur}
    >
      <NvPopover
        content={(
          <Fragment>
            <div className='text-danger text-center'>{t.FORM.WARNING()}</div>
            <div>{editorError}</div>
          </Fragment>
        )}
        show={!!editorError}
        placement='top'
        offset={0}
        preventOverflow
      >
        <div className='nv-froala-origami'>
          {(preset !== FroalaViewMode.AIR && (isCodeViewOn || isToolbarVisible)) && (
          <div className='froala-novoed-menu' role='toolbar' ref={toolbarRef} onMouseDown={preventDefault}>
            {uploadType !== UploadType.NONE && (
              <NvTooltip text={uploadType === UploadType.IMAGE_ONLY ? t.FROALA.ICON_TOOLTIPS.UPLOAD_IMAGE() : t.FROALA.ICON_TOOLTIPS.UPLOAD()} placement='left' preventOverflow>
                <Button
                  variant='link'
                  disabled={isCodeViewOn}
                  onClick={openUploadModal}
                  aria-label={uploadType === UploadType.IMAGE_ONLY ? t.FROALA.ICON_TOOLTIPS.UPLOAD_IMAGE() : t.FROALA.ICON_TOOLTIPS.UPLOAD()}
                >
                  <i className='icon icon-smallest icon-upload' />
                </Button>
              </NvTooltip>
            )}
            {withCodeView && (
              <NvTooltip text={isCodeViewOn ? t.FROALA.ICON_TOOLTIPS.EXIT_CODE() : t.FROALA.ICON_TOOLTIPS.ENTER_CODE()} placement='left' preventOverflow>
                <Button
                  variant='link'
                  onClick={toggleCodeView}
                  aria-label={isCodeViewOn ? t.FROALA.ICON_TOOLTIPS.EXIT_CODE() : t.FROALA.ICON_TOOLTIPS.ENTER_CODE()}
                >
                  <i className='icon icon-smallest icon-html' />
                </Button>
              </NvTooltip>
            )}
            {withInsertTag && (
            <NvTooltip text={t.FROALA.ICON_TOOLTIPS.INSERTS()} placement='left' preventOverflow>
              <Button
                variant='link'
                disabled={isCodeViewOn}
                onClick={openTagModal}
                aria-label={t.FROALA.ICON_TOOLTIPS.INSERTS()}
              >
                <i className='icon icon-smallest icon icon-insert' />
              </Button>
            </NvTooltip>
            )}
          </div>
          )}

          <div className='froala-container' id={containerId} ref={containerRefCallback}>
            {!!editorReady && (
              <FroalaEditor tag='textarea' model={formValue} config={froalaConfig} onModelChange={onModelChange} />
            )}
            {/* Froala dumps new content at the top of it's parent, so the container is needed to contain it */}
          </div>

          {/* Code View - Supported Tags */}
          {isCodeViewOn && (
          <NvPopover
            className='support-tags-popover'
            content={(
              <div>
                {t.FROALA.HTML.SUPPORTED_DESCRIPTION()}
              </div>
            )}
            showOnHover
            preventOverflow
          >
            <div className='support-tags-row'>
              {t.FROALA.HTML.SUPPORTED()}
            </div>
          </NvPopover>
          )}
        </div>
      </NvPopover>

      {/* Froala Upload Modal */}
      {uploadType !== UploadType.NONE && (
        <NvModal
          type={ModalType.FIXED}
          theme={ModalTheme.LIGHT}
          header={uploadType === UploadType.IMAGE_ONLY ? t.FROALA.UPLOAD_MEDIA.IMAGE_HEADER() : t.FROALA.UPLOAD_MEDIA.HEADER()}
          body={<FroalaUploadModal uploadType={uploadType} closeModal={onUploadModalSubmit} fileExtensions={fileExtensions} />}
          show={isUploadModalOpen}
          onClose={closeUploadModal}
          doubleModal
          height={uploadType === UploadType.IMAGE_ONLY ? modalImgOnlyHeight : modalAllUploadsHeight}
        />
      )}
      {/* Froala Tag Modal Modal */}
      {withInsertTag && (
      <NvModal
        type={ModalType.FIXED}
        theme={ModalTheme.LIGHT}
        header={t.FROALA.INSERT_TAGS.HEADER()}
        body={(
          <FroalaTagsModal
            closeModal={onTagModalSubmit}
            relatedActivities={relatedActivities}
            extraTags={extraTags}
          />
        )}
        show={isTagModalOpen}
        onClose={closeTagModal}
        width={620}
        doubleModal
      />
      )}
      {/* Froala First Time Experience Modal */}
      {renderFte()}
    </div>
  );
});

export default NvFroala;

/* eslint-disable no-useless-escape */
const validationConstants = {
  URL_REGEX: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
  // https://emailregex.com/
  EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  CATALOG_ID_REGEX: /^[a-zA-Z0-9_-]+$/,
  KEY_PHRASE_REGEX: /^([a-zA-Z ' ’]*)$/,
  NUMBER_ANSWER_REGEX: /^(?:-)?(?!0[^.])[\d]*(?:\.[\d]*)?$/,
};

export default validationConstants;

import { useEnterLecturePageMode, useExitLecturePageMode, useLecturePageFromParams, useLecturePageLink, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import React, { useContext, useCallback, useMemo, useState, useEffect } from 'react';
import t from 'react-translate';

import { AngularServicesContext } from 'react-app';

// redux
import { useSelector } from 'react-redux';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { unsetCurrentCourseCourseLongTeamSet, unsetCurrentCourseGroupTeamSet } from 'redux/actions/lecture-components';
import { duplicateLecturePage, deleteLecturePage, createLecturePage } from 'redux/actions/lecture-pages';
import { getFullCourseTimelineWithProgress } from 'redux/actions/timeline';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import { LecturePage, SyncingStatus } from 'redux/schemas/models/lecture-page';
import { getCourseAliases, getCurrentCourse, getCurrentCourseCourseLongTeamSet, getCurrentCourseGroupTeamSet, getFlatCourseAliases } from 'redux/selectors/course';
import { CannotBeCopiedComponentsMeta, getCannotBeCopiedComponentsMeta, getNearestLecture } from 'redux/selectors/lecture-page';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { createLecturePage as createCollectionLecturePage, getCanLinkCollectionLesson, getCanConvertToCollectionLesson, canConvertToCollectionLecture } from 'redux/actions/collections';
import { useCourseOutline } from 'redux/selectors/course-outline/use-course-outline';

// components
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle, NvDropdownAlign } from 'shared/components/inputs/nv-dropdown';
import { isEmpty } from 'underscore';
import { hideAddUI } from 'lecture_pages/templates/components/nv-add-component';
import LinkCollectionLessonsModal from 'courses/components/course-lesson-link/link-lessons-modal';
import AddToCollectionModal from 'courses/components/course-lesson-to-collection/add-to-collection-modal';
import { LecturePageMode } from '..';

export const LectureContentAdminDropdown = () => {
  const dispatch = useAppDispatch();
  const params = useLecturePageParams();
  const lectureLink = useLecturePageLink();
  const enterMode = useEnterLecturePageMode();
  const exitMode = useExitLecturePageMode();

  const [showAddFromLessonModal, setShowAddFromLessonModal] = useState(false);
  const [showAddToLessonModal, setShowAddToLessonModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { $state, CurrentPermissionsManager, CurrentCourseManager } = useContext(AngularServicesContext);

  const lecturePage = useLecturePageFromParams();
  const currentUserId = useSelector(state => state.app.currentUserId);
  const currentCourse = useSelector(getCurrentCourse);
  const courseAliases = useSelector(state => getFlatCourseAliases(state, params.catalogId));
  const cannotBeCopiedComponentsMeta: CannotBeCopiedComponentsMeta[] = useSelector((state: RootState) => getCannotBeCopiedComponentsMeta(state, state.app.lecturePage.currentLectureId));
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));
  const currentCourseLongTeamSet = useSelector(state => getCurrentCourseCourseLongTeamSet(state));
  const currentCourseGroupTeamSet = useSelector(state => getCurrentCourseGroupTeamSet(state));
  const { canLinkCollectionLesson, canConvertToCollectionLesson, canLessonContentConvertibleToCollection } = useSelector(state => state.app.collection);

  const courseOutline = useCourseOutline(params.catalogId);

  const lectureName = lecturePage.title;

  useEffect(() => {
    if (!currentCourse?.isContentManagementCollection) {
      /**
       * To display the 'Add from Lesson Library' option, check if the user has
       * any collection with at least one folder containing a lesson.
       */
      if (canLinkCollectionLesson === null) {
        dispatch(getCanLinkCollectionLesson());
      }
    }
  }, [
    dispatch, canLinkCollectionLesson,
    currentCourse?.isContentManagementCollection,
  ]);

  useEffect(() => {
    // Make these requests only within a course lesson.
    if (!currentCourse?.isContentManagementCollection) {
      /**
       * To display the 'Add to Lesson Library' option, check if the user has a
       * collection with at least one folder available to convert a lesson
       * from a course to the collection.
       */
      if (canConvertToCollectionLesson === null) {
        dispatch(getCanConvertToCollectionLesson());
      }

      // Check that the current lesson contains only components supported by the Lesson Library.
      const canConvertLesson = canLessonContentConvertibleToCollection?.[lecturePage.id];
      if (
        !lecturePage.isLinked
        && canConvertToCollectionLesson
        && (canConvertLesson === undefined || canConvertLesson === null)
      ) {
        dispatch(canConvertToCollectionLecture({
          id: lecturePage.id,
        }));
      }
    }
  }, [
    currentCourse?.isContentManagementCollection, canConvertToCollectionLesson,
    canLessonContentConvertibleToCollection, dispatch, lecturePage.id,
    lecturePage.isLinked,
  ]);

  const duplicateThisLecture = useCallback(() => {
    // TODO: This request can take a while. On prod, we do not show any kind of loading indicator. But it feels
    // like a bad UX; let's try adding a loading indicator here and getting PM's feedback
    const duplicateParam = {
      catalogId: params.catalogId,
      lecturePageId: params.lecturePageId,
      currentCourseIsCollection: currentCourse?.isContentManagementCollection,
    };

    dispatch(duplicateLecturePage(duplicateParam)).then(result => {
      lectureLink({
        ...params,
        mode: LecturePageMode.EDIT,
        lecturePageId: (result.payload as LecturePage).id,
      });

      if (cannotBeCopiedComponentsMeta?.length > 0) {
        const bodyText: JSX.Element = (
          <div>
            {t.LECTURE_PAGES.ADMIN.DUPLICATED.DESCRIPTION(aliases.lectureAliases)}
            <div className='text-left text-medium font-weight-bold ml-6 pl-6 mt-4'>
              <ul className='ml-4 pl-4 mb-0'>
                {cannotBeCopiedComponentsMeta.map((lcMeta, idx) => (
                  <li key={lcMeta.lectureComponentId}>{lcMeta.label}</li>
                ))}
              </ul>
            </div>
          </div>
        );

        dispatch(openConfirmationDialog({
          title: t.LECTURE_PAGES.ADMIN.DUPLICATED.TITLE(courseAliases),
          bodyText,
          confirmButtonVariant: 'primary',
          confirmText: t.FORM.CLOSE(),
          onConfirm: () => { },
          noCancelButton: true,
          icon: 'info',
        }));
      }
    });
  }, [
    params, currentCourse?.isContentManagementCollection, dispatch, lectureLink,
    cannotBeCopiedComponentsMeta, aliases.lectureAliases, courseAliases,
  ]);

  const deleteThisLecture = useCallback(() => {
    dispatch(openConfirmationDialog({
      title: currentCourse?.isContentManagementCollection
        ? t.TIMELINE.DELETE_COLLECTION_LESSON.TITLE()
        : t.TIMELINE.DELETE_LESSON.ARE_YOU_SURE_LECTURE_NAME({ ...courseAliases, lectureName }),
      bodyText: currentCourse?.isContentManagementCollection
        ? t.TIMELINE.DELETE_COLLECTION_LESSON.DESCRIPTION()
        : t.TIMELINE.DELETE_LESSON.DELETE_ALL_SUBMISSIONS({ ...courseAliases, lessonName: lectureName }),
      confirmText: t.FORM.YES_SURE(),
      onConfirm: () => {
        const nearestLecturePageId = getNearestLecture(courseOutline, params.lecturePageId)?.id;
        if (nearestLecturePageId) {
          lectureLink({
            ...params,
            lecturePageId: nearestLecturePageId,
          });
        }

        const deleteParams = {
          catalogId: params.catalogId,
          lecturePageId: params.lecturePageId,
          currentCourseIsCollection: currentCourse?.isContentManagementCollection,
        };
        dispatch(deleteLecturePage(deleteParams)).then(result => {
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            header: t.FORM.SUCCESS_BANG(),
            message: t.TIMELINE.DELETE_LESSON.SUCCESS_ALERT(lectureName),
          }));

          if (!currentCourse?.isContentManagementCollection) {
            /**
             * Clearing the courseLongTeamSet/groupTeamSet if the deleted lecture
             * page has the Team/Group Formation LC.
             */
            if (
              !isEmpty(currentCourseLongTeamSet)
              && currentCourseLongTeamSet.lecturePageId === params.lecturePageId
            ) {
              CurrentCourseManager.course.courseLongTeamSet = null;
              dispatch(unsetCurrentCourseCourseLongTeamSet());
            }

            if (
              !isEmpty(currentCourseGroupTeamSet)
              && currentCourseGroupTeamSet.lecturePageId === params.lecturePageId
            ) {
              CurrentCourseManager.course.groupTeamSet = null;
              dispatch(unsetCurrentCourseGroupTeamSet());
            }
          }

          /**
           * Go to course home, if no near lessons found. It is redirected to
           * course home only after the delete to display the data as updated.
           */
          if (!nearestLecturePageId) {
            const stateName = currentCourse?.isContentManagementCollection
              ? 'content-library-collection-home' : 'course-home';
            const stateParams = {
              catalogId: params.catalogId,
              collectionId: currentCourse.id,
              institutionId: currentCourse.institutionId,
            };
            $state.go(stateName, stateParams);
          }
        });
      },
    }));
  }, [
    $state, CurrentCourseManager.course, courseAliases, courseOutline,
    currentCourseGroupTeamSet, currentCourseLongTeamSet, dispatch, lectureLink,
    lectureName, params, currentCourse?.isContentManagementCollection,
    currentCourse?.id, currentCourse?.institutionId,
  ]);

  const createLesson = useCallback(() => {
    if (currentCourse?.isContentManagementCollection) {
      dispatch(createCollectionLecturePage({
        title: 'New Lesson',
        folderId: lecturePage?.folderId,
      })).then((response) => {
        if (isEmpty(response?.error)) {
          lectureLink({
            ...params,
            mode: LecturePageMode.EDIT,
            lecturePageId: response.payload.id,
          });
        }
      });
    } else {
      dispatch(createLecturePage({ catalogId: params.catalogId, previousLectureId: params.lecturePageId })).then(actionResult => {
        lectureLink({
          ...params,
          mode: LecturePageMode.EDIT,
          lecturePageId: (actionResult.payload as LecturePage).id,
        });

        // Re-fetch the course timeline with the new lecture page
        dispatch(getFullCourseTimelineWithProgress({
          catalogId: params.catalogId,
          userId: currentUserId,
          outlineOnly: false,
          editMode: true,
        }));
      });
    }
  }, [currentCourse?.isContentManagementCollection, currentUserId, dispatch, lectureLink, lecturePage?.folderId, params]);

  const addFromLessonLibrary = useCallback(() => {
    setShowAddFromLessonModal(true);
  }, []);

  const closeAddFromLessonModal = useCallback(() => {
    setShowAddFromLessonModal(false);
  }, []);

  const addToLessonLibrary = useCallback(() => {
    setShowAddToLessonModal(true);
  }, []);

  const closeAddToLessonModal = useCallback(() => {
    setShowAddToLessonModal(false);
  }, []);

  const adminDropdownItems = useMemo<NvDropdownOption[]>(() => {
    const items: NvDropdownOption[] = [];

    if (params.mode === LecturePageMode.EDIT || params.mode === LecturePageMode.LINKED_EDIT) {
      items.push({
        type: 'text',
        text: t.LECTURE_PAGES.ADMIN.COG_OPTIONS.EXIT_EDIT_MODE(),
        callback: () => exitMode(),
        dataQa: 'exit-edit-mode-option',
      });
    } else if (params.mode === LecturePageMode.RESTRICTED_EDIT || params.mode === LecturePageMode.LINKED_EDIT) {
      items.push({
        type: 'text',
        text: t.LECTURE_PAGES.ADMIN.COG_OPTIONS.EXIT_ADMIN_MODE(),
        callback: () => exitMode(),
        dataQa: 'exit-admin-mode-option',
      });
    } else {
      items.push({
        type: 'text',
        text: t.LECTURE_PAGES.ADMIN.COG_OPTIONS.EDIT(),
        callback: () => enterMode(LecturePageMode.EDIT),
        dataQa: 'edit-lesson-option',
        disabled: SyncingStatus.SYNC_IN_PROGRESS === lecturePage.syncingStatus,
      });
    }

    if (CurrentPermissionsManager.isCourseBuilder() || CurrentPermissionsManager.hasCollectionBuilderPermissions()) {
      items.push(
        /** Preserve the previous mode entered in the nav logic if we entered from an edit mode. This way we will
         * return to the edit mode when exiting the reorder mode */
        {
          type: 'text',
          text: t.LECTURE_PAGES.ADMIN.COG_OPTIONS.REORDER(),
          disabled: (lecturePage.isLinked || SyncingStatus.SYNC_IN_PROGRESS === lecturePage.syncingStatus),
          callback: () => enterMode(
            LecturePageMode.REORDER,
            params.mode === LecturePageMode.EDIT || params.mode === LecturePageMode.RESTRICTED_EDIT,
          ),
          dataQa: 'reorder-lesson-option',
        },
        {
          type: 'text',
          text: t.LECTURE_PAGES.ADMIN.COG_OPTIONS.DUPLICATE(courseAliases),
          callback: duplicateThisLecture,
          dataQa: 'duplicate-lesson-option',
        },
      );

      const disableDelete = currentCourse?.isContentManagementCollection
        && (lecturePage.contentManagementLinks?.length > 0
          || lecturePage.syncingStatus === SyncingStatus.SYNC_IN_PROGRESS);

      items.push(
        {
          type: 'text',
          text: t.LECTURE_PAGES.ADMIN.COG_OPTIONS.DELETE(),
          class: 'text-danger',
          callback: deleteThisLecture,
          dataQa: 'delete-lesson-option',
          disabled: disableDelete,
          tooltip: {
            text: t.LECTURE_PAGES.ADMIN.COG_OPTIONS.DELETE_TOOLTIP(),
            enabled: disableDelete,
          },
        },
        { type: 'divider' },
        {
          type: 'text',
          text: currentCourse?.isContentManagementCollection
            ? t.INSTITUTIONS.CONTENT_LIBRARY.COLLECTIONS_HOME.LECTURE_LIST.LESSON_ACTION.NEW_LESSON()
            : t.LECTURE_PAGES.ADMIN.COG_OPTIONS.CREATE(courseAliases),
          callback: createLesson,
          dataQa: 'create-lesson-option',
        },
      );
    }

    if (!lecturePage.isLinked && canLessonContentConvertibleToCollection?.[lecturePage.id]) {
      items.push(
        {
          type: 'text',
          text: t.TIMELINE.LESSON_OPTIONS.ADD_TO_COLLECTION(),
          callback: addToLessonLibrary,
          dataQa: 'add-to-lesson-library-option',
        },
      );
    }

    if (!currentCourse?.isContentManagementCollection && canLinkCollectionLesson) {
      items.push(
        {
          type: 'text',
          text: t.LECTURE_PAGES.ADMIN.COG_OPTIONS.ADD_FROM_LESSON_LIBRARY(),
          callback: addFromLessonLibrary,
          dataQa: 'add-from-lesson-library-option',
        },
      );
    }

    return items;
  }, [
    courseAliases, createLesson, currentCourse?.isContentManagementCollection,
    CurrentPermissionsManager, deleteThisLecture, duplicateThisLecture,
    enterMode, exitMode, lecturePage.contentManagementLinks?.length,
    lecturePage.isLinked, lecturePage.id, params.mode, lecturePage.syncingStatus,
    addFromLessonLibrary, addToLessonLibrary, canLinkCollectionLesson,
    canLessonContentConvertibleToCollection,
  ]);

  const handleToggle = useCallback(
    (showList) => {
      setIsDropdownOpen(showList);
      if (showList && params.mode === LecturePageMode.EDIT) {
        hideAddUI(dispatch);
      }
    },
    [dispatch, params.mode],
  );

  return (
    <React.Fragment>
      <NvDropdown
        buttonStyle={NvDropdownButtonStyle.ICON}
        iconClass='icon-smallest icon-settings mr-4'
        items={adminDropdownItems}
        align={NvDropdownAlign.RIGHT}
        offset={10}
        minWidth={220}
        onToggle={handleToggle}
        ariaExpanded={isDropdownOpen}
        altLabel='gear icon'
        toggleDataQa='lecture-page-content-settings'
      />
      <LinkCollectionLessonsModal
        show={showAddFromLessonModal}
        onClose={closeAddFromLessonModal}
        sectionId={lecturePage.lectureSectionId}
        courseId={currentCourse.id}
      />

      <AddToCollectionModal
        show={showAddToLessonModal}
        onClose={closeAddToLessonModal}
        lessonId={lecturePage.id}
      />
    </React.Fragment>
  );
};

export default LectureContentAdminDropdown;

import { RootState } from 'redux/schemas';
import { QuizQuestionType } from 'redux/schemas/models/progressive-quiz';

export const getProgressiveQuiz = (
  state: RootState,
  id: number,
) => state.models.progressiveQuizzes[id];

export const getProgressiveQuizQuestion = (
  state: RootState,
  questionId: number,
) => state.models.quizQuestions[questionId];

export const getProgressiveQuizQuestions = (
  state: RootState,
  progressiveQuizId: number,
) => {
  let displayIndex = 1;
  let quizQuestionsWithDisplayIndex = [];
  state.models.progressiveQuizzes[progressiveQuizId].questions?.forEach((questionId) => {
    if (state.models.quizQuestions[questionId].type !== QuizQuestionType.STATEMENT) {
      quizQuestionsWithDisplayIndex = [
        ...quizQuestionsWithDisplayIndex,
        {
          ...state.models.quizQuestions[questionId],
          displayIndex,
        },
      ];
      displayIndex += 1;
    } else {
      quizQuestionsWithDisplayIndex = [
        ...quizQuestionsWithDisplayIndex,
        {
          ...state.models.quizQuestions[questionId],
          displayIndex: null,
        },
      ];
    }
  });

  return quizQuestionsWithDisplayIndex;
};

export const getQuizQuestionResponse = (
  state: RootState,
  responseId: number,
) => state.models.quizQuestionResponses[responseId] ?? null;

export const getQuizQuestionOptions = (
  state: RootState,
  quizQuestionId: number,
) => state.models.quizQuestions[quizQuestionId]?.responseOptions.map(
  (optionId) => state.models.quizQuestionOptions[optionId],
);

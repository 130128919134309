import React from 'react';
import { css } from '@emotion/core';
import { Omit } from 'utility-types';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { useController } from 'react-hook-form';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { Props as AnimationProps } from 'quizzes/components/quiz-animations';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownCustomItem, NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';

type Props = {
  value: number;
  description: string;
  placeholder: string;
  onChange: (newValue: number) => void;
  optionTranslation: (count: number) => string;
  AnimationComponent: React.ComponentType<AnimationProps>;
  info?: string;
  className?: string;
  canBeUnlimited?: boolean;
  renderAfter?: () => React.ReactNode;
  dataQa?: string,
} & AnimationProps;

const AttemptsSetting = (props: Props) => {
  const {
    info,
    value,
    onChange,
    className,
    renderAfter,
    description,
    placeholder,
    optionTranslation,
    AnimationComponent,
    canBeUnlimited = false,
    dataQa,
    ...animationProps
  } = props;

  const courseAliases = useSelector(getFlatCourseAliases);

  const styles = css`
    .dropdown-container {
      flex: 1;

      .attempts-button {
        width: 260px;
      }

      .attempts-menu {
        min-width: 260px;
      }
    }
  `;

  const attemptsCountItems = Array(5).fill(null).map((_, index) => ({
    type: 'text',
    value: index + 1,
    text: optionTranslation(index + 1),
    callback: () => onChange(index + 1),
    /** The data qa option adds as per the attempt type. So appending the
     * "OPTION" text with the prop to generate the QA attribute.
     */
    dataQa: `${dataQa}-OPTION-${index + 1}`,
    dataQaPendo: `${dataQa}-OPTION`,
  })) as NvDropdownTextItem[];

  const items = [
    ...attemptsCountItems,
    ...(canBeUnlimited ? [{
      type: 'custom',
      customItem: (
        <Dropdown.Item
          as='button'
          onClick={() => onChange(-1)}
          className='role-item d-flex'
          data-qa={`${dataQa}-OPTION-UNLIMITED`}
          data-qa-pendo={`${dataQa}-OPTION`}
        >
          <div>
            {t.SHARED.UNLIMITED()}
          </div>
          <div>
            <NvPopover
              showOnHover
              placement='top'
              preventOverflow
              content={(
                <div>
                  {t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.UNLIMITED_ATTEMPTS_INFO(courseAliases)}
                </div>
              )}
            >
              <NvIcon icon='info' size='xss-smallest' className='color-primary' />
            </NvPopover>
          </div>
        </Dropdown.Item>
      ),
    }] : []) as NvDropdownCustomItem[],
  ];

  const lastItemIndex = items.length - 1;

  const selectedIndex = value === -1 ? lastItemIndex : attemptsCountItems.findIndex((item) => item.value === value);

  const dropdownTitle = (selectedIndex === lastItemIndex && canBeUnlimited) ? t.SHARED.UNLIMITED() : attemptsCountItems[selectedIndex]?.text;

  return (
    <div css={styles} className={`d-flex align-items-center ${className}`}>
      <div className='mr-8'>
        <AnimationComponent {...animationProps} />
      </div>
      <div className='dropdown-container d-flex flex-column'>
        <div className='align-self-start mb-2 d-flex align-items-center'>
          <NvDropdown
            items={items}
            initialIndex={selectedIndex}
            menuClassName='attempts-menu'
            buttonClass='attempts-button'
            buttonStyle={NvDropdownButtonStyle.FORM_SMALL}
            title={value !== null ? dropdownTitle : placeholder}
            toggleDataQa={dataQa}
          />
          {!!info && (
            <NvPopover
              showOnHover
              placement='top'
              preventOverflow
              className='ml-2'
              content={(
                <div>
                  {info}
                </div>
              )}
            >
              <NvIcon icon='info' size='small' className='text-primary' />
            </NvPopover>
          )}
        </div>
        <div className='text-small gray-2'>
          {description}
        </div>
        {renderAfter?.()}
      </div>
    </div>
  );
};

type FormAttemptsSettingProps = {
  name: string;
} & Omit<Props, 'onChange' | 'value'>;

export const FormAttemptsSetting = (props: FormAttemptsSettingProps) => {
  const { name, ...restProps } = props;

  const { field: { onChange, value } } = useController({
    name,
  });

  return (
    <AttemptsSetting onChange={onChange} value={value} {...restProps} />
  );
};

export default AttemptsSetting;

import { saveLanguage, setLanguage } from 'react-translate';

/* Shim in support for Java style translation locales, turning 'en_US' -> 'en-US'.
This is really unfortunate but I've not found any other means to support using Java style
locales in our file names while still playing nice with Int.NumberFormat */
const nativeNumberFormat = Intl.NumberFormat;
Intl.NumberFormat = (locales, ...args) => nativeNumberFormat(locales?.replace('_', '-'), ...args);

/* eslint-disable global-require */
/* eslint-disable camelcase */
// need this to do file name replacements
const KEY_TO_FILE_NAME = {
  en_US: 'en_US.json',
  es_MX: 'es_MX.json',
  es_ES: 'es_ES.json',
  fr_FR: 'fr_FR.json',
  pt_PT: 'pt_PT.json',
  pt_BR: 'pt_BR.json',
  zh_CN: 'zh_CN.json',
  ja_JP: 'ja_JP.json',
  ko_KP: 'ko_KP.json',
  ru_RU: 'ru_RU.json',
  de_DE: 'de_DE.json',
  ar_SA: 'ar_SA.json',
  he_IL: 'he_IL.json',
  pl_PL: 'pl_PL.json',
  it_IT: 'it_IT.json',
  fr_CA: 'fr_CA.json',
  nl_NL: 'nl_NL.json',
  sv_SE: 'sv_SE.json',
  ro_RO: 'ro_RO.json',
  tr_TR: 'tr_TR.json',
  id_ID: 'id_ID.json',
  th_TH: 'th_TH.json',
  zh_TW: 'zh_TW.json',
};

// Replace the file names above with their revisioned versions from the
// manifest
if (process.env.NODE_ENV === 'production') {
  /* eslint-disable import/no-unresolved */
  // references a generated file that may not be available before compilation
  const manifest = require('./../dist/rev-manifest.json');
  Object.keys(KEY_TO_FILE_NAME).forEach((key) => {
    KEY_TO_FILE_NAME[key] = manifest[KEY_TO_FILE_NAME[key]];
  });
}

/* @ngInject */
export function $translateStaticFilesLoader($q, $http) {
  return function (options) {
    const key = KEY_TO_FILE_NAME[options.key];

    return $http(angular.extend({
      url: [
        options.prefix,
        key,
      ].join(''),
      method: 'GET',
      params: '',
    }, options.$http))
      .then((result) => {
        if (result.data) {
          // Save the react runtime generated translations for each language
          saveLanguage(options.key, result.data);
        } else {
          // we were getting empty translation objects - https://sentry.io/organizations/novoed/issues/1730135047/events/e07520243ff044ae98a66e3363d4b0a6/
          throw Error('Language not loaded in react-translate but no languageJson was provided for', options.key);
        }

        return result.data;
      }, () => $q.reject(options.key));
  };
}

$translateStaticFilesLoader.displayName = '$translateStaticFilesLoader';

/* @ngInject */
export function configureTranslateProvider($translateProvider, config) {
  let $cookies;
  angular.injector(['ngCookies']).invoke(['$cookies', function (_$cookies) {
    $cookies = _$cookies;
  }]);
  const language = $cookies.get('platform_language') || config.defaultPlatformLanguage;
  $translateProvider.useMessageFormatInterpolation();
  $translateProvider.fallbackLanguage(config.defaultPlatformLanguage);
  $translateProvider.preferredLanguage(language);
  $translateProvider.useSanitizeValueStrategy(null);

  // Makes a require context that loads in all of our YML config files for language translations as JSON
  function requireLangContext() {
    return require.context('json-loader!yaml-loader!../languages/', false, /\.yml/);
  }

  // Helper function to get language string from yml file name
  function getLang(key) {
    const lang = key.match('/(.+?).yml$');
    if (lang && lang[1]) {
      return lang[1];
    }
    return '';
  }

  let context = null;

  // Manually set the default en_US translation text for dev mode
  if (process.env.NODE_ENV === 'development') {
    context = requireLangContext();
    context.keys().forEach((key) => {
      const module = context(key);

      if (key.includes('global.yml')) {
        // There is a problem here. The language switch dropdown
        // only display the correct translation verbiage
        // when the selected language is the following one
        // on hot reload mode.
        $translateProvider.translations(language, module);
      } else {
        const lang = getLang(key);
        $translateProvider.translations(lang, module);
        // Save the react runtime generated translations for each language
        // Does not set as the current language; see setLanguage() below
        saveLanguage(lang, module);
      }
    });
  } else {
    // Prod mode static file setup
    $translateProvider.useStaticFilesLoader({
      prefix: config.translation.staticUrl.prefix,
    });
  }

  setLanguage(language);

  $translateProvider.forceAsyncReload(true);

  // Hot module reloading of langs files.
  if (module.hot) {
    context.keys().forEach((key) => {
      const module = context(key);
    });

    const modules = {};

    module.hot.accept(context.id, () => {
      const reloadedContext = requireLangContext();
      const changedModules = reloadedContext.keys()
        .map((key) => [key, reloadedContext(key)])
        .filter((reloadedModule) => modules[reloadedModule[0]] !== reloadedModule[1]);

      changedModules.forEach((module) => {
        const lang = getLang(module[0]);
        // A somewhat hacky way to get the $state service and reload it after registering the new translations, to force
        // the new translations to appear.
        const injector = angular.element(document.body).injector();
        $translateProvider.translations(lang, module[1]);
        injector.get('$state').reload();
        modules[module[0]] = module[1];

        // Force re-evaluating the runtime translations for the current language
        if (lang === language) {
          setLanguage(lang, module[1], true);
        }
      });
    });
  }
}

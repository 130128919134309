import { css } from '@emotion/react';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { primary } from 'styles/global_defaults/colors';

export default css`
  position: absolute;
  top: ${halfSpacing}px;
  right: ${halfSpacing}px;
  display: flex;

  .nv-dropdown {
    &:hover {
      .icon {
        color: ${primary};
      }
    }
  }
`;

import { css } from '@emotion/react';
import React, { useState, useRef, useContext, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { first } from 'underscore';
import { useAppDispatch } from 'redux/store';

import { standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { gray6, primary, info, hexToRgbaString } from 'styles/global_defaults/colors';
import { handheld, notHandheld, tablet } from 'styles/global_defaults/media-queries';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';

import { fetchReplies } from 'redux/actions/replies';
import { voteUpComment, voteDownComment, getCommentLikersInfo, translateComment } from 'redux/actions/comments';
import { BaseUser } from 'redux/schemas/models/my-account';

import { ExposedAngularServices, AngularServicesContext } from 'react-app';
import NvUserAvatar from 'components/nv-user-avatar';
import NvIcon from 'shared/components/nv-icon';
import DiscussionReply from 'lecture_pages/components/discussion/discussion-reply';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import ClickableContainer from 'components/clickable-container';
import { Comment } from 'redux/schemas/models/comment';
import { getCommentTranslationById } from 'redux/selectors/discussion-translations';
import { DiscussionTranslation } from 'redux/schemas';
import { getCurrentUser } from 'redux/selectors/users';
import { extractTextFromHTML } from 'shared/utils';
import DiscussionReplies from './discussion-replies';
import DiscussionLikes from './discussion-likes';
import DiscussionCommentActions from './discussion-comment-actions';
import DiscussionReplyForm from './discussion-reply-form';
import DiscussionCommentForm from './discussion-comment-form';

import { config } from '../../../../config/pendo.config.json';
import { TeamDiscussionContext } from './team-discussion';
import TranslationButton from '../../../components/translation-button';

interface DiscussionCommentProps {
  commentId: number;
  mentionableMembers?: BaseUser[];
}

const DiscussionComment = (props: DiscussionCommentProps) => {
  const styles = css`
    display: flex;

    .animation-display {
      white-space: nowrap;
      display: flex;
      align-items: center;
    }

    .comment-reply-wrapper {
      flex: 1;

      .load-previous-replies {
        background-color: ${hexToRgbaString(gray6, 0.9)};
        border-left: 1px solid ${gray6};
        border-right: 1px solid ${gray6};
        padding-left: ${standardSpacing}px;
        padding-right: ${standardSpacing}px;
        padding-top: ${standardSpacing}px;
        display: flex;
        color: ${primary};
        border-radius: unset;

        ${notHandheld(css`
          margin-left: ${halfSpacing}px;
        `)};
      }

      .comment-body {
        position: relative;
        background: ${hexToRgbaString(gray6, 0.6)};
        padding: ${standardSpacing}px;
        border-left: 1px solid ${gray6};
        border-right: 1px solid ${gray6};
        border-bottom: 1px solid ${gray6};

        .show-on-hover {
          visibility: hidden;

          ${handheld(css`
            visibility: visible;
          `)};

          ${tablet(css`
            visibility: visible;
          `)};
        }

        &:focus-within,
        &:hover {
          .show-on-hover {
            visibility: visible;
          }
        }

        a[oe-mention] {
          background-color: ${info};
          font-weight: ${semiBoldFontWeight};
          color: ${primary};
        }

        .text-body {
          text-align: left;
        }

        ${handheld(css`
          .text-body {
            margin-top: ${standardSpacing}px;
          }
        `)};

        ${notHandheld(css`
          margin-left: ${halfSpacing}px;
        `)};

        .bottom-row {
          margin-top: ${standardSpacing}px;
          display: flex;
          justify-content: space-between;

          .text-date {
            display: flex;
            flex-shrink: 0;
            margin-left: ${halfSpacing}px;
            white-space: nowrap;
          }

          .social-row {
            display: flex;
            gap: ${standardSpacing}px;

            // Romoving the gap space when there is reply count
            &:has(.replies-count) {
              .translation-container {
                margin-left: -${standardSpacing}px;
              }
            }
          }
        }
      }
    }

    ${notHandheld(css`
      .comment-avatar {
        width: 80px;
        padding-top: ${standardSpacing}px;
      }
    `)};
  `;

  const dispatch = useAppDispatch();

  const angularServices: ExposedAngularServices = useContext(AngularServicesContext);
  const { postId } = useContext(TeamDiscussionContext);

  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const post = useSelector((state) => state.models.posts[postId]);
  const comment: Comment = useSelector((state) => state.models.comments[props.commentId]);
  const isNewComment = useSelector((state) => state.app.newCommentIdsForCurrentUser.includes(comment.id));

  const froalaRef = useRef(null);

  /* Boolean used to determine when to show new reply form vs when to toggle replies */
  const [newReply, setNewReply] = useState(false);
  const [showReplies, setShowReplies] = useState(true);
  const [editing, setEditing] = useState(false);
  const [mentionedUser, setMentionedUser] = useState(null);

  const {
    body: bodyTranslation,
    isLoading: isTranslationLoading,
    error: hasTranslationFailed,
  }: DiscussionTranslation = useSelector(getCommentTranslationById(comment.id)) || {};
  const [showTranslatedComment, setShowTranslatedComment] = useState<boolean>(false);

  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    if (hasTranslationFailed) setShowTranslatedComment(false);
  }, [hasTranslationFailed]);

  /* Load 3 replies by default */
  useEffect(() => {
    if (!comment.repliesFetched && comment.repliesCount) {
      dispatch(fetchReplies({
        catalogId,
        commentId: props.commentId,
        pageSize: 3,
        vieweeLastActivity: post.vieweeLastActivity,
      }));
    }
  }, [catalogId, props.commentId, dispatch, comment, post.vieweeLastActivity]);

  const onLike = () => {
    dispatch(voteUpComment({
      catalogId,
      commentId: props.commentId,
    }));
  };

  const onUnlike = () => {
    dispatch(voteDownComment({
      catalogId,
      commentId: props.commentId,
    }));
  };

  const onLoadPreviousReplies = () => {
    dispatch(fetchReplies({
      catalogId,
      commentId: props.commentId,
      beforeId: first(comment.replyIds),
      vieweeLastActivity: post.vieweeLastActivity,
    }));
  };

  const onClickReplyIcon = (user: BaseUser) => {
    setNewReply(true);
    setTimeout(() => {
      froalaRef?.current?.focus();
    });

    setMentionedUser(user);
  };

  const onClickReplyText = () => {
    setShowReplies(!showReplies);
  };

  const onEditComment = () => {
    setEditing(true);
  };

  const getLikersInfo = () => {
    dispatch(getCommentLikersInfo({
      catalogId,
      commentId: comment.id,
      page: 1,
    }));
  };

  let animationText = t.LECTURE_PAGES.COMPONENTS.TEAM_DISCUSSION.FIRST();
  if (post.commentIds.length > 1 && comment.isFirstContribution) {
    animationText = t.LECTURE_PAGES.COMPONENTS.TEAM_DISCUSSION.GOOD_JOB_PARTICIPATING();
  }

  if (editing) {
    return (
      <DiscussionCommentForm
        edit
        showFroala={editing}
        commentId={props.commentId}
        afterSubmit={() => setEditing(false)}
        onCancel={() => setEditing(false)}
        mentionableMembers={props.mentionableMembers}
      />
    );
  }

  const animationDisplay = (
    <div className='animation-display'>
      <NvIcon icon='like' size='smallest' />
      {animationText}
    </div>
  );

  const onTranslationToggle = (isTranslationOn: boolean, language: string) => {
    setShowTranslatedComment(isTranslationOn);
    if (isTranslationOn) {
      dispatch(translateComment({ catalogId, commentId: comment.id, language }));
    }
  };

  return (
    <div css={styles}>
      {/* Hidden only on xs */}
      <div className='d-none d-md-block comment-avatar'>
        <NvUserAvatar
          animate={isNewComment}
          animationDisplay={animationDisplay}
          directToProfile
          notifications={comment.newForViewer}
          user={comment.user}
          size='sm'
          borderType='round'
          displayName
          pendoTagName={config.pendo.userProfiles.openProfile}
        />
      </div>
      <div className='comment-reply-wrapper'>
        <div className='comment-body'>
          {/* Visible only on xs */}
          <div className='d-block d-md-none comment-avatar'>
            <NvUserAvatar
              animate={isNewComment}
              animationDisplay={animationDisplay}
              directToProfile
              notifications={comment.newForViewer}
              user={comment.user}
              size='sm'
              borderType='round'
              displayName
              alignNameRight
              pendoTagName={config.pendo.userProfiles.openProfile}
            />
          </div>
          <DiscussionCommentActions
            commentId={props.commentId}
            onEditComment={onEditComment}
          />
          <div className='text-body'>
            <ResponsivelyEmbeddedAngularHTML
              template={
                showTranslatedComment ? bodyTranslation : comment.body
              }
              angularServices={angularServices}
            />
          </div>
          <div className='bottom-row'>
            <div className='social-row'>
              <DiscussionLikes
                getLikersInfo={getLikersInfo}
                discussionComponent={comment}
                like={onLike}
                unlike={onUnlike}
              />
              <DiscussionReplies
                isRepliesExpanded={showReplies}
                onClickIcon={() => onClickReplyIcon(null)}
                onClickText={onClickReplyText}
                discussionComponent={comment}
              />
              <TranslationButton
                onToggle={onTranslationToggle}
                isToggled={showTranslatedComment}
                isLoading={isTranslationLoading}
                isVisible={comment.user.id !== currentUser.id}
                dataQa={config.pendo.teamDiscussion.translationButton}
                sourceText={extractTextFromHTML(comment.body)}
                iconSize='small'
              />
            </div>
            <div className='text-date text-gray-2'>
              {moment(comment.createdAt).format('lll')}
            </div>
          </div>
        </div>
        {showReplies && (
          <React.Fragment>
            {comment.additionalRepliesBeforeCount > 0 && (
              <ClickableContainer className='load-previous-replies btn btn-link label' onClick={onLoadPreviousReplies}>
                {comment.additionalNewRepliesBeforeCount > 0
                  ? t.DISCUSSIONS.LOAD_PREVIOUS_REPLIES_WITH_NEW(comment.additionalRepliesBeforeCount, comment.additionalNewRepliesBeforeCount)
                  : t.DISCUSSIONS.LOAD_PREVIOUS_REPLIES(comment.additionalRepliesBeforeCount)}
              </ClickableContainer>
            )}
            {(comment?.replyIds || []).map(replyId => (
              <DiscussionReply
                key={replyId}
                commentId={props.commentId}
                replyId={replyId}
                onClickReply={onClickReplyIcon}
                mentionableMembers={props.mentionableMembers}
              />
            ))}
          </React.Fragment>
        )}
        {newReply && (
          <DiscussionReplyForm
            commentId={props.commentId}
            ref={froalaRef}
            onCancel={() => setNewReply(false)}
            afterSubmit={() => setNewReply(false)}
            mentionableMembers={props.mentionableMembers}
            mentionedUser={mentionedUser}
          />
        )}
      </div>
    </div>
  );
};

export default DiscussionComment;

import { css } from '@emotion/react';
import moment from 'moment';
import { MouseEvent, useRef, useState, useCallback, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';

import NvPopover from 'shared/components/nv-popover';
import NvTooltip from 'shared/components/nv-tooltip';
import NvUserAvatar from 'components/nv-user-avatar';
import { sizes } from 'components/nv-avatar';
import NvIcon from 'shared/components/nv-icon';
import { PopoversContainerContext } from 'shared/react-utils';
import ClickableContainer from 'components/clickable-container';

import { gray1, gray6, gray7, primary, warning } from 'styles/global_defaults/colors';
import { boldFontWeight, textSmallFontSize } from 'styles/global_defaults/fonts';
import { halfSpacing, standardSpacing, largeSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { handheld, isHandheld, notHandheld } from 'styles/global_defaults/media-queries';

import { Post } from 'redux/schemas/models/post';
import { CombinedCourse, RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';
import useWindowResize from 'shared/hooks/use-window-resize';
import useClickOutside from 'shared/hooks/use-click-outside';
import Button from 'react-bootstrap/Button';

import { config } from '../../../../config/pendo.config.json';
import { TeamDiscussionContext } from './team-discussion';
import { LecturePageMode } from '..';

interface ProfilePanelProps {
  commentsExpanded?: boolean,
  onClickJoin: (e: MouseEvent) => void;
  onClickCommentsText: (e: MouseEvent) => void;
  onClickCommentsIcon: (e: MouseEvent) => void;
}

const TeamProfilePanel = (props: ProfilePanelProps) => {
  const styles = css`
    position: relative;
    border-left: 1px solid ${gray6};
    border-right: 1px solid ${gray6};
    border-bottom: 1px solid ${gray6};
    margin-left: 90px;

    ${handheld(css`
      margin-left: 0;
    `)};

    .profile-background {
      background-color: ${gray7};
    }

    .buffer-top {
      height:30px;
      display: flex;
      justify-content: flex-end;
      padding: 0 ${standardSpacing}px;
      ${handheld(css`
        visibility: hidden;
      `)};
    }

    .header {
      position: absolute;
      display: flex;
      align-items: flex-start;
      top: 0;
      left: ${standardSpacing}px;

      .team-profile-picture {
        width: 60px;
        height: 60px;
      }

      .title {
        margin-left: ${halfSpacing}px;
        display: flex;
        align-items: center;
        font-weight: ${boldFontWeight};
        height: 30px;
        ${handheld(css`
          flex-direction: column;
          align-items: flex-start;
          align-self: center;
          height: 60px;
          justify-content: space-around;
        `)};

        .icon-comments-wrapper {
          ${notHandheld(css`
            margin-left: ${halfSpacing}px;
          `)};
        }

        .comments-text {
          color: ${primary};
          cursor: pointer;
          margin-left: ${halfSpacing}px;
          font-weight: normal;
        }
      }
    }

    .avatar-and-button {
      display: flex;
      justify-content: space-between;

      padding-left: 90px;

      ${handheld(css`
        padding: ${halfSpacing}px;
        flex-direction: column;
      `)};

      .avatars-container {
        display: flex;
        flex: 1;
        margin-top: ${halfSpacing}px;
        margin-bottom: ${halfSpacing}px;

        ${handheld(css`
          margin-top: ${largeSpacing}px;
          margin-bottom: ${halfSpacing}px;
          justify-content: center;
        `)};

        .popover-button {
          cursor: pointer;
          border-radius: 50%;
          background-color: ${gray6};
          color: ${gray1};
          display: flex;
          align-items: center;
          justify-content: center;
          height: ${sizes.md}px;
          width: ${sizes.md}px;
          font-size: ${textSmallFontSize}px;
          position: relative;

          .notifications-badge {
            z-index: 1;
            align-items: center;
            display: flex;
            justify-content: center;
            position: absolute;
            top: -5px;
            right: -5px;
            color: white;
            background-color: ${warning};
            border-radius: 50%;
            font-size: ${textSmallFontSize}px;
            font-weight: ${boldFontWeight};
            width: ${standardSpacing}px;
            height: ${standardSpacing}px;
            line-height: ${standardSpacing}px;
          }
        }
      }

      .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: ${largeSpacing}px;
        margin-right: ${standardSpacing}px;
        white-space: nowrap;

        ${handheld(css`
          margin-left: 0;
          margin-right: 0;
          margin-bottom: ${halfSpacing}px;
        `)};
      }
    }

    .avatars-container {
      .user-avatar {
        &:not(:last-of-type) {
          margin-right: ${standardSpacing}px;
        }
      }
    }

    .user-avatar {
      .nv-user-avatar:not(.contributed) {
        opacity: 0.5;
      }
    }

    .popover-avatars {
      display: flex;
      flex-wrap: wrap;
      max-width: 240px;
      max-height: 240px;
      overflow-y: auto;

      .user-avatar.inside-popover {
        padding-bottom: ${halfSpacing}px;
      }
    }

    /* Need to override popover positioning so it sits underneath the popover button */
    .bs4-popover {
      top: -30px !important;

      .bs4-popover-body {
        padding: ${halfSpacing}px;
      }
    }

    .link-button {
      border: none;
      background: none;
      outline: none;
      padding-left: 0;
    }
  `;

  const { postId, mode } = useContext(TeamDiscussionContext);
  const { $state } = useContext(AngularServicesContext);

  const aliases = useSelector<RootState, CourseAliases>((state) => getCourseAliases(state));
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const post = useSelector((state) => state.models.posts[postId]);

  const popoverRef = useRef(null);
  const containerRef = useRef(null);
  const buttonContainerRef = useRef(null);

  const [showPopover, setShowPopover] = useState(false);
  const [numAvatars, setNumAvatars] = useState(0);

  /**
   * Calculate width of remaining space to fill avatars by
   * subtracting width of button from parent container.
   */
  const setProfilePanelRef = useCallback(node => {
    if (node) {
      const containerRect = node.getBoundingClientRect();
      const buttonRect = buttonContainerRef.current.getBoundingClientRect();
      const avatarCount = isHandheld()
        ? Math.floor((containerRect.width - 40) / 60) - 1
        : Math.floor((containerRect.width - 90 - buttonRect.width) / 60) - 1;
      setNumAvatars(avatarCount);
    }

    containerRef.current = node;
  }, []);

  useWindowResize(() => {
    setProfilePanelRef(containerRef.current);
  }, 50, false, [containerRef, setProfilePanelRef]);

  useClickOutside(popoverRef, () => {
    setShowPopover(false);
  });

  const { teamDiscussionMembers } = post;
  const displayPopover = numAvatars < teamDiscussionMembers.length;

  let displayAvatars = teamDiscussionMembers;
  let popoverContent;
  let remainingAvatars = [];

  if (displayPopover) {
    displayAvatars = teamDiscussionMembers.slice(0, numAvatars);
    remainingAvatars = teamDiscussionMembers.slice(numAvatars);
    popoverContent = (
      <div className='popover-avatars' ref={popoverRef}>
        {remainingAvatars.map(member => (
          <div
            key={member.id}
            className='user-avatar inside-popover'
            /**
             * This width calculation is used for handling the width of the
             * `popover-avatars`class when there is a scrollbar.
             */
            css={css`width: calc(100% / ${remainingAvatars.length > 4 ? 4 : remainingAvatars.length})`}
          >
            <NvUserAvatar
              directToProfile
              notifications={member.newContributions}
              user={member.user}
              messages={member.totalContributions}
              className={`p-2 ${member.totalContributions ? 'contributed' : ''}`}
              size='md'
              borderType='round'
              pendoTagName={config.pendo.teamDiscussion.openUserProfile}
            />
          </div>
        ))}
      </div>
    );
  }

  const remainingTotalNotifications = useMemo(() => {
    let totalCount = 0;
    remainingAvatars.forEach(member => {
      totalCount += member.newContributions;
    });
    return totalCount;
  }, [remainingAvatars]);

  return (
    <PopoversContainerContext.Provider value={containerRef.current}>
      <div css={styles}>
        <div className='buffer-top'>
          <div className='text-date text-gray-2'>
            {moment(post.createdAt).format('lll')}
          </div>
        </div>
        <div className='profile-background'>
          <div className='header'>
            <a href={$state.href('team-profile-modal', { catalogId, teamId: post.team.id })}>
              <img
                className='team-profile-picture'
                src={post.team.profilePicture}
                alt={t.LECTURE_PAGES.PROFILE_PICTURE_SCREEN_READ_ONLY()}
                pendo-tag={config.pendo.teamProfiles.openProfile}
              />
            </a>
            <div className='title'>
              {post.team.name}
              <div className='d-flex align-items-center'>
                <NvTooltip text={t.DISCUSSIONS.COMMENT()}>
                  <button
                    type='button'
                    className='link-button icon-comments-wrapper'
                    onClick={props.onClickCommentsIcon}
                    aria-label={t.DISCUSSIONS.COMMENTS()}
                  >
                    <NvIcon
                      size='smallest'
                      icon='comments'
                    />
                  </button>
                </NvTooltip>
                {post.numPostsAndComments > 0 && (
                  <button
                    type='button'
                    className='link-button comments-text'
                    onClick={props.onClickCommentsText}
                    pendo-tag-name={config.pendo.teamDiscussion.commentIcon}
                    aria-expanded={props.commentsExpanded}
                  >
                    <span className='sr-only'>{t.COURSE_HOME.EXPAND()}</span>
                    {post.numPostsAndComments}
                    <span className='sr-only'>{t.DISCUSSIONS.COMMENTS()}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className='avatar-and-button' ref={setProfilePanelRef}>
            <div className='avatars-container'>
              {displayAvatars.map(member => (
                <div key={member.id} className='user-avatar displayed'>
                  <NvUserAvatar
                    directToProfile
                    notifications={member.newContributions}
                    className={member.totalContributions ? 'contributed' : ''}
                    messages={member.totalContributions}
                    user={member.user}
                    size='md'
                    borderType='round'
                    pendoTagName={config.pendo.teamDiscussion.openUserProfile}
                  />
                </div>
              ))}
              {displayPopover && (
                <NvPopover
                  placement='bottom'
                  content={popoverContent}
                  show={showPopover}
                  preventOverflow
                >
                  <NvTooltip
                    text={t.LECTURE_PAGES.COMPONENTS.TEAM_DISCUSSION.MORE_MEMBERS({ memberCount: remainingAvatars.length, ...aliases.teamAliases })}
                  >
                    <div className='popover-button' onClick={() => setShowPopover(true)} pendo-tag-name={config.pendo.teamDiscussion.overflowIcon}>
                      {`+${remainingAvatars.length}`}
                      {remainingTotalNotifications > 0 && <div className='notifications-badge'>{remainingTotalNotifications}</div>}
                    </div>
                  </NvTooltip>
                </NvPopover>
              )}
            </div>
            <div className='button-container' ref={buttonContainerRef}>
              {post.numPostsAndComments ? (
                <Button variant='primary' onClick={props.onClickJoin} pendo-tag-name={config.pendo.teamDiscussion.join} disabled={mode === LecturePageMode.EDIT}>
                  {t.LECTURE_PAGES.COMPONENTS.TEAM_DISCUSSION.JOIN_DISCUSSION()}
                </Button>
              ) : (
                <Button variant='primary' onClick={props.onClickJoin} pendo-tag-name={config.pendo.teamDiscussion.start} disabled={mode === LecturePageMode.EDIT}>
                  {t.LECTURE_PAGES.COMPONENTS.TEAM_DISCUSSION.START_DISCUSSION()}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </PopoversContainerContext.Provider>
  );
};

export default TeamProfilePanel;

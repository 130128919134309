/* @ngInject */
export default function DiscussionsRhsController(
  DiscussionsManager,
  $stateParams,
  $state,
  CurrentUserManager,
  $window,
  _,
) {
  const vm = this;
  vm.discussionsManager = DiscussionsManager;
  vm.currentUserManager = CurrentUserManager;
  vm.trendingTopicsExpanded = false;
  vm.topicsList = [];
  vm.Math = $window.Math;
  vm.newlyAddedTopicId = $stateParams.newTopicId;


  DiscussionsManager.initialize({ catalogId: $stateParams.catalogId });


  if (!DiscussionsManager.rhsInitialized) {
    DiscussionsManager.getUsersActiveInDiscussions();
  }

  DiscussionsManager.getTrendingTopics($stateParams);


  vm.setTrendingTopicsState = function (expanded) {
    vm.trendingTopicsExpanded = expanded;
  };


  vm.setDropdownIsOpen = function (open, topic) {
    topic.editDropdownIsOpen = open;
  };

  vm.cancelClickLink = function ($event) {
    $event.stopPropagation();
  };

  vm.selectTopicFilter = function (topic, $event) {
    if ($event.target === $('.topic-edit-dropdown-menu')) {
      return;
    }

    vm.discussionsManager.setSortOrder('recent');
    vm.discussionsManager.selectTopicFilter(topic);
  };

  vm.handleKeyDown = function ($event, topic) {
    if ($event.key === 'Enter' || $event.key === ' ') {
      $event.preventDefault();
      vm.selectTopicFilter(topic, $event);
    }
  };
}



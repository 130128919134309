import { flatten } from 'underscore';
import { FeedbackCategory, OwnerType } from 'redux/schemas/models/course-communication';
import { BaseUser } from 'redux/schemas/models/my-account';
import { SanitizationLevel } from './sanitizer';

/* Interfaces */
export enum FroalaViewMode {
  AIR = 'Air',
  INLINE = 'Inline',
  NORMAL = 'Normal',
}

export enum UploadType {
  ALL = 'All',
  IMAGE_ONLY = 'ImageOnly',
  NONE = 'none',
}

// TODO May Need to refactor the code
export interface RelatedActivity {
  id: number,
  type: OwnerType,
}

export interface RelatedCourse {
  id: number,
  tagName: string,
  dateType?: string,
}

export type NvFroalaProps = {
  /* Common Options */
  preset?: FroalaViewMode,
  withCodeView?: boolean,
  sanitizationLevel?: SanitizationLevel,
  onChange?(content: string): void,
  onFocus?(event: any): void,
  onBlur?(event: any, content: string): void,

  /**
   * This prop (blockBackspaceOnEmpty) controls whether the editor should prevent the user from backspacing
   * further when the text is fully deleted. When set to true, any additional
   * backspace action after the content is cleared will be blocked. This is useful
   * for maintaining the default styling of the editor even when the content is empty.
   */
  blockBackspaceOnEmpty?: boolean
  // in a form
  withForm?: boolean,
  name?: string,
  // not in a form
  value?: string,
  mentionableUsers?: BaseUser[],
  // display Insert Tag Modal Icon
  withInsertTag?: boolean,
  // suggested default tags
  relatedActivities?: RelatedActivity[],
  // extra tags if any
  extraTags?: RteTagValue[],
  fileExtensions?: string[],

  /* Uncommon Options */
  toolbarButtons?: ToolbarOptions[],
  placeholder?: string,
  minHeight?: number,
  uploadType?: UploadType,
  isDisabled?: boolean,
  className?: string,
  ariaLabel?: string,
  dataQa?: string,
  dataQaId?: string,

  /** see: https://froala.com/wysiwyg-editor/v2-0/docs/options#editorClass */
  editorClass?: string,
  immediateReactModelUpdate?: boolean,
  scrollableContainer?: string;
  keepFormatOnDelete?: boolean,
  pastePlain?: boolean;
  // Whether to allow toolbar no matter if preset is AIR
  allowToolbar?: boolean;
};

export interface RteEmbedding {
  src: string,
  isImage: boolean,
  hasDirective: boolean,
  width: number,
  height: number,
  fixedHeight: boolean,
}

export interface FileLikeBlob extends Blob {
  name?: string,
}

export type UploadableFile = File | FileLikeBlob;

export type UploadableFileList = FileList | File[] | FileLikeBlob[];

/* Language Related */
export const DEFAULT_LANGUAGE = 'en';
export const LANGUAGE_MAPPING = {
  en_US: 'en',
  es_MX: 'es',
  es_ES: 'es',
  fr_FR: 'fr',
  pt_PT: 'pt_pt',
  pt_BR: 'pt_br',
  zh_CN: 'zh_cn',
  zh_TW: 'zh_tw',
  ja_JP: 'ja',
  ko_KP: 'ko',
  ru_RU: 'ru',
  de_DE: 'de',
  ar_SA: 'ar',
  he_IL: 'he',
  pl_PL: 'pl',
  it_IT: 'it',
  fr_CA: 'fr',
  nl_NL: 'nl',
  sv_SE: 'sv',
  ro_RO: 'ro',
  tr_TR: 'tr',
  id_ID: 'id',
  th_TH: 'th',
};


/* Styles for Text */
const FROALA_TEXT_SIZES = ['title', 'subtitle', 'title-small', 'big', 'medium', 'regular', 'small'];
const FROALA_ALIGNMENT = ['left', 'center', 'right'];
export const FROALA_TEXT_CLASSES = flatten([FROALA_TEXT_SIZES.map(size => `froala-style-${size}`), FROALA_ALIGNMENT.map(alignment => `froala-text-align-${alignment}`)]);


/* Configurations */
export const PARAGRAPH_STYLES = ['froala-style-title', 'froala-style-subtitle', 'froala-style-title-small', 'froala-style-big', 'froala-style-medium', 'froala-style-regular', 'froala-style-small'];
export const DEFAULT_PARAGRAPH_STYLE = 'froala-style-regular';
export const PASTE_STRIP_ATTRS = ['nv-compile-once', 'nv-compile-always'];
export const HTML_ALLOWED_TAGS = [
  'div', 'iframe', 'p', 'br', 'hr',
  'span', 'a', 'blockquote', 'strong', 'b', 'em', 'u',
  'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre', 'img',
  'ul', 'ol', 'li',
  'table', 'thead', 'th', 'tbody', 'tr', 'td',
];
// copied from PRIVATE_COURSE_ADMIN_INPUT
export const HTML_ALLOWED_ATTRS = [
  'href', 'title', 'class', 'id', 'name', 'style', 'target', 'data-toggle', 'data-parent',
  'data-ga-outbound-tracking', 'ite', 'span', 'width', 'src', 'allowFullScreen', 'height', 'type', 'wmode',
  'allowfullscreen', 'allowFullScreenInteractive', 'bgcolor', 'flashvars', 'pluginspage', 'quality', 'flashVars',
  'align', 'alt', 'usemap', 'start', 'summary', 'scope', 'border', 'abbr', 'axis', 'colspan', 'rowspan', 'lass',
  'text-align', 'dir', 'runat', 'data', 'value', 'oe-mention', 'user', 'data-user-id', 'padding', 'margin',
  'background-position', 'background-repeat', 'data-id', 'oe-content-cdf', 'oe-content-math', 'uib-accordion',
  'close-others', 'uib-accordion-group', 'heading', 'is-open', 'is-disabled', 'url', 'tgb-slug', 'tgb-post-count',
  'tgb-mobile-count', 'tgb-dark-mode', 'tgb-fixed-height', 'shape', 'coords', 'scrolling', 'frameborder', 'sandbox',
  'webkitallowfullscreen', 'mozallowfullscreen', 'uib-popover', 'popover-title', 'popover-trigger', 'popover-is-open',
  'tooltip-placement', 'popover-placement', 'uib-tooltip', 'tooltip-trigger', 'tooltip-is-open',
];

export enum ToolbarOptions {
  COLOR = 'color',
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  PARAGRAPH_STYLE = 'paragraphStyle',
  ALIGN = 'align',
  INSERT_LINK = 'insertLink',
  FORMAT_UL = 'bulletList',
  FORMAT_OL = 'numberList',
}

export const DEFAULT_TOOLBAR_BUTTONS: ToolbarOptions[] = [
  ToolbarOptions.COLOR,
  ToolbarOptions.BOLD,
  ToolbarOptions.ITALIC,
  ToolbarOptions.UNDERLINE,
  ToolbarOptions.FORMAT_UL,
  ToolbarOptions.FORMAT_OL,
  ToolbarOptions.PARAGRAPH_STYLE,
  ToolbarOptions.ALIGN,
  ToolbarOptions.INSERT_LINK,
];

/* Upload */
export const modalImgOnlyHeight = 180;
export const modalAllUploadsHeight = 405;
export const defaultEmbeddingWidth = 580;
export const defaultEmbeddingHeight = 325;

export enum RteTagTypes {
  NAME,
  URL,
  DATE,
  ACCESS_INSTRUCTION,
}

export enum RteTagValue{
  // Default tags
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  OFFERING_NAME = 'OFFERING_NAME',
  OFFERING_HOME_URL = 'OFFERING_HOME_URL',
  OFFERING_START_DATE = 'OFFERING_START_DATE',
  OFFERING_RELEASE_DATE = 'OFFERING_RELEASE_DATE',
  OFFERING_END_DATE = 'OFFERING_END_DATE',
  OFFERING_CLOSING_DATE = 'OFFERING_CLOSING_DATE',
  OFFERING_REMAINING_ACCESS_DAYS = 'OFFERING_REMAINING_ACCESS_DAYS',

  // Extra tags
  INSERT_PASSWORD_SENTENCE = 'INSERT_PASSWORD_SENTENCE',
}


export interface RteTag {
  tagValue: string,
  displayName?: string,
  entityType?: OwnerType,
  entityId?: number,
  type: RteTagTypes,
  selected?: boolean
  category?: FeedbackCategory,
}

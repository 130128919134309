import { css } from '@emotion/react';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { AngularServicesContext } from 'react-app';
import { isFunction } from 'underscore';
import { useAppDispatch } from 'redux/store';
import moment from 'moment';

// Schemas
import { CombinedInstitution, RootState } from 'redux/schemas';
import { PagedDataQueryParams } from 'redux/create-action-creators';
import { OrgLevelUserNormalized } from 'redux/schemas/api/user';
import { NameAndEmailFormSource, OrgLevelUser } from 'redux/schemas/models/user';

// Actions
import { deleteOrgUser, searchOrgUsers } from 'redux/actions/org-level-roles';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

// selectors
import { getCurrentInstitution } from 'redux/selectors/institutions';

// styles
import { gray2, gray5, gray6, warning } from 'styles/global_defaults/colors';
import { createGridStyles, threeQuartersSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';

// components
import NvIcon from 'shared/components/nv-icon';
import NvResponsiveTable, { NvTableRowProps } from 'shared/components/nv-responsive-table';
import { NvUserAvatar } from 'components/nv-user-avatar';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import ClickableContainer from 'components/clickable-container';
import EditNameAndEmailModal from 'institutions/components/edit-name-and-email/edit-modal';
import LoadingRow, { ActionsDropdown, tableStyles } from './shared/table-components';
import OrgProfileBulkUploadModal from './org-profile-bulk-upload-modal';
import OrgLevelProfileSettings from '../org-level-profile-settings';

import { config } from '../../../../config/pendo.config.json';

const PAGE_SIZE = 30;

const styles = (hasSearch) => css`
  .all-users-table {
    /**
     * 210 = top-nav height(60) + tab header height(60) + tab-action-header height(60) + table margin(30)
     * tab-action-header does not show on search. So reducing 150px on this case.
     */
    height: calc(100vh - ${hasSearch ? 150 : 210}px);

    .last-synced {
      border-bottom: 1px solid ${gray6};
      border-top: 1px solid ${gray6};
      .last-synced-text {
        max-width: 800px;
        margin: 0 auto;
        color: ${warning};
      }
    }

    .nv-responsive-table {
      margin-top: ${largeSpacing}px;
      ${tableStyles}
      .name-container {
        font-weight: bold;
      }

      /* Overrides the empty search results styles similar to licensed dashboard search */
      .no-results-panel {
        span {
          color: ${gray2} !important;
        }

        .clear-text {
          font-weight: 600;
          font-size: 12px;
          line-height: ${threeQuartersSpacing}px;
        }
      }
    }
  }
`;

const userRowSyles = (columnsWidth) => css`
  &.user-row {
    border-bottom: 1px solid ${gray5};
  }
  .name {
    z-index: 1;
    width: ${columnsWidth.name}
  }
  .email {
    width: ${columnsWidth.email}
  }
  .external-id {
    width: ${columnsWidth.externalId}
  }
  .enrollment {
    width: ${columnsWidth.enrollment}
  }
  .actions {
    width: ${columnsWidth.actions}
  }
`;

const LastSynced = () => {
  const institution: CombinedInstitution = useSelector<CombinedInstitution>(state => getCurrentInstitution(state));
  const { hcmLastSyncedAt: lastSynced } = institution;
  const date = lastSynced ? moment(lastSynced).format('MMMM D, YYYY [at] h:mm A') : null;
  return (
    <>
      {lastSynced && (
        <div className='last-synced pb-4 pt-4'>
          <div className='last-synced-text text-regular'>
            {t.INSTITUTIONS.ADVANCED_SETTINGS.HCM.ORG_ADMIN.LAST_SYNCED(date)}
          </div>
        </div>
      )}
    </>
  );
};

type OrgAllUsersProps = {
  searchQuery?: string,
  onSearchClear: () => void
};

const OrgAllUsers = (props: OrgAllUsersProps) => {
  const { searchQuery = '', onSearchClear } = props;
  const { $state } = useContext(AngularServicesContext) || {};
  const getStoreOrgUserData = (state: RootState) => state.models.users as OrgLevelUserNormalized;
  const institution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const dispatch = useAppDispatch();

  const [extraDataKeys, setExtraDataKeys] = useState([]);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [showEditNameAndEmailModal, setShowEditNameAndEmailModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<OrgLevelUser>(null);
  const [showConfigureOrgProfileModal, setShowConfigureOrgProfileModal] = useState(null);
  const [loadTable, setLoadTable] = useState(true);
  const hasSsoLogin = institution.ssoLogin;
  const hasSearchQuery = !!searchQuery;
  const canEditNameAndEmail = (!hasSsoLogin || institution.enableBasicSsoInfoEdit);

  const filtersRef = React.useRef({});
  const sortingRef = React.useRef([]);

  const fetchParams: PagedDataQueryParams = {
    searchQuery,
    // NOTE: Always passing the same filters and sorting using a ref since they
    // are constant. If we pass them on the fly, each render they will be
    // different which causes NvResponsiveTable to confuse and reset the table.
    filters: filtersRef.current,
    sorting: sortingRef.current,
  };

  /**
   * Resetting the loadTable value to true when it changes to false for
   * reloading the users list table.
   */
  useEffect(() => {
    if (!loadTable) {
      setLoadTable(true);
    }
  }, [loadTable]);

  const tableColumnsWidth = hasSsoLogin ? {
    name: '25%',
    email: '25%',
    externalId: '20%',
    enrollment: '20%',
    actions: '10%',
  } : {
    name: '35%',
    email: '35%',
    enrollment: '20%',
    actions: '10%',
  };

  const tableColumns: {
    name: string,
    className: string,
    gridWidth: string,
    headerClassName?: string,
  }[] = [
    {
      name: t.INSTITUTIONS.ROLES.NAME(),
      className: 'name-cell',
      gridWidth: tableColumnsWidth.name,
    },
    {
      name: t.INSTITUTIONS.ROLES.EMAIL(),
      className: 'email-cell',
      gridWidth: tableColumnsWidth.email,
    },
  ];

  if (hasSsoLogin) {
    tableColumns.push({
      name: t.INSTITUTIONS.ROLES.EXTERNAL_ID(),
      className: 'external-id-cell',
      gridWidth: tableColumnsWidth.externalId,
    });
  }

  tableColumns.push({
    name: t.INSTITUTIONS.ROLES.ENROLLMENT(),
    className: 'enrollment-cell',
    gridWidth: tableColumnsWidth.enrollment,
  });

  tableColumns.push({
    name: t.INSTITUTIONS.ROLES.ACTIONS(),
    className: 'actions-cell',
    gridWidth: tableColumnsWidth.actions,
    headerClassName: 'justify-content-end pr-0',
  });

  const onEditClick = (user) => {
    setShowEditNameAndEmailModal(true);
    setSelectedUser(user);
  };

  const onDeleteClick = (user) => {
    dispatch(openConfirmationDialog({
      title: t.INSTITUTIONS.ROLES.REMOVE_ORG_ADMIN_WARNING.TITLE(user.fullName),
      bodyText: t.INSTITUTIONS.ROLES.ALL_USERS.REMOVE_USER_WARNING(user.fullName),
      confirmText: t.FORM.YES_SURE(),
      onConfirm: () => dispatch(deleteOrgUser({
        institutionId: institution.id,
        userId: user.id,
      })),
    }));
  };

  const orgUserRow = (rowProps: NvTableRowProps<OrgLevelUser>) => {
    const { data: user, rowIndex } = rowProps;

    const openEnrollmentModal = () => {
      $state.go('user-enrollment-details-modal', {
        userId: user.id,
        institutionId: institution.id,
      });
    };

    return (
      <div
        className='user-row d-flex py-2 align-items-center text-regular text-black'
        style={createGridStyles(1, rowIndex, tableColumns.length + 1, rowIndex + 1)}
        css={userRowSyles(tableColumnsWidth)}
      >
        <ClickableContainer
          className='name pr-4 text-break'
          style={{ width: tableColumnsWidth.name }}
          onClick={user.enrolledCoursesCount > 0 ? openEnrollmentModal : null}
        >
          <NvUserAvatar
            displayName
            inlineName
            alignNameRight
            borderType='round'
            size='md'
            user={user}
          />
        </ClickableContainer>
        <div
          className='email pr-4'
          style={{ width: tableColumnsWidth.email }}
        >
          {user.email}
        </div>
        {hasSsoLogin && (
          <div
            className='external-id pr-4'
            style={{ width: tableColumnsWidth.externalId }}
          >
            {user.externalId}
          </div>
        )}
        <div
          className='enrollment'
          style={{ width: tableColumnsWidth.enrollment }}
        >
          {t.INSTITUTIONS.ROLES.ENROLLED_COURSE(user.enrolledCoursesCount)}
        </div>
        <div
          className='actions text-primary justify-content-end pr-0'
          style={{ width: tableColumnsWidth.actions }}
        >
          <ActionsDropdown
            editText={t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL.OPTION()}
            deleteText={t.INSTITUTIONS.ROLES.ALL_USERS.REMOVE_USER()}
            hasDelete={!!user?.canRemoveFromInstitution}
            onEdit={() => onEditClick(user)}
            onDelete={() => onDeleteClick(user)}
            canEdit={canEditNameAndEmail}
            editDisabledText={t.USER_MANAGEMENT.EDIT_NAME_AND_EMAIL.DISABLED()}
          />
        </div>
      </div>
    );
  };

  return (
    <div css={styles(hasSearchQuery)}>
      {!hasSearchQuery && (
        <div className='tab-action-header'>
          <div className='tab-action-panel text-medium'>
            <button
              type='button'
              className='d-flex align-items-center border-0 bg-transparent color-primary mr-4'
              onClick={() => setShowBulkUploadModal(true)}
            >
              <NvIcon size='smallest' icon='upload' className='pr-2 d-inline-block ' />
              {t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD()}
            </button>
            <button
              type='button'
              className='d-flex align-items-center border-0 bg-transparent color-primary mr-4'
              onClick={showConfigureOrgProfileModal}
            >
              <NvIcon size='smallest' icon='profile' className='pr-2 d-inline-block ' />
              {t.INSTITUTIONS.ROLES.ALL_USERS.CONFIGURE_ORG_PROFILE()}
            </button>
          </div>
        </div>
      )}
      <div className='all-users-table'>
        {/* Checking the showConfigureOrgProfileModal for avoiding multiple
          table data rendering */}
        <LastSynced />
        {loadTable && isFunction(showConfigureOrgProfileModal) && (
          <NvResponsiveTable<OrgLevelUser>
            columns={tableColumns}
            fetchData={searchOrgUsers}
            fetchParams={{}}
            pagedFetchParams={fetchParams}
            rowComponent={orgUserRow as FunctionComponent}
            rowProps={{}}
            loadingComponent={(rawProps) => LoadingRow(rawProps, tableColumns.length + 1)}
            dataKey='id'
            cacheDataKey='id'
            cacheLookup={getStoreOrgUserData}
            hideClearSearch={!hasSearchQuery}
            clearSearch={onSearchClear}
            dataQaClearSearch={config.pendo.userManagement.clearSearch}
            style={createGridStyles(1, 3)}
            noResultsInTable={!hasSearchQuery}
            noResultsIcon={hasSearchQuery ? 'profile' : ''}
            noResultsText={hasSearchQuery
              ? t.SEARCH.NO_RESULTS_FOUND() : t.SEARCH.NO_USERS_ADDED_YET()}
            pageSize={PAGE_SIZE}
            extraDataKeys={extraDataKeys}
            hoverDisabled
            showSearchSummaryHeader
          />
        )}
      </div>
      <NvModal
        type={ModalType.DYNAMIC}
        header={t.INSTITUTIONS.ROLES.ALL_USERS.BULK_UPLOAD_MODAL.HEADER()}
        body={(
          <OrgProfileBulkUploadModal
            closeModal={() => setShowBulkUploadModal(false)}
            reloadList={() => setLoadTable(false)}
          />
        )}
        show={showBulkUploadModal}
        onClose={() => setShowBulkUploadModal(false)}
        width={600}
        fullHeight={false}
      />
      {showEditNameAndEmailModal && (
        <EditNameAndEmailModal
          user={selectedUser}
          source={NameAndEmailFormSource.ORG_ADMIN}
          closeCallback={() => setShowEditNameAndEmailModal(false)}
        />
      )}
      <OrgLevelProfileSettings
        forwardShowModal={(func) => setShowConfigureOrgProfileModal(() => func)}
      />
    </div>
  );
};

export default OrgAllUsers;
